<template>
	<CtaLink :cta-link="ctaLink" :browser-title="ctaLink.text" :use-default-style="false" class="advert-card-layout" :style="{ 'max-width': `${props.placementWidth}px` }" @click="onAdvertClick">
		<template v-if="props.isLoading">Loading...</template>
		<template v-else>
			<div class="name">{{ asset.name }}</div>
			<div class="description">{{ asset.description }}</div>
			<div class="image" :style="{ 'max-width': `${props.placementWidth}px`, 'height': `${props.placementHeight}px` }">
				<ImgIxWithPlaceholder :src="asset.image.url" :preview="asset.image.preview" :sizes="imageSizes" :widths="imageWidths" :aspect-ratio="imageAspectRatio" />
			</div>
			<div class="summary">{{ asset.summary }}</div>
		</template>
	</CtaLink>
</template>

<script setup>
import { computed, inject, onMounted } from "vue";

import { useRouteParams } from "../../helpers/index.js";

import ImgIxWithPlaceholder from "../imgix/ImgIxWithPlaceholder.vue";
import CtaLink from "../CtaLink.vue";

const props = defineProps({
	isLoading: {
		type: Boolean,
		default: false,
	},
	matchAdvertResult: {
		type: Object,
		required: true,
	},
	placementWidth: {
		type: Number,
		required: true,
	},
	placementHeight: {
		type: Number,
		required: true,
	},
	extraTrackingData: {
		type: Object,
		default: () => ({}),
	},
});

const tracking = inject("tracking");

const routeParams = useRouteParams();

const asset = computed(() => props.matchAdvertResult.asset);
const ctaLink = computed(() => props.matchAdvertResult.ctaLink);
const imageSizes = computed(() => `${props.placementWidth}px`);
const imageWidths = computed(() => new Array(3).fill(null).map((v, index) => props.placementWidth * (index + 1)));
const imageAspectRatio = computed(() => props.placementWidth / props.placementHeight);

onMounted(() => {
	tracking.advertImpression({ matchAdvertResult: props.matchAdvertResult, cityName: routeParams.cityName, extraTrackingData: props.extraTrackingData });
});

function onAdvertClick() {
	tracking.advertInteraction({ matchAdvertResult: props.matchAdvertResult, cityName: routeParams.cityName, extraTrackingData: props.extraTrackingData });
}
</script>

<style scoped lang="scss">
@import "../../assets/styles/variables_new.scss";

.advert-card-layout {
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	width: 100%;
	height: 100%;

	.name {
		flex-shrink: 0;
		font-size: $text-size-tertiary;
		font-weight: $text-bold-secondary;
		color: $text-color-primary;

		width: 100%;
		line-height: 130%;
		@include text-ellipsis;
	}

	.description {
		flex-shrink: 0;
		font-size: $text-size-tertiary;
		color: $text-color-secondary;
		width: 100%;
		line-height: 130%;

		margin-bottom: calc($spacing / 6);

		@include text-ellipsis;
	}

	.image {
		flex-shrink: 0;
		border-radius: $border-radius-primary;
		overflow: hidden;
		align-self: center;
		width: 100%;
	}

	.summary {
		flex-shrink: 0;
		font-size: $text-size-tertiary;
		color: $text-color-secondary;

		margin-top: calc($spacing / 4);

		@include text-ellipsis;
	}
}
</style>
