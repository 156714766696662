export default {
	scrollPositionStored: {
		level: "silly",
		message: ({ name, position }) => `🔝 Scroll position stored for ${name} ${position}`,
	},
	scrollPositionRestored: {
		level: "silly",
		message: ({ name, position }) => `🔝 Scroll position restored for ${name} ${position}`,
	},
};
