<template>
	<div class="download-app">
		<div>Unable to show list, please update to the latest version of onezone.</div>
		<a :href="appDownloadLink" class="push-button-call-to-action">Update</a>
	</div>
</template>

<script setup>
import { inject } from "vue";

import { getAppDownloadLink } from "../helpers/index.js";

const platform = inject("platform");
const appDownloadLink = getAppDownloadLink({ platform });
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

.download-app {
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: calc($spacing);
	align-items: center;
	justify-content: center;
	margin: calc($spacing / 2);

	> div {
		text-align: center;
	}
}
</style>
