import { computed } from "vue";

export default class {
	constructor({ logger, modelBuilder, api, authentication, isSignedIn }) {
		this.logger = logger.nested({ name: "Model" });
		this.modelBuilder = modelBuilder;
		this.api = api;
		this.authentication = authentication;
		this.isSignedIn = isSignedIn;
	}

	execute({ placementName, placementSize, matchJson, count, enabled = true }) {
		return this.modelBuilder.build({
			name: "MatchAdverts",
			args: {
				placementName,
				placementSize,
				matchJson,
				count,
				enabled,
				isSignedIn: this.isSignedIn,
			},
			state: {
				isLoadingMore: false,
				matchAdvertResults: [],
			},
			actions: {
				async loadMore({ state, execute }) {
					state.isLoadingMore = true;
					await execute();
				},
				async refresh({ state, execute }) {
					state.isLoadingMore = false;
					await execute();
				},
			},
			execute: async ({ args, state }) => {
				if (args.enabled) {
					if (state.isLoadingMore) {
						state.isLoadingMore = false;
					} else {
						state.matchAdvertResults = [];
					}

					const excludeAssetIds = Array.from(new Set(state.matchAdvertResults.map(({ asset }) => asset?.id)));
					const data = await this.api.queries.matchAdverts(
						{ placementName: args.placementName, placementSize: args.placementSize, matchJson: args.matchJson, excludeAssetIds, count: args.count },
						{ useClientCache: false, accessToken: args.isSignedIn ? this.authentication.getAccessToken : undefined },
					);

					if (data.matchAdverts) {
						data.matchAdverts.forEach((matchAdvert) => {
							state.matchAdvertResults.push(matchAdvert);
						});
					}
				}

				const matchAdvertResults = computed(() => state.matchAdvertResults);

				return { matchAdvertResults };
			},
		});
	}
}
