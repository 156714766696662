<template>
	<button :class="{ 'close-button': true, 'background': props.showBackground }" @click="closeButtonClicked">
		<img :src="props.showLight ? CloseButtonLightImageUrl : CloseButtonStandardImageUrl" :class="{ icon: true }" />
	</button>
</template>

<script setup>
import { inject } from "vue";

import CloseButtonStandardImageUrl from "../assets/icons/font-awesome/xmark-solid.svg";
import CloseButtonLightImageUrl from "../assets/icons/font-awesome/xmark-solid-light.svg";

const props = defineProps({
	showLight: {
		type: Boolean,
		default: false,
	},
	showBackground: {
		type: Boolean,
		default: false,
	},
	enableBackAction: {
		type: Boolean,
		default: false,
	},
});

const navigation = inject("navigation");

function closeButtonClicked() {
	if (props.enableBackAction) {
		navigation.back();
	}
}
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

.close-button {
	// position: absolute;
	// top: calc($spacing/1.5);
	// right: calc($spacing/1.5);
	// z-index: 1;
	display: flex;
	align-items: center;
	cursor: pointer;

	&.background {
		background-color: white;
		border-radius: 50%;
		padding: 2px;
		@include drop-shadow;

		.icon {
			width: 17px;
			height: 17px;
		}
	}

	.icon {
		width: 22px;
		height: 22px;
	}
}
</style>
