<template>
	<Dialog ref="perkDialogRef" v-slot="{ closeDialog }" class="perk-dialog-layout" param-name="showPerk" @viewed="perkViewed">
		<DialogSimpleLayout :show-background-close-button="isSmallerThanMedium" :show-padding="false" @close="closeDialog">
			<PerkDetail :venue="props.venue" :perk="props.perk" />
		</DialogSimpleLayout>
	</Dialog>
</template>

<script setup>
import { inject, ref } from "vue";
import { useRoute } from "vue-router";

import { breakpoints } from "../../domain/index.js";

import Dialog from "../Dialog.vue";
import PerkDetail from "./PerkDetail.vue";
import DialogSimpleLayout from "../DialogSimpleLayout.vue";

const props = defineProps({
	venue: {
		type: Object,
		default: null,
	},
	perk: {
		type: Object,
		default: null,
	},
});

const tracking = inject("tracking");

const route = useRoute();

const perkDialogRef = ref(null);

const isSmallerThanMedium = breakpoints.smaller("medium");

defineExpose({ showModal, close });

function showModal(...args) {
	perkDialogRef.value.open(...args);
}

function close(...args) {
	perkDialogRef.value.close(...args);
}

function perkViewed() {
	const button = JSON.parse(route.query.button ?? null);
	tracking.perkViewed({
		venue: props.venue,
		perk: props.perk,
		button,
	});
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/variables_new.scss";

h3 {
	font-size: 1rem;
}

.perk-dialog-layout {
	width: 90vw;
	// max-width: 90vw !important;
	// height: 100% !important;
	// max-height: 90dvh !important;
	// padding: 0 !important;
}

@media (min-width: $bp-medium) {
	.perk-dialog-layout {
		max-width: 800px;
		max-height: min(600px, 90dvh);
	}
}
</style>
