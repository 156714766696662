<template>
	<button :class="{ 'arrow': true, 'show-light': props.showLight }">
		<img :src="iconSrc" />
	</button>
</template>

<script setup>
import { computed } from "vue";
import ChevronLeft from "../assets/icons/font-awesome/circle-chevron-left-solid.svg";
import ChevronLeftBlack from "../assets/icons/font-awesome/circle-chevron-left-solid-black.svg";
import ChevronRight from "../assets/icons/font-awesome/circle-chevron-right-solid.svg";
import ChevronRightBlack from "../assets/icons/font-awesome/circle-chevron-right-solid-black.svg";

const props = defineProps({
	direction: {
		type: String,
		required: true,
	},
	showLight: {
		type: Boolean,
		default: true,
	},
});

const iconSrc = computed(() => (props.direction === "left" ? (props.showLight ? ChevronLeft : ChevronLeftBlack) : props.showLight ? ChevronRight : ChevronRightBlack));
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

.arrow {
	// position: absolute;
	// top: 50%;
	// transform: translateY(-12px);

	// padding: 1px;
	// box-sizing: border-box;
	border-radius: 50%;
	@include drop-shadow;
	width: 24px;
	height: 24px;
	transition: opacity 0.2s ease-in;
	opacity: 0.5;
	cursor: pointer;

	&:hover {
		opacity: 1 !important;
	}

	background-color: white;
	&.show-light {
		background-color: black;
	}

	img {
		width: 100%;
		height: 100%;
	}
}
</style>
