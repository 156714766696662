import { computed, unref } from "vue";

import { BookingMethods } from "../constants/index.js";

export const useVenueBookingMethod = (venue) => {
	const bookingMethod = computed(() => unref(venue).book?.bookingMethod);
	const shouldHighlightBooking = computed(() => [BookingMethods.IN_APP, BookingMethods.PASS_THROUGH].includes(unref(bookingMethod)));

	return { bookingMethod, shouldHighlightBooking };
};
