<template>
	<div class="feed-list">
		<div class="list-title">
			<h2>{{ feedList.name }}</h2>
			<CustomRouterLink :to="listRouterLink" class="show-more link-button-primary">Show all</CustomRouterLink>
		</div>
		<NewList
			v-slot="{ item, isItemVisible }"
			list-class="venues"
			item-class="venue"
			:name="listName"
			:items="props.feedList.items"
			:direction="ListDirections.HORIZONTAL"
			:item-height="220"
			:item-width="170"
			:gap-size="7"
			:item-buffer-count="4"
			:show-navigation-buttons="true"
			:enable-snap="false"
			:pad-first-and-last-item="true"
		>
			<KeepAlive>
				<VenueCard v-if="isItemVisible" :key="`list-item:${item.id}`" :venue="item.venue" />
			</KeepAlive>
		</NewList>
		<!-- <EfficientList v-slot="{ item: item, style }" class="venues" :direction="ListDirections.HORIZONTAL" :items="props.feedList.items" :item-size="220" :buffer="4" :recycle="true">
			<VenueCard :venue="item.venue" :style="style" />
		</EfficientList> -->
	</div>
</template>

<script setup>
import { computed } from "vue";

import { urlBuilder } from "../helpers/index.js";
import { PageNames, ListDirections } from "../constants/index.js";

import NewList from "./NewList.vue";
import VenueCard from "./VenueCard.vue";
// import EfficientList from "./EfficientList/EfficientList.vue";

const props = defineProps({
	feedList: {
		type: Object,
		required: true,
	},
});

const listName = `feed-list:${props.feedList.name}:${props.feedList.id}`;
const listRouterLink = computed(() => urlBuilder(PageNames.EXPLORE_LIST, { cityName: props.feedList.city()?.name?.toLowerCase(), list: props.feedList }));
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

.feed-list {
	display: flex;
	flex-direction: column;
	gap: calc($spacing / 1.5);

	.list-title {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: calc($spacing / 1.5);
		max-width: 100vw;
		padding-left: calc($spacing);

		> h2 {
			@include text-ellipsis;
		}

		.show-more {
			margin-top: 4px;
			flex-shrink: 0;
		}
	}
}

@media (min-width: $bp-medium) {
	.feed-list {
		> .list-title {
			padding-left: 0;
		}
	}
}
</style>
