<template>
	<div class="profile-settings">
		<loading-message-with-error v-if="profileQuery.isLoading || profileQuery.error" class="top-loading-message" :error="profileQuery.error" :retry="profileQuery.retry">
			Loading profile, please wait...
		</loading-message-with-error>
		<div v-else-if="isSignedIn" class="settings">
			<div class="update-profile-form">
				<EntityForm :entity="profile" @save="updateProfileOnSave" />
			</div>

			<template v-if="isImpersonating && allowImpersonateUser">
				<div class="impersonate-user-info">
					<label>Currently Impersonating User</label>
					<span>{{ impersonatedDescription }}</span>
					<label>Logged In User</label>
					<span>{{ loggedInUserDescription }}</span>
				</div>
				<button v-if="isAdmin" class="push-button-tertiary" @click="adminHardDeleteUserOnClick">Hard-Delete User (<font-awesome-icon icon="fa-solid fa-lock" class="admin" /> Admin)</button>
				<DeletePremiumAdminButton />
			</template>

			<div class="bottom-options">
				<!-- <ManagePayments :user="profile" /> -->
				<button v-if="isSignedIn" class="link-button-primary log-out-button-desktop" @click="currentEnvironment?.logout">Logout</button>
				<router-link :to="{ name: PageNames.PRIVACY_POLICY }" class="link-button-primary">Privacy Policy</router-link>
				<DeleteUserButton />
			</div>
			<div class="version-details">
				<div>client version: {{ config.clientVersion }}</div>
				<div>api version: {{ currentEnvironmentApiVersion }}</div>
			</div>
		</div>
		<div v-else>
			<error-message>You must be signed in to view this page</error-message>
		</div>
	</div>
</template>

<script setup>
import { computed, inject, onMounted, ref, unref } from "vue";

import { PageNames } from "../constants/index.js";
import { getAllowImpersonate } from "../helpers/index.js";
import loggingMessages from "./Settings.logging-messages.js";

import EntityForm from "./forms/EntityForm.vue";
// import ManagePayments from "./ManagePayments.vue";
import DeleteUserButton from "./DeleteUserButton.vue";
import DeletePremiumAdminButton from "./DeletePremiumAdminButton.vue";

const config = inject("config");
const currentEnvironment = inject("currentEnvironment");
const model = inject("model");
const queryString = inject("queryString");
const logger = inject("logger").nested({ name: "Settings" });
const addChange = inject("addChange");

const isMounted = ref(false);

const isSignedIn = computed(() => currentEnvironment.value.isSignedIn.value);

const profileQuery = model.queries.GetProfile({ isSignedIn });

const loggedInUser = computed(() => (isMounted.value ? currentEnvironment.value?.loggedInUser.value : null));
const isImpersonating = computed(() => !!queryString.impersonate);
const loggedInUserDescription = computed(() => (loggedInUser.value ? getUserDescription(loggedInUser.value) : null));
const impersonatedDescription = computed(() => (profile.value ? getUserDescription(profile.value) : null));
const allowImpersonateUser = computed(() => getAllowImpersonate(currentEnvironment.value).canImpersonate);
const profile = computed(() => profileQuery.model?.profile);
const isAdmin = computed(() => currentEnvironment.value?.isAdmin?.value);
const currentEnvironmentApiVersion = computed(() => (isMounted.value ? unref(currentEnvironment.value?.apiVersion) : null));

onMounted(() => {
	isMounted.value = true;
});

function getUserDescription(user) {
	return `${user.firstName} ${user.lastName} (${user.username}) : ${user.email}`;
}

async function updateProfileOnSave() {
	logger.log(loggingMessages.userProfileUpdatedReInitialisingEnvironment, { environmentName: currentEnvironment.value.name });
	window.location.reload();
}

const adminHardDeleteUserOnClick = () => {
	addChange.HardDeleteUser({ userId: profile.value.id });
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables_new.scss";

.profile-settings {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	overflow: auto;
	@include centre-loading-message;

	.settings {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: calc($spacing / 2);
		width: 100%;
		height: 100%;
		padding: $spacing calc($spacing * 1.5) $spacing calc($spacing * 1.5);
		box-sizing: border-box;
		overflow: auto;

		.bottom-options {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: calc($spacing / 2);
			margin-top: calc($spacing * 1.5);

			.log-out-button-desktop {
				display: none;
			}
		}

		.update-profile-form {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 100%;
			max-width: 440px;
		}

		.impersonate-user-info {
			display: grid;
			grid-template-columns: min(250px, 50%) auto;
			margin: $spacing 0;
			border: $border-size-primary solid $border-color-warning;

			> * {
				border: $border-size-primary solid $border-color-warning;
				padding: calc($spacing / 2);
				box-sizing: border-box;
				margin: 1px;
			}

			label {
				font-weight: $text-bold-secondary;
			}

			span {
				color: $text-color-secondary;
				overflow: auto;
			}
		}
	}

	.user-list {
		height: 100%;
	}

	.version-details {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: calc($spacing * 2);
		font-size: $text-size-tertiary;
		color: $text-color-tertiary;
	}
}

@media (min-width: $bp-medium) {
	// .profile-settings {
	// 	.header {
	// 		// align-items: start;
	// 		border: none;
	// 		padding: 0;
	// 		margin-bottom: calc($spacing * 2);

	// 		.back-button {
	// 			display: none;
	// 		}

	// 		.log-out-button {
	// 			display: none;
	// 		}
	// 	}

	// 	.settings {
	// 		align-items: stretch;
	// 		padding: 0 0 $spacing 0;
	// 		overflow: visible;

	// 		:deep(.save-button-container) {
	// 			align-items: start;
	// 		}
	// 	}

	.profile-settings {
		.settings {
			.bottom-options {
				.log-out-button-desktop {
					display: flex;
				}
			}
		}
	}
}
</style>
