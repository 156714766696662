<template>
	<div class="venue-product-request-info-button">
		<CtaLink v-if="ctaLink" :cta-link="ctaLink" :browser-title="venueProduct.name" @click.prevent="linkClicked({ linkType: LINK_TYPE.CTA, linkText: ctaLink.text ?? CTA_DEFAULT_LINK_TEXT })">
			{{ ctaLink.text ?? CTA_DEFAULT_LINK_TEXT }}
		</CtaLink>
		<button v-else-if="canShowEnquiryForm" class="push-button-call-to-action" @click.prevent="openVenueProductEnquiryForm({ linkType: LINK_TYPE.CTA, linkText: CTA_DEFAULT_LINK_TEXT })">
			{{ CTA_DEFAULT_LINK_TEXT }}
		</button>
	</div>
</template>

<script setup>
import { computed, inject } from "vue";

import CtaLink from "../CtaLink.vue";

const CTA_DEFAULT_LINK_TEXT = "Request Info";
const LINK_TYPE = {
	OTHER: "OTHER",
	CTA: "CTA",
};

const props = defineProps({
	venue: {
		type: Object,
		required: true,
	},
	venueProduct: {
		type: Object,
		required: true,
	},
});

const tracking = inject("tracking");
const venueProductEnquiryDialogRef = inject("venueProductEnquiryDialogRef");

const venueProduct = computed(() => props.venueProduct);
const ctaLink = computed(() => venueProduct.value?.ctaLink);
const canShowEnquiryForm = computed(() => venueProduct.value?.hasEnquiryEmail ?? false);

function openVenueProductEnquiryForm({ linkType, linkText }) {
	linkClicked({ linkType, linkText });
	venueProductEnquiryDialogRef.value.open({ venueId: props.venue.id, venueProductId: venueProduct.value.id });
}

function linkClicked({ linkType, linkText }) {
	tracking.venueProductLinkClicked({ venue: props.venue, venueProduct: props.venueProduct, linkType, linkText });
}
</script>

<style scoped lang="scss">
@import "../../assets/styles/variables_new.scss";
.venue-product-request-info-button {
	display: flex;
	justify-content: stretch;

	> :deep(*) {
		flex: 1;
	}
}
</style>
