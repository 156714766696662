<template>
	<Page :is-page-full-height="true" class="activity-feed-page">
		<DialogFullScreenPageLayout :show-header-on-desktop="true" :has-back-button="false" class="dialog-full-screen-page-layout">
			<template #header>
				<h2>Activity</h2>
			</template>
			<template #default>
				<!-- <loading-message-with-error v-if="queries.isLoading || queries.error" :error="queries.error" :retry="queries.retry" /> -->
				<ActivityFeed v-if="isSignedIn" :feed="feed" :query="queries" class="activity-feed" @refresh="onRefresh" @load-more="onLoadMore" />
				<UserActionPagePreview v-else :feature="PremiumFeature.ACTIVITY_FEED" :user-action="SuggestedUserAction.SIGN_IN">
					<ActivityFeedPagePreview />
				</UserActionPagePreview>
			</template>
		</DialogFullScreenPageLayout>
	</Page>
</template>

<script setup>
import { computed, inject } from "vue";

import { combineQueries } from "../helpers/index.js";
import { PremiumFeature, SuggestedUserAction } from "../constants/index.js";

import Page from "../components/Page.vue";
import DialogFullScreenPageLayout from "../components/DialogFullScreenPageLayout.vue";
import ActivityFeed from "../components/ActivityFeed.vue";
import UserActionPagePreview from "../components/UserActionPagePreview.vue";
import ActivityFeedPagePreview from "../components/ActivityFeedPagePreview.vue";

const currentEnvironment = inject("currentEnvironment");
const model = inject("model");

const isSignedIn = computed(() => currentEnvironment.value.isSignedIn.value);
const queries = combineQueries({
	activityFeed: model.queries.GetActivityFeed({ isSignedIn }),
});

const feed = computed(() => queries.model?.activityFeed ?? []);

async function onLoadMore() {
	/* TODO: add error handling */
	console.log("load-more!!!");
	await queries.actions.loadMore();
}

async function onRefresh() {
	await queries.actions.refresh();
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables_new.scss";

.activity-feed-page {
	.dialog-full-screen-page-layout {
		// :deep(.content) {
		// 	margin: 0;
		// 	padding: 0;
		// }

		:deep(.activity-feed) {
			// padding: calc($spacing) 0 0 0;
		}
	}
}
</style>
