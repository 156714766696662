import { computed } from "vue";
import { ListCategoryNameLookupByUrlParam } from "../../../constants/index.js";

export default class {
	constructor({ logger, modelBuilder, api, authentication, cities }) {
		this.logger = logger.nested({ name: "Model" });
		this.modelBuilder = modelBuilder;
		this.api = api;
		this.authentication = authentication;
		this.cities = cities;
	}

	execute({ cityName, categoryName, search } = {}) {
		return this.modelBuilder.build({
			name: "GetCuratedLists",
			args: { cityName, categoryName, search },
			execute: async ({ args }) => {
				const foundCity = this.cities.value.find(({ name }) => name.toLowerCase() === args.cityName);
				if (!foundCity) {
					throw new Error(`City '${args.cityName}' not found`);
				}

				const listCategoryEnum = ListCategoryNameLookupByUrlParam[args.categoryName];
				const data = await this.api.queries.getCuratedLists({ search: args.search, cityId: foundCity.id, categoryName: listCategoryEnum }, { useClientCache: false });

				const curatedLists = computed(() => data.city.curatedLists);

				return { curatedLists };
			},
		});
	}
}
