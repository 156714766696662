<template>
	<div class="venue-product-detail-container" @mousedown.stop>
		<div class="venue-product-detail-wrapper">
			<div class="venue-product-images">
				<ImageGallery v-slot="{ image }" :images="venueProduct.images" :bounciness="false" class="image-carousel" @interaction="imageGalleryInteraction">
					<ImgIxWithPlaceholder :src="image.url" :preview="image.preview" :sizes="sizes" :widths="widths" placeholder-width="502px" :aspect-ratio="0.72" class="image" />
				</ImageGallery>
			</div>
			<div class="venue-product-detail-section">
				<div class="product-detail">
					<div class="product-summary">
						<span class="name">{{ venueProduct.name }}</span>
						<span class="venue-summary">{{ venueSummary }}</span>
					</div>
					<div class="product-detail-main">
						<div class="detail-summary">
							<div v-if="venueProduct.seatingCapacity">Seated: {{ venueProduct.seatingCapacity }}</div>
							<div v-if="venueProduct.standingCapacity">Standing: {{ venueProduct.standingCapacity }}</div>
							<div v-if="minimumSpendPrices.length > 0">Min Spend: {{ formatCurrency(minimumSpendPrices[0].amount) }}</div>
							<div v-else-if="fixedPricePrices.length > 0">Price: {{ formatCurrency(fixedPricePrices[0].amount) }}</div>
						</div>
						<div class="description" v-html="venueProduct.descriptionHtml"></div>
						<!-- <div v-if="minimumSpendPrices.length > 0" class="prices">
							<h3>Minimum Spend</h3>
							<ul>
								<li v-for="{ id, condition, amount } in minimumSpendPrices" :key="id">
									<div v-if="condition">{{ condition }}:</div>
									<div>{{ formatCurrency(amount) }}</div>
								</li>
							</ul>
						</div>
						<div v-if="fixedPricePrices.length > 0" class="prices">
							<h3>Price</h3>
							<ul>
								<li v-for="{ id, condition, amount } in fixedPricePrices" :key="id">
									<div v-if="condition">{{ condition }}:</div>
									<div>{{ formatCurrency(amount) }}</div>
								</li>
							</ul>
						</div> -->
						<!-- <div v-if="minimumSpendPrices.length === 0 && fixedPricePrices.length === 0" class="prices">
							<h3>{{ noPricesMessage }}</h3>
						</div> -->

						<div v-if="otherLinks.length > 0" class="additional-info">
							<ul>
								<li v-for="{ id, text, url } in otherLinks" :key="id">
									<ExternalLink :href="url" class="push-button-external-link" @click="linkClicked({ linkType: LINK_TYPE.OTHER, linkText: text })">{{ text }}</ExternalLink>
								</li>
							</ul>
						</div>
						<div v-if="specialOffers.length > 0" class="special-offers">
							<!-- <h3>Special Offers</h3> -->
							<ul>
								<li v-for="{ id, description } in specialOffers" :key="id">{{ description }}</li>
							</ul>
						</div>
					</div>
				</div>
				<ExternalLink class="push-button-call-to-action" :href="ctaLink.url" @click="linkClicked({ linkType: LINK_TYPE.CTA, linkText: ctaLinkText })">{{ ctaLinkText }}</ExternalLink>
			</div>
		</div>
	</div>
</template>

<script setup>
import { computed, inject } from "vue";
import dayjs from "dayjs";

import { formatCurrency } from "../../helpers/index.js";
import { VenueProductPriceType } from "../../constants/index.js";

import ImgIxWithPlaceholder from "../imgix/ImgIxWithPlaceholder.vue";
import ImageGallery from "../ImageGallery.vue";
import ExternalLink from "../ExternalLink.vue";

const LINK_TYPE = {
	OTHER: "OTHER",
	CTA: "CTA",
};

const props = defineProps({
	venue: {
		type: Object,
		required: true,
	},
	venueProduct: {
		type: Object,
		required: true,
	},
});

const tracking = inject("tracking");

const venueSummary = computed(() => `${props.venue.name}, ${props.venue.addressLine2}`);
const venueProduct = computed(() => props.venueProduct);
const minimumSpendPrices = computed(() => venueProduct.value?.prices?.filter(({ type }) => type === VenueProductPriceType.MIN_SPEND) ?? []);
const fixedPricePrices = computed(() => venueProduct.value?.prices?.filter(({ type }) => type === VenueProductPriceType.FIXED_PRICE) ?? []);
// const ctaLink = computed(() => venueProduct.value?.ctaLink);
const otherLinks = computed(() => venueProduct.value?.otherLinks ?? []);
const ctaLink = computed(() => venueProduct.value?.ctaLink ?? {});
const ctaLinkText = computed(() => ctaLink.value.text ?? "Request Info");
const specialOffers = computed(() =>
	(venueProduct.value?.specialOffers ?? []).filter(({ startDate, endDate }) => (!startDate || dayjs(startDate).isBefore(dayjs())) && (!endDate || dayjs(endDate).isAfter(dayjs()))),
);
// const noPricesMessage = computed(() => venueProduct.value.noPricesMessage ?? "Minimum spend can vary based on time, day of the week and party size.");

const widths = computed(() => new Array(8).fill(null).map((v, index) => 220 * (index + 1)));
const sizes = "(max-width: 767px) 90vw, 440px";

function linkClicked({ linkType, linkText }) {
	tracking.venueProductLinkClicked({ venue: props.venue, venueProduct: props.venueProduct, linkType, linkText });
}

function imageGalleryInteraction({ highestIndexViewed }) {
	tracking.venueProductImageGalleryInteraction({
		venue: props.venue,
		venueProduct: venueProduct.value,
		venueProductImageCount: venueProduct.value.images.length,
		highestVenueProductImageIndex: highestIndexViewed,
	});
}
</script>

<style scoped lang="scss">
@import "../../assets/styles/variables_new.scss";

.venue-product-detail-container {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	// height: 100%;
	overflow: auto;

	.venue-product-detail-wrapper {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		width: 100%;
		overflow: auto;
		// height: 100%;

		.venue-product-images {
			flex-shrink: 1;
			display: flex;
			// overflow: hidden;
			// max-height: min(55dvh, 400px);
			// height: 100%;

			.image-carousel {
				width: 100%;
				// overflow: hidden;
				max-height: min(50dvh, 350px);
			}
		}

		.venue-product-detail-section {
			flex-grow: 1;
			display: flex;
			flex-direction: column;
			gap: calc($spacing * 1.5);
			align-items: center;
			// justify-content: center;
			// padding: $spacing calc($spacing) calc($spacing * 1.5) calc($spacing);
			margin: $spacing $spacing calc($spacing * 1.5) $spacing;
			box-sizing: border-box;
			overflow: auto;

			.product-detail {
				flex-grow: 1;
				width: 100%;
				box-sizing: border-box;
				display: flex;
				flex-direction: column;
				align-items: center;
				// justify-content: center;
				gap: $spacing;
				overflow: auto;

				.product-summary {
					display: flex;
					flex-direction: column;

					.name {
						font-size: $text-size-header;
						font-weight: $text-bold-primary;
						text-align: center;
					}

					.venue-summary {
						font-size: $text-size-tertiary;
						text-align: center;
					}
				}

				.product-detail-main {
					flex-grow: 1;
					width: 100%;
					box-sizing: border-box;
					position: relative;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: safe center; /* WHY: Use safe here to prevent the content from disapearing when the content is too big for flex container. See: http://www.w3.org/TR/css-align-3/#overflow-values */
					gap: calc($spacing);
					// min-height: 200px;
					font-size: $text-size-tertiary;
					padding: 0 $spacing;
					overflow: auto;

					.description {
						text-align: center;

						:deep(p) {
							text-align: center;
						}

						:deep(p:first-child) {
							margin-top: 0;
						}
						:deep(p:last-child) {
							margin-bottom: 0;
						}
					}

					.detail-summary {
						display: flex;
						// font-size: $text-size-tertiary;

						> div {
							padding: 0 calc($spacing / 2);
							white-space: nowrap;

							&:not(:first-child) {
								border-left: $border-size-primary solid $border-color-primary;
							}
						}
					}

					.prices,
					.additional-info,
					.special-offers {
						display: flex;
						flex-direction: column;
						gap: calc($spacing);
						align-items: center;
						// font-size: $text-size-tertiary;

						> h3 {
							margin: 0;
							font-size: $text-size-secondary;
							text-align: center;
						}

						> ul {
							display: flex;
							flex-direction: column;
							gap: calc($spacing / 2);
							align-items: center;
							justify-content: center;
							list-style: none;
							padding: 0;
							margin: 0;

							> li {
								display: flex;
								gap: calc($spacing / 2);
								text-align: center;
							}
						}
					}

					// .special-offers {
					// 	> ul {
					// 		list-style: disc;
					// 		> li {
					// 			display: list-item;
					// 		}
					// 	}
					// }

					.additional-info {
						> ul {
							flex-direction: row;
							flex-wrap: wrap;
						}
					}
				}
			}
			.push-button-call-to-action {
				flex-shrink: 0;
				width: 180px;
			}
		}
	}
}

@media (min-width: $bp-medium) {
	// .perk-detail-container {
	// 	.experience-detail-wrapper {
	// 		flex-direction: row;

	// 		.perk-image {
	// 			flex: 1;
	// 			max-height: none;

	// 			.image {
	// 				// width: 100%;
	// 				:deep(img) {
	// 					// width: 100%;
	// 					height: auto;
	// 				}
	// 			}
	// 		}

	// 		.experience-detail-section {
	// 			flex: 1;
	// 			overflow: auto;
	// 		}
	// 	}
	// }
}
</style>
