<template>
	<Dialog ref="dialogRef" param-name="browserOpen" class="browser-dialog" :full-screen="true" max-width="min(100vw, 800px)" @closed="close">
		<DialogFullScreenPageLayout header="Change City" :show-header-on-desktop="true" :auto-wire-back-button="false" @close="close">
			<template #header>
				<img src="../assets/onezone-logo.png" alt="logo" class="logo" />
			</template>
			<template #default>
				<EmbeddedBrowser v-if="queryString.browserUrl" :url="queryString.browserUrl" />
				<div v-else>No URL provided</div>
			</template>
		</DialogFullScreenPageLayout>
	</Dialog>
	<div ref="measureScrollBarWidthRef" class="measure-scrollbar-width"></div>
</template>

<script setup>
import { ref } from "vue";

import { useQueryString } from "../functions/query-string/index.js";

import Dialog from "./Dialog.vue";
import DialogFullScreenPageLayout from "./DialogFullScreenPageLayout.vue";
import EmbeddedBrowser from "./EmbeddedBrowser.vue";

const queryString = useQueryString(
	{
		browserUrl: null,
	},
	{ persist: true },
);

const dialogRef = ref(null);
const measureScrollBarWidthRef = ref(null);

defineExpose({
	open,
	close,
});

async function open({ url } = {}) {
	const dialog = dialogRef.value;
	if (!url) {
		throw new Error("URL is required to open the browser dialog.");
	}
	queryString.browserUrl = url;

	if (dialog && !dialog.isOpen) {
		return dialog.open();
	}
}

async function close() {
	const dialog = dialogRef.value;
	queryString.browserUrl = null;
	dialog.close();
}
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

.browser-dialog {
	overflow: hidden;

	:deep(.logo) {
		width: 100px;
		margin: 0 auto;
	}
}
</style>
