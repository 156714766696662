import { reactive, watch, computed, ref, toRefs, unref } from "vue";
import { useIntersectionObserver } from "@vueuse/core";

import { ListDirections } from "./ListDirections.js";

export const useIntersectionObserverList = (containerRef, options) => {
	const {
		items,
		direction = ListDirections.VERTICAL,
		itemWidth,
		itemHeight,
		gapSize = 0,
		itemBufferCount = 2,
		enableSnap = false,
	} = toRefs(options, ["items", "direction", "itemWidth", "itemHeight", "gapSize", "itemBufferCount", "enableSnap"]);

	const itemRefs = ref([]);
	const itemVisibility = reactive({});

	const containerStyle = computed(() => ({
		"display": "flex",
		"flexDirection": unref(direction) === ListDirections.VERTICAL ? "column" : "row",
		"gap": `${unref(gapSize)}px`,
		"overflow": "auto",
		"box-sizing": "content-box",
		"scroll-snap-type": unref(enableSnap) ? `${unref(direction) === ListDirections.VERTICAL ? "y" : "x"} mandatory` : undefined,
	}));
	const itemStyle = computed(() => ({
		"width": unref(itemWidth) ? `${unref(itemWidth)}px` : undefined,
		"height": unref(itemHeight) ? `${unref(itemHeight)}px` : undefined,
		"flex-shrink": 0,
		"box-sizing": "content-box",
		"scroll-snap-align": unref(enableSnap) ? "start" : undefined,
		"scroll-snap-stop": unref(enableSnap) ? "always" : undefined,
	}));

	/* WHY: We clear all refs when items changes, otherwise for some reason, new refs are added but existing are not removed */
	watch(items, () => {
		itemRefs.value = [];
	});

	const size = unref(itemHeight) * unref(itemBufferCount) + unref(gapSize) * Math.min(unref(itemBufferCount), 0);
	const rootMargin = unref(direction) === ListDirections.VERTICAL ? `${size}px 0px` : `0px ${size}px`;

	useIntersectionObserver(
		itemRefs,
		(entries) => {
			entries.forEach((entry) => {
				const { isIntersecting, target } = entry;
				const { itemId } = target.dataset;
				itemVisibility[itemId] = isIntersecting;
			});
		},
		{
			root: containerRef,
			rootMargin,
			threshold: 0,
		},
	);

	return {
		itemRefs,
		itemVisibility,
		itemStyle,
		containerStyle,
	};
};
