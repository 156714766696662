<template>
	<div :class="{ 'dialog-simple-layout': true }">
		<BackButton v-show="props.hasBackButton" :class="{ 'back-button': true, 'has-header': hasHeader }" :enable-back-action="false" @click="backButtonClicked" />
		<!--
		/* WHY: This needs to be v-show as v-if, for some reason, breaks multi dialogs (which use swiper). The dialogs don't align correctly when re-opened after navigating beyond first dialog and closing  */
		-->
		<CloseButton
			v-show="props.hasCloseButton"
			:class="{ 'close-button': true, 'has-header': hasHeader }"
			:enable-back-action="props.autoWireCloseButton"
			:show-light="props.showLightCloseButton"
			:show-background="props.showBackgroundCloseButton"
			@click="closeButtonClicked"
		/>
		<div v-if="hasHeader" class="header">
			<slot name="header">
				<h2>{{ props.header }}</h2>
			</slot>
		</div>
		<div :class="{ 'content': true, 'full-width': props.fullWidth, 'show-padding': props.showPadding }">
			<slot name="default" />
		</div>
	</div>
</template>

<script setup>
import { computed, useSlots } from "vue";

import CloseButton from "./CloseButton.vue";
import BackButton from "./BackButton.vue";

const props = defineProps({
	header: {
		type: String,
		default: null,
	},
	hasCloseButton: {
		type: Boolean,
		default: true,
	},
	hasBackButton: {
		type: Boolean,
		default: false,
	},
	showLightCloseButton: {
		type: Boolean,
		default: false,
	},
	showBackgroundCloseButton: {
		type: Boolean,
		default: false,
	},
	fullWidth: {
		type: Boolean,
		default: false,
	},
	showPadding: {
		type: Boolean,
		default: true,
	},
});

const slots = useSlots();

const hasHeader = computed(() => props.header || slots.header);

const emit = defineEmits(["back", "close"]);

function closeButtonClicked() {
	emit("close");
}

function backButtonClicked() {
	emit("back");
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables_new.scss";

.dialog-simple-layout {
	position: relative;
	display: flex;
	flex-direction: column;
	// width: 100%;
	overflow: auto;
	flex-grow: 1;

	.back-button {
		position: absolute;
		z-index: 2;
		top: 0;
		left: 0;
		margin: calc($spacing / 2);
	}

	.close-button {
		position: absolute;
		z-index: 2;
		top: 0;
		right: 0;
		margin: calc($spacing / 2);
		@include swiper-overlay-fix;
	}

	.back-button,
	.close-button {
		&.has-header {
			margin: calc($spacing);
		}
	}

	.header {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: calc($spacing) 0;
		border-bottom: $border-size-primary solid $border-color-primary;

		> h2 {
			font-size: $text-size-quaternary;
		}
	}

	.content {
		position: relative;
		flex-grow: 1;
		display: flex;
		gap: calc($spacing / 2);
		flex-direction: column;
		box-sizing: border-box;
		overflow: auto;

		&.show-padding {
			margin: $spacing calc($spacing * 2) $spacing calc($spacing * 2);
			padding: calc($spacing) calc($spacing * 0.5) calc($spacing) calc($spacing * 0.5);
		}

		&.full-width {
			margin-left: 0;
			margin-right: 0;
			padding-left: 0;
			padding-right: 0;
		}
	}
}

@media (min-width: $bp-medium) {
	.dialog-simple-layout {
		.content {
			// flex-grow: 1;
		}
	}
}
</style>
