import { computed, reactive, unref } from "vue";

// import MAP_TYPE_BLACKLIST from "../../../google-place-types-blacklist-hardcoded.json";
// import MAP_TYPE_ESTABLISHMENT_WHITELIST from "../../../google-place-types-establishment-whitelist-hardcoded.json";

// const MAP_TYPE_ESTABLISHMENT = "establishment";

export default class {
	constructor({ logger, modelBuilder, api, authentication, cities }) {
		this.logger = logger.nested({ name: "Model" });
		this.modelBuilder = modelBuilder;
		this.api = api;
		this.authentication = authentication;
		this.cities = cities;
	}

	execute({ google, location, searchText, cityName, filterState, size = 20 } = {}) {
		return this.modelBuilder.build({
			name: "SearchMap",
			args: { isSignedIn: computed(() => this.authentication.isAuthenticated.value), isLoaded: computed(() => unref(google).isLoaded), location, searchText, cityName, filterState, size },
			execute: async ({ args }) => {
				const foundCity = this.cities.value.find(({ name }) => name.toLowerCase() === args.cityName);
				if (!foundCity) {
					throw new Error(`City '${args.cityName}' not found`);
				}

				let searchResults = { venues: [], tags: [], lists: [], zones: [] },
					places = [];

				if (args.location && args?.isLoaded) {
					[searchResults, places] = await Promise.all([
						this.#getSearchResults(args, foundCity.id),
						// this.#getGooglePlaces(google, args),
					]);
				}

				return { search: reactive({ ...searchResults, places }) };
			},
		});
	}

	async #getSearchResults(args, cityId) {
		const filters = {
			...args.filterState,
			listIds: args.filterState.listIds?.map(({ id }) => id),
			zoneIds: args.filterState.zoneIds?.map(({ id }) => id),
			prices: args.filterState.prices?.map((price) => parseInt(price)),
			location: args.location ? { latitude: args.location.lat, longitude: args.location.lng, distance: 30000 } : undefined,
		};

		const data = await this.api.queries.searchMap(
			{ search: args.searchText, filters, cityId, first: args.size },
			{ useClientCache: false, accessToken: args.isSignedIn ? this.authentication.getAccessToken : undefined },
		);

		const detachedSearch = data?.city.search ?? null;

		const venues = computed(() => detachedSearch.venueResults.venues);
		const tags = computed(() => detachedSearch.tagResults.tags);
		const lists = computed(() => detachedSearch.listResults.lists);
		const zones = computed(() => detachedSearch.zoneResults.zones);
		const places = computed(() => detachedSearch.googleLocations.places);
		return { venues, tags, lists, zones, places };
	}

	// async #getGooglePlaces(googleRef, args) {
	// 	let places = [];

	// 	if (args.searchText?.length > 0) {
	// 		const google = unref(googleRef);
	// 		const results = await google.services.autocomplete.getPlacePredictions({
	// 			input: args.searchText,
	// 			language: "en",
	// 			locationRestriction: new google.maps.Circle({ center: args.location, radius: 30000 }),
	// 		});
	// 		const placePredictions = results.predictions
	// 			.map((prediction) => ({
	// 				...prediction,
	// 				matchedBlacklistTypes: prediction.types.filter((type) => MAP_TYPE_BLACKLIST.includes(type)),
	// 				exclude: prediction.types.filter((type) => MAP_TYPE_BLACKLIST.includes(type)).length > 0,
	// 			}))
	// 			.filter(({ exclude }) => !exclude)
	// 			.map((prediction) => ({
	// 				...prediction,
	// 				include:
	// 					!prediction.types.includes(MAP_TYPE_ESTABLISHMENT) ||
	// 					(prediction.types.includes(MAP_TYPE_ESTABLISHMENT) && prediction.types.filter((type) => MAP_TYPE_ESTABLISHMENT_WHITELIST.includes(type)).length > 0),
	// 			}))
	// 			.filter(({ include }) => include);

	// 		places = await Promise.all(
	// 			placePredictions.map((placePrediction) => {
	// 				return new Promise((resolve, reject) => {
	// 					google.services.places.getDetails({ placeId: placePrediction.place_id }, (place, status) => {
	// 						if (status === "OK") {
	// 							resolve({
	// 								place_id: placePrediction.place_id,
	// 								name: place.name,
	// 								description: placePrediction.description,
	// 								location: {
	// 									lat: place.geometry.location.lat(),
	// 									lng: place.geometry.location.lng(),
	// 								},
	// 							});
	// 						} else {
	// 							reject(new Error(`Could not get place details for placeId '${placePrediction.place_id}': ${status}`));
	// 						}
	// 					});
	// 				});
	// 			}),
	// 		);
	// 	}

	// 	return places;
	// }
}
