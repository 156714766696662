import { computed, inject, unref } from "vue";

export const useMatchAdvertResult = (args) => {
	const model = inject("model");

	const query = model.queries.MatchAdvert({
		placementName: computed(() => unref(args.placementName)),
		matchJson: computed(() => unref(args.matchJson)),
		placementSize: computed(() => ({ width: unref(args.placementWidth), height: unref(args.placementHeight) })),
	});

	const matchAdvertResult = computed(() => query?.model?.matchAdvertResult);
	const asset = computed(() => matchAdvertResult.value?.asset);
	const ctaLink = computed(() => matchAdvertResult?.value?.ctaLink);
	const imageSizes = computed(() => `${unref(args.placementWidth)}px`);
	const imageWidths = computed(() => new Array(3).fill(null).map((v, index) => unref(args.placementWidth) * (index + 1)));
	const imageAspectRatio = computed(() => unref(args.placementWidth) / unref(args.placementHeight));

	return {
		matchAdvertResult,
		asset,
		ctaLink,
		imageSizes,
		imageWidths,
		imageAspectRatio,
	};
};
