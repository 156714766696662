<template>
	<div class="premium">
		<custom-router-link :to="{ name: PageNames.PERKS, params: { city: currentCityName } }" class="premium-link" @click="buttonClicked">
			<img draggable="false" src="../assets/icons/Diamond-Black.svg" alt="premium" class="premium-icon" />
			<span class="premium-text">perks</span>
		</custom-router-link>
	</div>
</template>

<script setup>
import { computed } from "vue";

import { useConfig } from "../domain/index.js";
import { safeHapticsVibrate, useRouteParams } from "../helpers/index.js";
import { PageNames } from "../constants/index.js";

const config = useConfig();
const routeParams = useRouteParams();

const currentCityName = computed(() => routeParams.cityName ?? config.defaultCityName);

function buttonClicked() {
	safeHapticsVibrate({ duration: 20 });
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables_new.scss";

.premium {
	.premium-link {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 1px;

		@media (pointer: fine) {
			&:hover {
				opacity: 50%;
			}
		}

		& > img {
			height: 22px;
			padding: 1px;
		}
	}

	.premium-text {
		font-size: $text-size-quinary;
		text-transform: capitalize;
	}
}

@media (min-width: $bp-medium) {
	.premium {
		.premium-icon {
			display: none;
		}

		.premium-link {
			&.router-link-active-partial-path {
				.premium-text {
					// font-weight: $text-bold-primary;
				}
			}
		}
		.premium-text {
			display: block;
			font-weight: $text-bold-tertiary;
			font-size: $text-size-header;
		}
	}
}
</style>
