<template>
	<div class="login-page">
		<Dialog :dialog="loginDialog">
			<DialogLayoutFullscreen :dialog="loginDialog" class="login-dialog-layout" :has-safe-area="false" :is-full-screen-mobile="false" :has-close-button="false">
				<div class="login-container">
					<div class="image-container">
						<ImgIxWithPlaceholder
							src="https://onezone.imgix.net/venue_image_cky8tf31izlq20a985v34p2ql_1688140030564"
							sizes="400px"
							:widths="[400, 800, 1200, 1600]"
							:aspect-ratio="0.74"
							:is-lazy="false"
							class="image"
						/>
					</div>
					<LoadingMessage v-if="isProcessing" class="processing-loading-message">Please wait...</LoadingMessage>
					<div v-else-if="!isAuthenticated" class="login-form">
						<img src="../assets/onezone-logo.png" class="logo" />
						<div class="intro-text">Curated by us. Discovered by you.</div>
						<div class="sign-up-or-login">
							<button data-signup class="link-button-secondary" @click="signUpOnClick">Sign Up</button>
							<span>or</span>
							<button data-login class="link-button-secondary" @click="loginOnClick">Log In</button>
						</div>
					</div>
				</div>
			</DialogLayoutFullscreen>
		</Dialog>
	</div>
</template>

<script setup>
import { computed, inject, onActivated, onDeactivated, onUnmounted, ref } from "vue";
import { useEventListener } from "@vueuse/core";

import { Dialog, useDialog } from "../components/dialog/useDialog.js";

import DialogLayoutFullscreen from "../components/dialog/DialogLayoutFullscreen.vue";
import ImgIxWithPlaceholder from "../components/imgix/ImgIxWithPlaceholder.vue";

const loginDialog = useDialog({ isOpen: true });

const currentEnvironment = inject("currentEnvironment");
const queryString = inject("queryString");

const isProcessing = ref(false);

const isAuthenticated = computed(() => currentEnvironment.value.isAuthenticated.value);

useEventListener(window, "pageshow", (event) => {
	if (event.persisted) {
		isProcessing.value = false;
	}
});

onActivated(() => {
	loginDialog.open();
});

onDeactivated(() => {
	loginDialog.close();
});

onUnmounted(() => {
	loginDialog.close();
});

const signUpOnClick = async () => {
	isProcessing.value = true;
	if (!(await currentEnvironment.value.createAccount({ returnTo: queryString.returnTo }))) {
		isProcessing.value = false;
	}
};
const loginOnClick = async () => {
	isProcessing.value = true;
	if (!(await currentEnvironment.value.login({ returnTo: queryString.returnTo }))) {
		isProcessing.value = false;
	}
};
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

.login-page {
	height: 100%;
}

.login-dialog-layout {
	width: 100vw;
	max-width: 100vw;
	height: 100dvh;
	max-height: 100dvh;
	box-shadow: none;
	background-color: $background-color-primary;

	:deep(.login-container) {
		height: 100%;

		.image-container {
			display: none;
		}

		.processing-loading-message,
		.login-form {
			height: 100%;
			display: flex;
			gap: calc($spacing * 1.5);
			flex-direction: column;
			justify-content: center;
			align-items: center;

			.logo {
				width: 180px;
			}

			.intro-text {
				font-size: $text-size-primary;
				font-weight: $text-bold-tertiary;
			}
		}

		.login-form {
			.sign-up-or-login {
				display: flex;
				gap: calc($spacing/2);

				> button,
				span {
					font-size: $text-size-secondary;
				}

				> button {
					font-weight: $text-bold-primary;
					cursor: pointer;
				}
			}
		}
	}
}

@media (min-width: $bp-medium) {
	.login-page {
		background-image: url("../assets/home-feed-screenshot.png");
		background-size: 1008px auto;
		background-position: 5px top;
		background-repeat: no-repeat;
		image-rendering: pixelated;
		filter: blur(4px);
		-webkit-filter: blur(4px);
	}

	.login-dialog-layout {
		max-width: min(800px, 90vw) !important;
		max-height: min(500px, 90dvh) !important;
		@include drop-shadow-subtle;

		:deep(.content) {
			padding: 0 !important;
		}

		:deep(.login-container) {
			display: flex;
			height: 100%;

			.image-container {
				display: block;
				width: 50%;
				overflow: hidden;
			}

			.processing-loading-message,
			.login-form {
				flex-shrink: 0;
				width: 50%;
				min-width: 400px;
			}
		}
	}
}
</style>
