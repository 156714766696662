import { computed, reactive, ref, toRef } from "vue";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import utc from "dayjs/plugin/utc";
dayjs.extend(duration);
dayjs.extend(utc);

import { BookingReservationConfirmationChallengeType, BookingStageNames } from "../constants/index.js";
import { useQueryString } from "../functions/query-string/index.js";
import loggingMessages from "./BookingStageStrategyOpenTable.logging-messages.js";

import VenueBookingStageCollectReservationDetails from "../components/venue/VenueBookingStageCollectReservationDetails.vue";
import VenueBookingStageReservationConfirmed from "../components/venue/VenueBookingStageReservationConfirmed.vue";

export class BookingStageStrategyOpenTable {
	constructor({ logger, model, router, route, venueId, venue, sessionStateManager, tracking, venueBookingParams, bookingProviderAssociation }) {
		this.logger = logger.nested({ name: "BookingStageStrategyOpenTable" });
		this.model = model;

		this.route = route;
		this.venueId = venueId;
		this.venue = venue;
		this.sessionStateManager = sessionStateManager;
		this.tracking = tracking;
		this.venueBookingParams = venueBookingParams;
		this.bookingProviderAssociation = bookingProviderAssociation;

		this.isCancellingLock = this.sessionStateManager.get("VenueBooking:isCancellingLock");

		this.queryString = useQueryString(
			{
				reservationToken: null,
				reservationTokenExpiresAt: null,
				reservationConfirmationMessage: null,
				reservationConfirmationCode: null,
				reservationTime: null,
				reservationPartySize: null,
				manageReservationUrl: null,
			},
			{ router, route },
		);

		const isMakingReservation = ref(false),
			makingReservationError = ref(null);

		const reservationTokenExpiresAt = computed(() => (this.queryString.reservationTokenExpiresAt ? dayjs.utc(this.queryString.reservationTokenExpiresAt).toISOString() : null));

		this.stages = {
			[BookingStageNames.COLLECT_RESERVATION_DETAILS]: {
				back: async (...args) => {
					await this.#cancelLock(...args);
					this.venueBookingParams.currentStageName = BookingStageNames.SELECT_TIME_SLOT;
				},
				dialogClose: async (...args) => {
					await this.#cancelLock(...args);
				},
				component: {
					is: VenueBookingStageCollectReservationDetails,
					vBind: reactive({
						error: makingReservationError,
						isMakingReservation,
						reservationToken: toRef(this.queryString, "reservationToken"),
						reservationTokenExpiresAt,
						timeSlot: toRef(this.venueBookingParams, "timeSlot"),
						isCreditCardRequired: toRef(this.venueBookingParams, "isCreditCardRequired"),
						partySize: computed(() => this.venueBookingParams.partySize?.toString()),
						isCancellingLock: this.isCancellingLock,
					}),
					vOn: {
						lockCancelled: async (...args) => {
							await this.#cancelLock(...args);
							this.venueBookingParams.currentStageName = BookingStageNames.SELECT_TIME_SLOT;
						},
						reservationDetailsSubmitted: async (reservationDetails = {}, { button } = {}) => {
							isMakingReservation.value = true;
							const { error, challenge } = await this.#makeReservation(reservationDetails, null, { button });
							if (error) {
								makingReservationError.value = error;
							} else if (challenge) {
								const type = challenge.type;
								if (type === BookingReservationConfirmationChallengeType.STRIPE_SCA) {
									const { paymentMethod, token, last4, clientSecret, connectedAccountPk } = challenge.data;
									const stripe = window.Stripe(connectedAccountPk);
									const cardSetup = await stripe.confirmCardSetup(clientSecret, { payment_method: paymentMethod });
									if (cardSetup.error) {
										makingReservationError.value = cardSetup.error.message ?? "Error confirming card details";
									} else {
										const challengeDetails = { type, data: { token, last4, paymentMethod, setupIntent: cardSetup.setupIntent.id } };
										const { error: challengeError, challenge: secondChallenge } = await this.#makeReservation(reservationDetails, challengeDetails, { button });
										if (challengeError) {
											makingReservationError.value = challengeError;
										} else if (secondChallenge) {
											this.logger.log(loggingMessages.unexpectedChallengeWhileMakingReservation, { venueId: this.venueId, typeName: secondChallenge.type });
											makingReservationError.value = "Error confirming card details";
										}
									}
								}
							}
							isMakingReservation.value = false;
						},
						clearError: () => {
							makingReservationError.value = null;
						},
					},
				},
			},
			[BookingStageNames.RESERVATION_CONFIRMED]: {
				component: {
					is: VenueBookingStageReservationConfirmed,
					vBind: reactive({
						confirmationMessage: toRef(this.queryString, "reservationConfirmationMessage"),
						confirmationCode: toRef(this.queryString, "reservationConfirmationCode"),
						time: toRef(this.queryString, "reservationTime"),
						partySize: toRef(this.queryString, "reservationPartySize"),
						manageReservationUrl: toRef(this.queryString, "manageReservationUrl"),
					}),
				},
			},
		};
	}

	async #makeReservation(reservationDetails = {}, challengeDetails, { button } = {}) {
		const { success, challenge, error, message, confirmationCode, time, partySize, manageReservationUrl } = await this.model.mutations.MakeVenueBookingReservation({
			venueId: this.venueId,
			reservationToken: this.queryString.reservationToken,
			seatingPosition: this.venueBookingParams.seatingPosition,
			reservationDetails,
			challengeDetails,
		});
		if (success) {
			await this.tracking.bookingConfirmed({ ...this.#getStandardTrackingDetails(), button: { ...button, pageName: this.route.name } });
			this.queryString.reservationConfirmationMessage = message;
			this.queryString.reservationConfirmationCode = confirmationCode;
			this.queryString.reservationTime = time;
			this.queryString.reservationPartySize = partySize;
			this.queryString.manageReservationUrl = manageReservationUrl;
			this.venueBookingParams.currentStageName = BookingStageNames.RESERVATION_CONFIRMED;
			return { error: null, challenge: null };
		} else {
			if (error) {
				await this.tracking.bookingFailed({ ...this.#getStandardTrackingDetails(), error, button: { ...button, pageName: this.route.name } });
			}
			return { error, challenge };
		}
	}

	async #cancelLock({ button = {} }) {
		if (this.queryString.reservationToken) {
			this.isCancellingLock.value = true;
			this.logger.log(loggingMessages.cancellingLock, { venueId: this.venueId });
			await Promise.all([
				await this.model.mutations.UnlockVenueBookingAvailability({ venueId: this.venueId, reservationToken: this.queryString.reservationToken }),
				await this.tracking.bookingTimeSlotCancelled({ ...this.#getStandardTrackingDetails(), button: { ...button, pageName: this.route.name } }),
			]);
			this.queryString.reservationToken = null;
			this.queryString.reservationTokenExpiresAt = null;
			this.isCancellingLock.value = false;
		}
	}

	#getStandardTrackingDetails() {
		return {
			venue: this.venue,
			bookingProviderAssociation: this.bookingProviderAssociation,
			searchDateTime: this.venueBookingParams.adjustedStartTime,
			partySize: this.venueBookingParams.partySize,
			timeSlot: this.venueBookingParams.timeSlot,
			seatingPosition: this.venueBookingParams.seatingPosition,
			isCreditCardRequired: this.venueBookingParams.isCreditCardRequired,
		};
	}

	closed() {
		this.queryString.clear();
	}
}
