<template>
	<div class="explore-list-container">
		<PageHeader v-if="isHeaderReady" :show-share-button="!showLoadingOrErrorMessage" :share-title="list?.name">
			<template #breadcrumb>
				<custom-router-link :to="{ name: 'City/Explore/Lists/Category', params: { city: routeParams.cityName, categoryName } }">
					{{ listBreadcrumb?.text }}
				</custom-router-link>
			</template>
			<template v-if="!showLoadingOrErrorMessage">{{ list?.name }}</template>
			<template v-if="!showLoadingOrErrorMessage && firstEntityType === EntityTypeNames.Venue" #controls>
				<VenueFilterClientSide ref="venueFilterClientSideRef" :tags="tags" :venues="sortedVenues" />
			</template>
		</PageHeader>
		<StandardSearchContext :align-center="false" :tags="tags" :filter-state-manager="venueFilterClientSideRef?.filterStateManager" :show-search-text="false" />
		<loading-message-with-error v-if="showLoadingOrErrorMessage" :error="queries.error" :retry="queries.retry" class="loading-message"> Loading, please wait... </loading-message-with-error>
		<DownloadApp v-else-if="!entityTypeConfig" />
		<div v-else class="explore-list">
			<!-- <SuperList v-slot="{ item: venue, style }" :name="`list-${list?.id}`" :items="venueFilterClientSideRef?.filteredVenues ?? []" :item-size="76" :is-virtual="true" class="explore-list-venues-list">
				<VenueCard :key="venue.id" :venue="venue" :show-mini="true" :user-lists="userLists" :style="style" />
			</SuperList> -->
			<NewList
				v-slot="{ item, isItemVisible }"
				:name="`list-${list?.id}`"
				:items="entityTypeConfig.items"
				:direction="ListDirections.VERTICAL"
				:item-height="entityTypeConfig.itemHeight"
				:gap-size="0"
				:item-buffer-count="4"
			>
				<component :is="item.component" v-if="isItemVisible" v-bind="item" />
			</NewList>
		</div>
		<VenueProductEnquiryDialog ref="venueProductEnquiryDialogRef" />
	</div>
</template>

<script setup>
import { inject, computed, ref, onMounted, toRef, reactive, unref, provide } from "vue";

import { combineQueries, useRouteParams } from "../helpers/index.js";
import { EntityTypeNames, ListCategory, ListCategoryUrlParamLookupByName, ListDirections } from "../constants/index.js";
import { useVenueProductSummary } from "../composables/useVenueProductSummary.js";

import VenueCard from "./VenueCard.vue";
// import VenueProductCard from "./VenueProductCard.vue";
import VenueProductSummaryCard from "./VenueProductSummaryCard.vue";
// import SuperList from "./SuperList.vue";
import NewList from "./NewList.vue";
import PageHeader from "./PageHeader.vue";
import StandardSearchContext from "./StandardSearchContext.vue";
import VenueFilterClientSide from "./VenueFilterClientSide.vue";
import DownloadApp from "./DownloadApp.vue";
import VenueProductEnquiryDialog from "./venue/VenueProductEnquiryDialog.vue";

const LIST_CATEGORY_BREADCRUMB = {
	[ListCategory.VIBES]: { text: "All Vibe Lists", categoryName: ListCategoryUrlParamLookupByName[ListCategory.VIBES] },
	[ListCategory.CUISINES]: { text: "All Cuisine Lists", categoryName: ListCategoryUrlParamLookupByName[ListCategory.CUISINES] },
	[ListCategory.OCCASIONS]: { text: "All Occasion Lists", categoryName: ListCategoryUrlParamLookupByName[ListCategory.OCCASIONS] },
	[ListCategory.MOST_POPULAR]: { text: "All Top Lists", categoryName: ListCategoryUrlParamLookupByName[ListCategory.MOST_POPULAR] },
};

const LIST_CATEGORY_PREFERENCE_ORDER = [ListCategory.MOST_POPULAR, ListCategory.CUISINES, ListCategory.OCCASIONS, ListCategory.VIBES];

const routeParams = useRouteParams();

const isAppHydrated = inject("isAppHydrated");
const model = inject("model");
const currentEnvironment = inject("currentEnvironment");
// const logger = inject("logger");
// const { venueResults, hasResults, allQueries: allSearchQueries } = inject("searchResults");

const isMounted = ref(false);
const venueFilterClientSideRef = ref(null);
const venueProductEnquiryDialogRef = ref(null);

onMounted(() => {
	isMounted.value = true;
});

const isSignedIn = computed(() => currentEnvironment.value?.isSignedIn.value);

const queries = combineQueries({
	listQuery: model.queries.GetList({
		listId: computed(() => routeParams.listId),
	}),
	userListsQuery: model.queries.GetCurrentUserLists({ isSignedIn }),
	tags: model.queries.GetTags({
		cityName: toRef(routeParams, "cityName"),
	}),
	venueProductTypes: model.queries.GetVenueProductTypes(),
});

const list = computed(() => queries.model?.list);
const showLoadingOrErrorMessage = computed(() => queries.isLoading || queries.error);
const tags = computed(() => queries.model?.tags ?? []);
const userLists = computed(() => queries.model?.currentUserLists ?? []);
const listCategories = computed(() => (list.value?.category?.length > 0 ? list.value.category : null));
const sortedListCategories = computed(() =>
	listCategories.value ? [...listCategories.value].sort((a, b) => LIST_CATEGORY_PREFERENCE_ORDER.indexOf(a) - LIST_CATEGORY_PREFERENCE_ORDER.indexOf(b)) : null,
);
const listCategory = computed(() => (sortedListCategories.value ? sortedListCategories.value[0] : null));
const listBreadcrumb = computed(() => (listCategory.value ? LIST_CATEGORY_BREADCRUMB[listCategory.value.name] : null));
const categoryName = computed(() => listBreadcrumb.value?.categoryName ?? null);
const isHeaderReady = computed(() => !!categoryName.value);
const venueProductTypes = computed(() => queries.model?.venueProductTypes);

const listVenues = computed(
	() =>
		unref(list)
			.items?.filter(({ venue }) => venue?.published)
			.map(({ venue }) => {
				return venue;
			}) ?? [],
);
const sortedVenues = computed(() => listVenues.value);

const venueProductsSummary = useVenueProductSummary(list);

const entityTypes = computed(() => unref(list).entityTypes ?? []);
const firstEntityType = computed(() => entityTypes.value?.[0]);

const ENTITY_TYPE_CONFIG_LOOKUP = {
	[EntityTypeNames.Venue]: reactive({
		items: computed(() =>
			(venueFilterClientSideRef.value?.filteredVenues ?? []).map((venue) => ({ id: venue.id, component: VenueCard, class: "venue", venue, showMini: true, userLists: userLists.value })),
		),
		itemHeight: 76,
	}),
	[EntityTypeNames.VenueProduct]: reactive({
		items: computed(() =>
			venueProductsSummary.value.map(({ venue, venueProducts }) => ({
				id: venue.id,
				component: VenueProductSummaryCard,
				class: "venue-product",
				venue,
				venueProducts,
				venueProductTypes: venueProductTypes.value,
			})),
		),
		itemHeight: 136,
	}),
	// [EntityTypeNames.VenueProduct]: reactive({
	// 	items: computed(() => sortedVenueProducts.value.map((venueProduct) => ({ id: venueProduct.id, component: VenueProductCard, class: "venue-product", venueProduct }))),
	// 	itemHeight: 184,
	// }),
};
const entityTypeConfig = computed(() => ENTITY_TYPE_CONFIG_LOOKUP[firstEntityType.value]);
// const listCityName = computed(() => list.value?.city?.()?.name ?? null);
// ensureCorrectCity(logger, listCityName);

provide("venueProductEnquiryDialogRef", venueProductEnquiryDialogRef);

if (!isAppHydrated.value) {
	await queries.promise;
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables_new.scss";

.explore-list-container {
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: calc($spacing / 2);
	// padding: 0 calc($spacing * 2);
	margin-top: $spacing;
	overflow: auto;
	@include centre-loading-message;

	.explore-list {
		display: flex;
		flex-direction: column;
		gap: calc($spacing * 0.5);
		overflow: auto;
		overscroll-behavior: none;

		:deep(.super-list) {
			padding-right: calc($spacing / 2);
		}

		.category-tabs {
			margin-bottom: calc($spacing * 1.5);
		}

		// .explore-list-venues-list {
		// 	overflow: auto;
		// 	display: flex;
		// 	flex-direction: column;
		// 	gap: $spacing;
		// 	align-content: start;

		// 	.image {
		// 		width: 160px;
		// 		height: 160px;
		// 	}
		// }

		:deep(.venue-product) {
			padding: calc($spacing / 2);
		}
	}
}

@media (min-width: $bp-medium) {
	.explore-list-container {
		padding: 0;
	}
}
</style>
