<template>
	<div ref="listContainerRef" :class="{ 'list-container': true, 'load-more-enabled': props.enableLoadMore }">
		<ScrollArrows v-if="props.showNavigationButtons" class="scroll-arrows" :container-ref="listContainerRef" :scroll-ref="containerRef" :item-count="items.length" :item-size="props.itemSize" />
		<Refreshing class="refreshing" :stage="pullToRefresh.pulledAmount" :style="pullToRefresh.style" />
		<LoadingMore v-if="props.enableLoadMore" :is-visible="isLoadingMore" class="loading-more" />
		<loading-message-with-error
			v-if="(props.query?.isLoading && (props.query?.state?.loading ?? LOADING_STATE.LOAD_INITIAL) === LOADING_STATE.LOAD_INITIAL) || props.query?.error"
			:error="props.query?.error"
			:retry="props.query?.retry"
			class="loading-message"
		>
			{{ props.loadingMessage }}
		</loading-message-with-error>
		<EmptyResultsMessage v-else-if="items.length === 0">
			{{ props.emptyMessage }}
		</EmptyResultsMessage>
		<RecycleScroller
			v-else-if="isVirtual"
			ref="scrollerRef"
			v-slot="{ item, index }"
			v-dragscroll="isDragScrollEnabled"
			:direction="props.direction"
			:class="{ 'super-list': true, [`${props.direction.toLowerCase()}-list`]: true }"
			:item-size="hasCustomPadding ? undefined : props.itemSize"
			:items="hasCustomPadding ? itemsWithSize : items"
			:key-field="props.keyField"
			:buffer="props.itemSize * props.bufferItemCount"
			@click.capture="scrollerClick"
			@dragscrollend="dragScrollEnd"
			@dragscrollmove="dragScrollMove"
		>
			<slot v-bind="{ item, index, style: itemStyle(index) }" />
		</RecycleScroller>
		<div v-else ref="listElRef" :class="{ 'super-list': true, 'static-list': true, [`${props.direction.toLowerCase()}-list`]: true }">
			<slot v-for="(item, index) in itemsWithSize" v-bind="{ item, index, style: itemStyle(index) }" />
		</div>
	</div>
</template>

<script setup>
import { computed, inject, markRaw, onMounted, reactive, ref } from "vue";
import { useInfiniteScroll } from "@vueuse/core";
import { dragscroll } from "vue-dragscroll";

import { useScrollMemory } from "../functions/scroll-memory/index.js";
import { usePullToRefresh } from "./pull-to-refresh/usePullToRefresh.js";
// import { useScrollClickBlocker } from "../helpers/index.js";
import { LOADING_STATE } from "../constants/index.js";

import Refreshing from "./Refreshing.vue";
import LoadingMore from "./LoadingMore.vue";
import ScrollArrows from "./ScrollArrows.vue";
import EmptyResultsMessage from "./EmptyResultsMessage.vue";

const DIRECTIONS = {
	VERTICAL: "vertical",
	HORIZONTAL: "horizontal",
};

const SCROLL_DRAG_CLICK_THRESHOLD = 10;

defineOptions({ directives: { dragscroll } });

const props = defineProps({
	name: {
		type: String,
		required: true,
	},
	isVirtual: {
		type: Boolean,
		default: false,
	},
	keyField: {
		type: String,
		default: "id",
	},
	direction: {
		type: String,
		default: "vertical",
	},
	query: {
		type: Object,
		default: null,
	},
	items: {
		type: Array,
		required: true,
	},
	itemSize: {
		type: Number,
		required: true,
	},
	bufferItemCount: {
		type: Number,
		default: 4,
	},
	loadMoreDistanceItemCount: {
		type: Number,
		default: 2,
	},
	loadingMessage: {
		type: String,
		default: "",
	},
	emptyMessage: {
		type: String,
		default: "No places found",
	},
	isLoadingMore: {
		type: Boolean,
		default: false,
	},
	firstItemPadding: {
		type: Number,
		default: 0,
	},
	lastItemPadding: {
		type: Number,
		default: 0,
	},
	enablePullToRefresh: {
		type: Boolean,
		default: false,
	},
	enableLoadMore: {
		type: Boolean,
		default: false,
	},
	showNavigationButtons: {
		type: Boolean,
		default: false,
	},
});

const emits = defineEmits(["loadMore", "refresh"]);

const platform = inject("platform");

const isMounted = ref(false);
const listContainerRef = ref(null);
const scrollerRef = ref(null);
const listElRef = ref(null);
const dragScrollData = { dragScrollEndTime: null, deltaX: null, deltaY: null };

const containerRef = computed(() => (props.isVirtual ? scrollerRef.value?.$el : listElRef.value));
const isVirtual = computed(() => props.isVirtual && !platform.isServer && isMounted.value);
const hasCustomPadding = computed(() => props.firstItemPadding !== 0 || props.lastItemPadding !== 0);
const items = computed(() => (props.isVirtual ? [...props.items] : props.items));
const itemsWithSize = computed(() =>
	items.value.map((item, index) => {
		let size = props.itemSize;
		if (isFirstItem(index) && props.firstItemPadding !== 0) {
			size += props.firstItemPadding;
		}
		if (isLastItem(index) && props.lastItemPadding !== 0) {
			size += props.lastItemPadding;
		}
		return { ...item, size };
	}),
);
// const isDragScrollEnabled = computed(() => !platform.isTouchEnabled);
const isDragScrollEnabled = computed(() => false);

const itemStyle = (index) => {
	const style = {
		marginLeft: isFirstItem(index) && props.firstItemPadding !== 0 ? `${props.firstItemPadding}px` : undefined,
		marginRight: isLastItem(index) && props.lastItemPadding !== 0 ? `${props.lastItemPadding}px` : undefined,
		height: props.direction === DIRECTIONS.VERTICAL && !props.isVirtual ? `${props.itemSize}px` : undefined,
		width: props.direction === DIRECTIONS.HORIZONTAL && !props.isVirtual ? `${props.itemSize}px` : undefined,
	};
	return style;
};

useScrollMemory({ name: props.name, containerRef });
useInfiniteScroll(
	containerRef,
	async () => {
		if (props.enableLoadMore) {
			emits("loadMore");
		}
	},
	{ distance: props.itemSize * props.loadMoreDistanceItemCount },
);
const pullToRefresh = usePullToRefresh(
	containerRef,
	reactive({
		enabled: computed(() => props.enablePullToRefresh),
		distance: 200,
		pullStages: 2,
		onRefresh: markRaw(async () => {
			setTimeout(() => {
				emits("refresh");
			}, 500);
		}),
	}),
);
// useScrollClickBlocker(containerElRef);

onMounted(() => {
	isMounted.value = true;
	// scrollMemory.mounted(containerElRef);
});

// onBeforeUnmount(() => {
// 	scrollMemory.beforeUnmount();
// });

// onActivated(() => {
// 	scrollMemory.activated(containerElRef);
// });

// onDeactivated(() => {
// 	scrollMemory.deactivated();
// });

function isFirstItem(index) {
	return index === 0;
}

function isLastItem(index) {
	return index === items.value.length - 1;
}

function scrollerClick(e) {
	if (dragScrollData.dragScrollEndTime) {
		const timeDiff = new Date() - dragScrollData.dragScrollEndTime;
		const wasClickFromADragScrollEvent = timeDiff < 50;
		const wasDragScrolledSignificantly = Math.abs(dragScrollData.deltaX) >= SCROLL_DRAG_CLICK_THRESHOLD || Math.abs(dragScrollData.deltaY) >= SCROLL_DRAG_CLICK_THRESHOLD;
		dragScrollData.dragScrollEndTime = null;
		dragScrollData.deltaX = null;
		dragScrollData.deltaY = null;
		if (wasClickFromADragScrollEvent && wasDragScrolledSignificantly) {
			e.preventDefault();
		}
	}
}

function dragScrollEnd() {
	dragScrollData.dragScrollEndTime = new Date();
}

function dragScrollMove(e) {
	dragScrollData.deltaX += e.detail.deltaX;
	dragScrollData.deltaY += e.detail.deltaY;
}
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";
.list-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;

	// :deep(.navigation-button) {
	// 	transform: translateY(-6px);
	// }

	.scroll-arrows {
		z-index: 2;
		position: absolute;
		padding: 0 calc($spacing / 2);
		display: none;

		@media (pointer: fine) {
			display: inherit;
		}
	}

	&.load-more-enabled .vertical-list {
		padding-bottom: calc($spacing * 2);
	}

	.loading-message {
		height: 100%;
	}

	.refreshing {
		position: absolute;
		transform: translateY(-100%);
	}

	.loading-more {
		position: absolute;
		bottom: 0px;
	}

	.vertical-list {
		height: 100%;
		overflow: auto;
		box-sizing: border-box;
		padding-right: calc($spacing / 2);
		overscroll-behavior: none;
	}
	.horizontal-list {
		height: 100%;
		overflow: auto;
		// overscroll-behavior: none;

		&.static-list {
			display: flex;
		}

		&::-webkit-scrollbar {
			width: 0px;
			height: 0px;
			background: transparent;
			display: none;
		}
	}

	:deep(.vue-recycle-scroller__item-view) {
		// will-change: unset;
	}
}
</style>
