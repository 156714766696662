<template>
	<div class="carousel-container">
		<NavigationArrow direction="left" :class="{ 'navigation-button-prev': true, [navigationUniqueId]: true }" />
		<NavigationArrow direction="right" :class="{ 'navigation-button-next': true, [navigationUniqueId]: true }" />
		<swiper
			class="carousel"
			:breakpoints="SWIPER_BREAKPOINTS"
			:modules="SWIPER_MODULES"
			:navigation="{ enabled: true, prevEl: `.navigation-button-prev.${navigationUniqueId}`, nextEl: `.navigation-button-next.${navigationUniqueId}` }"
			:pagination="{ clickable: true, dynamicBullets: true, dynamicMainBullets }"
			:edge-swipe-detection="true"
			:zoom="{ enabled: false }"
			:keyboard="{ enabled: true }"
			:resistance-ratio="props.bounciness ? undefined : 0"
			@slide-change="slideChange"
		>
			<swiper-slide v-for="(image, index) in props.images" :key="`image-${index}`" :virtual-index="index" class="slide">
				<slot v-bind="{ image }" />
			</swiper-slide>
		</swiper>
	</div>
</template>

<script setup>
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/zoom";
import { computed, ref } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Pagination, Virtual, Zoom, Keyboard } from "swiper/modules";
import cuid from "cuid";
import pDebounce from "p-debounce";

import NavigationArrow from "./NavigationArrow.vue";

const SWIPER_MODULES = [Navigation, Pagination, Virtual, Zoom, Keyboard];
const SWIPER_BREAKPOINTS = {
	0: {
		slidesPerView: 1,
		spaceBetween: 0,
		centredSlides: true,
	},
	400: {
		slidesPerView: 1,
		spaceBetween: 0,
		centredSlides: true,
	},
	768: {
		slidesPerView: 1,
		spaceBetween: 0,
		centredSlides: true,
	},
};

const props = defineProps({
	images: {
		type: Object,
		required: true,
	},
	bounciness: {
		type: Boolean,
		default: true,
	},
});

const emit = defineEmits(["interaction"]);

/* WHY: Generate a unique id for the navigation buttons to prevent conflicts when multiple carousels are on the same page. Without this all arrows control all carousels on the page at once */
const navigationUniqueId = ref(cuid());
let highestIndexViewed = null;

const debouncedImageGalleryInteraction = pDebounce(imageGalleryInteraction, 20000);

const dynamicMainBullets = computed(() => Math.min(props.images.length, 6));

function slideChange(swiper) {
	const { activeIndex } = swiper;

	if (activeIndex > 0) {
		if (activeIndex > highestIndexViewed) {
			highestIndexViewed = Math.max(highestIndexViewed, activeIndex);
			debouncedImageGalleryInteraction();
		}
	}
}

function imageGalleryInteraction() {
	emit("interaction", { highestIndexViewed });
}
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

$max-width-venue-carousel: 460px;
$max-height-venue-carousel-mobile: max(50dvh, 520px);
$max-height-venue-details-desktop: 700px;

.carousel-container {
	display: flex;

	.navigation-button-prev,
	.navigation-button-next {
		display: none;
	}

	:deep(.carousel) {
		flex-grow: 1;

		.slide {
			display: flex;
			align-items: center;
			overflow: hidden;
			max-height: $max-height-venue-carousel-mobile;

			.image {
				width: 100%;
				// object-fit: scale-down !important;
			}
		}

		.swiper-button-next,
		.swiper-button-prev {
			color: rgba($text-color-primary, 0.7);
		}

		.swiper-pagination {
			--swiper-pagination-bullet-size: 6px;
			--swiper-pagination-bullet-horizontal-gap: 3px;
			transform: none;
			left: auto;
			right: 0;
			bottom: $spacing;
			// right: 0;
			// left: auto;
			// text-align: right;
			// margin-right: calc($spacing * 2);
			// margin-bottom: calc($spacing / 2);
			// display: flex;
			// flex-direction: row;
			// align-items: center;
			// justify-content: flex-end;
		}
		.swiper-pagination-bullet {
			background-color: $background-color-primary;
			opacity: 0.8;
		}

		.swiper-pagination-bullet-active {
			background-color: $text-color-primary;
		}

		.swiper-button-disabled {
			opacity: 0;
		}

		.swiper-slide-active {
			z-index: 1000;
		}
	}
}

@media (min-width: $bp-medium) {
	.carousel-container {
		position: relative;
		width: 50%;
		max-width: $max-width-venue-carousel;

		&:hover {
			.navigation-button-prev,
			.navigation-button-next {
				z-index: 2;
				position: absolute;
				display: block;
				top: 50%;
				transform: translateY(-50%);
			}
			.navigation-button-prev {
				left: calc($spacing / 2);
				right: auto;
			}
			.navigation-button-next {
				right: calc($spacing / 2);
				left: auto;
			}
		}

		.swiper-button-disabled {
			opacity: 0;
		}

		:deep(.carousel) {
			height: 100%;

			.slide {
				justify-content: center;
				max-height: none;

				.image {
					/* 1px is to fix a bug where the image is not full height on safari. when resizing window, and text wraps on right, sometimes a pixel gap appears at bottom of image */
					height: calc(100% + 1px);
					object-fit: cover;
				}
			}

			.swiper-slide {
				// height: 100%;
			}
		}
	}
}
</style>
