<template>
	<MiniCard
		v-if="props.showMini"
		:img-ix="!props.showHashTagIcon"
		:text="props.tag.name"
		:middle-text="middleText"
		:bottom-text="bottomText"
		:image-src="imageUrl"
		:image-preview="imagePreview"
		:router-link="routerLink"
		:router-link-enabled="props.routerLinkEnabled"
	>
		<slot />
	</MiniCard>
	<Card
		v-else
		:img-ix="!props.showHashTagIcon"
		:top-text="props.tag.name"
		:top-sub-text="topSubText"
		:middle-text="middleText"
		:bottom-text="bottomText"
		:image-src="imageUrl"
		:image-preview="imagePreview"
		:router-link="routerLink"
		:router-link-enabled="props.routerLinkEnabled"
	>
		<slot />
	</Card>
</template>

<script setup>
import { computed } from "vue";

import { urlBuilder, useRouteParams } from "../helpers/index.js";
import { PageNames, TagCategoryNamesLookup } from "../constants/index.js";

import Card from "./Card.vue";
import MiniCard from "./MiniCard.vue";

import HashtagIcon from "../assets/icons/hashtag.png";

const props = defineProps({
	tag: {
		type: Object,
		required: true,
		validator: (tag) => {
			return tag.id && tag.name && (tag.venues || typeof tag.venueCount !== "undefined");
		},
	},
	zone: {
		type: Object,
		default: null,
	},
	showMini: {
		type: Boolean,
		default: false,
	},
	showHashTagIcon: {
		type: Boolean,
		default: true,
	},
	showTagCategory: {
		type: Boolean,
		default: false,
	},
	routerLink: {
		type: Object,
		default: null,
	},
	routerLinkEnabled: {
		type: Boolean,
		default: true,
	},
	showVenueCount: {
		type: Boolean,
		default: true,
	},
});

const imageUrl = computed(() => {
	return props.showHashTagIcon ? HashtagIcon : props.tag.image?.url;
});

const imagePreview = computed(() => {
	// const preview = props.tag.image?.preview ?? null;
	// return preview;
	return undefined;
});

const middleText = computed(() => (props.showTagCategory ? TagCategoryNamesLookup[props.tag.category] : undefined));

const bottomText = computed(() => {
	const venueCount = props.tag.venues?.length ?? props.tag.venueCount;
	return props.showVenueCount ? `${venueCount} Place${venueCount > 1 ? "s" : ""}` : null;
});

const topSubText = computed(() => props.zone?.name ?? "");

const routeParams = useRouteParams();

const routerLink = computed(() => {
	return props.zone
		? urlBuilder(PageNames.EXPLORE_ZONE_TAG, { cityName: routeParams.cityName, zone: props.zone, tag: props.tag })
		: urlBuilder(PageNames.EXPLORE_TAG, { cityName: routeParams.cityName, tag: props.tag });
});
</script>
