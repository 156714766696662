<template>
	<div>
		<dl>
			<dt>Impersonate User</dt>
			<dd>
				<input id="impersonateUser" v-model="email" type="text" @change="impersonateUser" />
			</dd>
		</dl>
	</div>
</template>

<script setup>
import { ref, watchEffect } from "vue";
import { useRouter } from "vue-router";

import { useQueryString } from "../../functions/query-string/index.js";

const router = useRouter();
const queryString = useQueryString({
	impersonate: null,
});

const impersonateUser = async () => {
	// queryString.set("impersonate", email.value);
	/* FIXME: We have to use router.push instead of queryString.set, as queryString.set will get overridden when we reload */
	await router.push({ query: { impersonate: email.value } });
	/* WHY: Reload the page so impersonation can be started */
	router.go();
};

const email = ref("");

watchEffect(() => {
	email.value = queryString.impersonate;
});
</script>

<style lang="scss" scoped>
@import "../../assets/styles/variables_new.scss";
dl {
	display: flex;
	flex-flow: row wrap;
	gap: $spacing;
	margin: 0;
}
dt {
	flex-basis: calc(35% - ($spacing * 2));
	border: 1px solid $border-color-primary;
	background: $background-color-tertiary;
	color: $text-color-secondary;
	text-align: right;
	text-align: right;
	padding: $spacing $spacing $spacing 0;
	box-sizing: border-box;
}
dd {
	flex-basis: calc(65% - ($spacing * 2));
	flex-grow: 1;
	border: 1px solid $border-color-primary;
	margin-left: 0;
	padding: 0 $spacing;
	display: flex;
	flex-direction: column;
	justify-content: center;
	box-sizing: border-box;

	select {
		max-width: 200px;
	}
}
</style>
