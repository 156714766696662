import { unref } from "vue";

import { useLogger } from "../functions/logger/useLogger.js";
import loggingMessages from "./externalLink.logging-messages.js";
import { openExternalLink } from "../helpers/openExternalLink.js";

export const externalLink = {
	mounted(el, binding) {
		const { clickHandler, openWindow = true, replaceUrl = false } = binding.value ?? {};
		const logger = useLogger({ name: "externalLink" });

		el.addEventListener("click", async (event) => {
			const url = el.href;
			try {
				event.preventDefault();
				if (clickHandler) {
					await clickHandler(event);
				}

				if (url) {
					const isCurrentHost = new URL(url).host === window.location.host;
					const isExternalLink = url.startsWith("http") && !isCurrentHost;
					const shouldOpenInNewWindow = unref(openWindow) && isExternalLink;

					openExternalLink({ url, replaceUrl, shouldOpenInNewWindow });
				}
			} catch (error) {
				logger.log(loggingMessages.errorOpeningExternalLink, { url, error });
			}
		});
	},
};
