<template>
	<div :class="{ 'city-container': true, 'has-transparent-background': hasTransparentBackground }">
		<div class="city">
			<!-- <transition :enter-active-class="'animate__animated animate__slideInDown'" :leave-active-class="'animate__animated animate__slideOutUp'"> -->
			<Header v-if="showHeader" class="header" />
			<!-- </transition> -->
			<div class="main">
				<div class="navigation-section">
					<!-- <transition :enter-active-class="'animate__animated animate__slideInUp'" :leave-active-class="'animate__animated animate__slideOutDown'"> -->
					<div v-if="showNavigation">
						<NetworkStatus class="network-status" />
						<Navigation class="navigation" />
					</div>
					<!-- </transition> -->
					<InfoSection class="info-section" />
				</div>
				<div class="spacer-left"></div>
				<div class="content">
					<ActivatedRouterView v-slot="{ Component }" class="router-view">
						<!-- <ActivatedRouterView v-slot="{ Component, route: matchedRoute }" class="router-view"> -->
						<!-- <transition v-bind="matchedRoute.meta?.transition ?? {}" :css="!!matchedRoute.meta?.transition"> -->
						<KeepAlive>
							<Component :is="Component" />
						</KeepAlive>
						<!-- </transition> -->
					</ActivatedRouterView>
				</div>
				<div class="spacer-right"></div>
			</div>
		</div>
		<CitySwitchingDialog ref="citySwitchingDialog" />
		<UserActionDialog ref="userActionDialogRef" />
		<UserDevicePermissionsDialog ref="userDevicePermissionsDialogRef" />
		<AlertDialog ref="alertDialogRef" />
		<DebugContextDialog ref="debugContextDialogRef" />
		<VenueBookingDialog ref="venueBookingDialogRef" />
		<FeedbackDialog ref="feedbackDialogRef" />
	</div>
</template>

<script setup>
import { computed, inject, provide, ref } from "vue";
import { useRoute } from "vue-router";

import { usePageSize } from "../domain/index.js";

import Header from "../components/Header.vue";
import Navigation from "../components/Navigation.vue";
import InfoSection from "../components/InfoSection.vue";
import CitySwitchingDialog from "../components/city-switching/CitySwitchingDialog.vue";
import UserActionDialog from "../components/UserActionDialog.vue";
import UserDevicePermissionsDialog from "../components/UserDevicePermissionsDialog.vue";
import AlertDialog from "../components/AlertDialog.vue";
import NetworkStatus from "../components/NetworkStatus.vue";
import DebugContextDialog from "../components/DebugContextDialog.vue";
import VenueBookingDialog from "../components/venue/VenueBookingDialog.vue";
import FeedbackDialog from "../components/FeedbackDialog.vue";

const userDevicePermissionsManager = inject("userDevicePermissionsManager");

const route = useRoute();
const pageSize = usePageSize();

const citySwitchingDialog = ref(null);
const userActionDialogRef = ref(null);
const userDevicePermissionsDialogRef = ref(null);
const alertDialogRef = ref(null);
const debugContextDialogRef = ref(null);
const venueBookingDialogRef = ref(null);
const feedbackDialogRef = ref(null);

const showHeader = computed(() => (pageSize.isSmallerThanMedium ? route.meta?.showHeader ?? true : true));
const showNavigation = computed(() => (pageSize.isSmallerThanMedium ? route.meta?.showNavigation ?? true : true));
const hasTransparentBackground = computed(() => route.meta?.hasTransparentBackground ?? false);

userDevicePermissionsManager.setDialogRef(userDevicePermissionsDialogRef);

provide("citySwitchingDialog", citySwitchingDialog);
provide("userActionDialogRef", userActionDialogRef);
provide("alertDialogRef", alertDialogRef);
provide("debugContextDialogRef", debugContextDialogRef);
provide("venueBookingDialogRef", venueBookingDialogRef);
provide("feedbackDialogRef", feedbackDialogRef);
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables_new.scss";

.city-container {
	height: 100%;

	&:not(.has-transparent-background) {
		background-color: $background-color-primary;
	}
	> .city {
		display: flex;
		flex-direction: column;
		height: 100%;
		position: relative;

		// .hide-header {
		// 	display: none;
		// }

		> .header {
			animation-duration: 0.3s;
			&.animate__slideOutUp,
			&.animate__fadeOut {
				position: absolute;
				z-index: 1000;
				width: 100vw;
			}
		}

		> .main {
			flex-grow: 1;
			display: flex;
			flex-direction: column;
			overflow: hidden;

			> .navigation-section {
				order: 2;
				position: relative;

				> div {
					animation-duration: 0.3s;

					&.animate__slideOutDown {
						position: absolute;
						top: -60px;
						width: 100vw;
						z-index: 1000;
					}

					.network-status {
						position: absolute;
						bottom: 100%;
						// bottom: 0;
						// bottom: 0;
						// width: 100%;
						z-index: 1000;
					}
				}
				.info-section {
					display: none;
				}
			}

			> .content {
				display: flex;
				flex-direction: column;
				position: relative;
				order: 1;
				flex-grow: 1;
				overflow: hidden;
				// margin-top: $spacing;

				> .router-view {
					overflow: auto;
					animation-duration: 0.3s;

					&.animate__animated {
						position: absolute;
						width: 100vw;
						height: 100%;
					}
				}

				:deep(.city-router-view-loading-message) {
					height: 100%;
				}
			}

			> .spacer-left,
			> .spacer-right {
				display: none;
			}
		}
	}
}

@media (min-width: $bp-medium) {
	.city-container {
		display: flex;
		justify-content: center;

		> .city {
			flex-grow: 1;
			min-width: 0; /* REF: https://stackoverflow.com/questions/36247140/why-does-flex-grow-1-shrink-the-parent-element */
			max-width: 1262px;
			margin: 0 calc($spacing * 2);

			// .hide-header {
			// 	display: block;
			// }

			> .main {
				flex-direction: row;
				> .navigation-section {
					display: flex;
					flex-direction: column;
					order: 1;
					// min-width: 100px;
					margin-right: calc($spacing * 4.1);
					margin-left: calc($spacing / 4); /* this is to account for the onezone logo image itself having a margin */

					:deep(.nav-wrapper) {
						overflow-y: auto;
					}

					> div {
						.network-status {
							position: fixed;
							height: fit-content;
							top: 0;
							left: 0;
							width: 100vw;
						}
					}

					.info-section {
						display: flex;
						flex-grow: 1;
						font-size: $text-size-primary;
					}
				}

				> .content {
					order: 2;
				}
			}
		}

		&.instagram-style {
			> .city {
				max-width: none;
				> .main {
					> .content {
						order: 3;
						max-width: 680px;
					}

					> .spacer-left {
						display: block;
						flex-grow: 1;
						order: 2;
					}
					> .spacer-right {
						display: block;
						flex-grow: 1;
						order: 4;
					}
				}
			}
		}
	}
}
</style>
