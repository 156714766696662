<template>
	<button class="venue-product-button push-button-call-to-action" @click="buttonClicked">Groups<br />& Events</button>
</template>

<script setup>
import { safeHapticsVibrate } from "../../helpers/index.js";

const props = defineProps({
	venue: {
		type: Object,
		required: true,
	},
	venueProductDialogRef: {
		type: Object,
		default: null,
	},
});

function buttonClicked() {
	safeHapticsVibrate({ duration: 20 });
	props.venueProductDialogRef?.open();
}
</script>

<style scoped lang="scss">
@import "../../assets/styles/variables_new.scss";

.venue-product-button {
	&.push-button-call-to-action {
		padding: 0 $spacing;
		font-size: $text-size-tertiary;
		width: 80px;
		height: 38px;
	}
}
</style>
