<template>
	<div class="perk-detail-container">
		<EmptyResultsMessage v-if="!perk">No perk for venue</EmptyResultsMessage>
		<div v-else class="perk-detail-wrapper">
			<div class="perk-image">
				<ImgIxWithPlaceholder :src="perk.image.url" :preview="perk.image.preview" :sizes="sizes" :widths="widths" placeholder-width="502px" :aspect-ratio="0.72" class="image" />
			</div>
			<div class="perk-detail-section">
				<div class="perk-detail">
					<span class="name">{{ perk.name }}</span>
					<div class="perk-detail-main">
						<div v-if="isShowingRedeemQRCode" class="redeem-qr-code">
							<CloseButton class="close-button" :show-light="true" @click="hideRedeemQRCode" />
							<QRCode :data="redemptionUrl" class="qr-code" />
							<div class="instructions">To redeem your perk, simply present your QR code to a member of staff</div>
						</div>
						<div class="description" v-html="perk.longDescriptionHtml"></div>
						<button v-if="canRedeem" class="redeem-button push-button-tertiary" @click="showRedeemQRCode">Redeem</button>
						<button v-else class="redeem-button push-button-tertiary" @click="upgradeOnClick">{{ noAccessButtonText }}</button>
						<div class="terms-and-conditions" v-html="perk.termsAndConditionsHtml"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { computed, inject, ref } from "vue";

import { PremiumFeature, SuggestedUserAction } from "../../constants/index.js";

import EmptyResultsMessage from "../EmptyResultsMessage.vue";
import ImgIxWithPlaceholder from "../imgix/ImgIxWithPlaceholder.vue";
import QRCode from "../QRCode.vue";
import CloseButton from "../CloseButton.vue";

const BUTTON_TEXT_BY_ACTION = {
	[SuggestedUserAction.UPGRADE_PREMIUM]: "Upgrade to Access Perk",
	[SuggestedUserAction.SIGN_IN]: "Sign In to Access Perk",
};

const props = defineProps({
	venue: {
		type: Object,
		default: null,
	},
	perk: {
		type: Object,
		default: null,
	},
});

const currentEnvironment = inject("currentEnvironment");
const userActionDialogRef = inject("userActionDialogRef");
const tracking = inject("tracking");

const isShowingRedeemQRCode = ref(false);

const perk = computed(() => props.perk);
const venue = computed(() => props.venue);
const redeem = computed(() => (perk.value ? perk.value.canRedeem[venue.value.id] : null));
const canRedeem = computed(() => redeem.value?.canRedeem ?? false);
const action = computed(() => redeem.value?.action);
const noAccessButtonText = computed(() => BUTTON_TEXT_BY_ACTION[action.value]);
const redemptionUrl = computed(() => `${currentEnvironment.value.config.api.url}${perk.value?.redemptionData?.redemptionUrl[venue.value.id]}&isPremiumFree=true`);

const widths = computed(() => new Array(8).fill(null).map((v, index) => 220 * (index + 1)));
const sizes = "(max-width: 767px) 90vw, 440px";

const showRedeemQRCode = () => {
	isShowingRedeemQRCode.value = true;
	tracking.perkRedeemButtonPressed({ perk: perk.value, venue: venue.value });
};
const hideRedeemQRCode = () => {
	isShowingRedeemQRCode.value = false;
};
const upgradeOnClick = () => {
	if (!canRedeem.value) {
		userActionDialogRef.value.open({ feature: PremiumFeature.PERK, userAction: action.value });
	}
};
</script>

<style scoped lang="scss">
@import "../../assets/styles/variables_new.scss";

.perk-detail-container {
	display: flex;
	flex-direction: column;
	// height: 100%;
	overflow: auto;
	.perk-detail-wrapper {
		display: flex;
		flex-direction: column;
		width: 100%;
		overflow: auto;
		// height: 100%;

		.perk-image {
			overflow: hidden;
			max-height: max(55dvh, 494px);
			// height: 100%;

			.image {
				// max-height: 450px;
				// min-height: 200px;
				// object-fit: cover;
				// height: 100%;
			}
		}

		.perk-detail-section {
			display: flex;
			flex-direction: column;
			gap: calc($spacing * 1.5);
			align-items: center;
			padding: calc($spacing) calc($spacing * 2) $spacing calc($spacing * 2);
			box-sizing: border-box;

			.perk-detail {
				display: flex;
				flex-direction: column;
				gap: $spacing;
				margin: auto;

				.name {
					font-size: $text-size-header;
					font-weight: $text-bold-primary;
					text-align: center;
				}

				.perk-detail-main {
					position: relative;
					display: flex;
					flex-direction: column;
					align-items: center;
					gap: calc($spacing * 1.5);
					min-height: 200px;

					.redeem-qr-code {
						position: absolute;
						top: 0;
						width: 100%;
						height: 100%;
						background-color: $background-color-primary;
						display: flex;
						flex-direction: column;
						align-items: center;
						gap: calc($spacing * 2);

						.close-button {
							position: absolute;
							top: calc($spacing/1.5);
							right: auto;
							z-index: 1;
							transform: translateX(100px);
						}

						.qr-code {
							min-height: 150px;
						}

						.instructions {
							font-size: $text-size-tertiary;
							font-weight: $text-bold-primary;
							text-align: center;
						}
					}

					.description {
						height: auto;
						font-size: $text-size-secondary;

						:deep(p) {
							text-align: center;
						}

						:deep(p:first-child) {
							margin-top: 0;
						}
						:deep(p:last-child) {
							margin-bottom: 0;
						}
					}

					.redeem-button {
						width: 100%;
						max-width: 200px;
					}

					.terms-and-conditions {
						font-size: $text-size-tertiary;
						font-weight: $text-bold-secondary;
						text-align: center;

						:deep(p) {
							margin: 0;
						}
					}
				}
			}
		}
	}
}

@media (min-width: $bp-medium) {
	.perk-detail-container {
		.perk-detail-wrapper {
			flex-direction: row;

			.perk-image {
				flex: 1;
				max-height: none;

				.image {
					// width: 100%;
					:deep(img) {
						// width: 100%;
						height: auto;
					}
				}
			}

			.perk-detail-section {
				flex: 1;
				overflow: auto;
			}
		}
	}
}
</style>
