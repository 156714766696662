import colors from "ansi-colors";
import dayjs from "dayjs";

import { EnvironmentNames } from "../../constants/index.js";
import { getErrorStack } from "../../errors/errorHelpers.js";

colors.enabled = true;

export default {
	runMode: (value) => `'${value === "production" ? colors.red.bold(value) : colors.blue.bold(value)}'`,
	routeName: (value) => `'${colors.yellow.bold(value)}'`,
	routeNames: (values) => values.map((value) => `'${colors.yellow.bold(value)}'`).join(", "),
	listName: (value) => `'${colors.yellow.bold(value)}'`,
	duration: (value) => `'${colors.yellow.bold(value)}'`,
	durationHumanized: (value) => colors.yellow.italic(dayjs.duration(value).humanize()),
	durationMS: (value) => `${colors.yellow.italic(Math.round(dayjs.duration(value).asMilliseconds()))}${colors.magenta("ms")}`,
	name: (value) => `'${colors.yellow.bold(value)}'`,
	status: (value) => `'${colors.yellow.bold(value)}'`,
	env: (value) => `'${colors.yellow.bold(value)}'`,
	title: (value) => `'${colors.yellow.bold(value)}'`,
	pattern: (value) => `'${colors.yellow.bold(value)}'`,
	fromCity: (value) => `'${colors.yellow.bold(value)}'`,
	toCity: (value) => `'${colors.yellow.bold(value)}'`,
	city: (value) => `'${colors.yellow.bold(value)}'`,
	count: (value) => `'${colors.bold.yellow(value)}'`,
	maxCount: (value) => `'${colors.bold.red(value)}'`,
	result: (value) => `'${colors.bold.yellow(value)}'`,
	attempt: (value) => `'${colors.bold.yellow(value)}'`,
	url: (value) => `'${colors.italic.yellow(value)}'`,
	relativeUrl: (value) => `'${colors.italic.yellow(value)}'`,
	loginUrl: (value) => `'${colors.italic.yellow(value)}'`,
	logoutUrl: (value) => `'${colors.italic.yellow(value)}'`,
	version: (value) => `'${colors.italic.magenta(value)}'`,
	path: (value) => `'${colors.italic.magenta(value)}'`,
	to: (value) => `'${colors.italic.magenta(JSON.stringify(value))}'`,
	from: (value) => `'${colors.italic.magenta(JSON.stringify(value))}'`,
	bounds: (value) => `'${colors.italic.magenta(JSON.stringify(value))}'`,
	properties: (value) => `'${colors.italic.magenta(JSON.stringify(value))}'`,
	position: ({ top, left, x, y }) =>
		top || left ? colors.magenta.italic(`(top:${colors.bold(top)}, left:${colors.bold(left)})`) : colors.magenta.italic(`(x:${colors.bold(x)}, y:${colors.bold(y)})`),
	position2: ({ top, left, x, y }) =>
		top || left ? colors.magenta.italic(`(top:${colors.bold(top)}, left:${colors.bold(left)})`) : colors.magenta.italic(`(x:${colors.bold(x)}, y:${colors.bold(y)})`),
	location: (value) => `'${colors.italic.magenta(JSON.stringify(value))}'`,
	zoomLevel: (value) => `'${colors.bold.yellow(value)}'`,
	operationName: (value) => `'${colors.yellow(value)}'`,
	modelMethodName: (value) => `'${colors.yellow(value)}'`,
	expected: (value) => `'${colors.magenta(value)}'`,
	actual: (value) => `'${colors.yellow(value)}'`,
	searchText: (value) => `'${colors.yellow(value)}'`,
	actionName: (value) => `'${colors.blue(value)}'`,
	args: (value) => `${colors.magenta.italic(JSON.stringify(value))}`,
	diff: (value) =>
		`${colors.magenta.italic(
			value
				.map(
					({ path, value1, value2 }) =>
						`${path}: ${colors.bold(typeof value1 !== "undefined" && value1 !== null ? JSON.stringify(value1) : colors.italic("<undefined>"))} => ${colors.bold(
							typeof value2 !== "undefined" && value2 !== null ? JSON.stringify(value2) : colors.italic("<undefined>"),
						)}`,
				)
				.join("\n"),
		)}`,
	variables: (value) => `${colors.magenta.italic(JSON.stringify(value))}`,
	data: (value) => `${colors.magenta.italic(JSON.stringify(value))}`,
	networkStatus: (value) => `${colors.magenta.italic(JSON.stringify(value))}`,
	id: (value) => `'${colors.magenta.italic(value)}'`,
	venueId: (value) => `'${colors.magenta.italic(value)}'`,
	userId: (value) => `'${colors.magenta.italic(value)}'`,
	sessionId: (value) => `'${colors.magenta.italic(value)}'`,
	effectId: (value) => `${colors.magenta.italic(value)}`,
	processingState: (value) => `'${colors.yellow(value)}'`,
	processingStateData: (value) => `${colors.magenta.italic(JSON.stringify(value))}`,
	change: (value) => `${colors.magenta.italic(JSON.stringify(value))}`,
	params: (value) => `${colors.magenta.italic(JSON.stringify(value))}`,
	fromParams: (value) => `${colors.magenta.italic(JSON.stringify(value))}`,
	toParams: (value) => `${colors.magenta.italic(JSON.stringify(value))}`,
	query: (value) => `${colors.magenta.italic(JSON.stringify(value))}`,
	toQuery: (value) => `${colors.magenta.italic(JSON.stringify(value))}`,
	fromQuery: (value) => `${colors.magenta.italic(JSON.stringify(value))}`,
	filterState: (value) => `${colors.magenta.italic(JSON.stringify(value))}`,
	json: (value) => `${colors.yellow.italic(JSON.stringify(value, null, 2))}`,
	routeConfigs: (value) => `${colors.cyan.italic(value.map(({ name, path }) => `  • ${colors.yellow.bold(name)} => ${colors.italic.magenta(path ? path : colors.bold("<virtual>"))}`).join("\n"))}`,
	error: (value) => (value?.stack ? getErrorStack(value) : value),
	errors: (values) => values?.map((value) => (value.stack ? getErrorStack(value) : value)).join("\n"),
	errorJson: (value) => JSON.stringify(value),
	permissions: (value) => JSON.stringify(value),
	user: (value) => `'${colors.italic.yellow(value.name ?? value.firstName + " " + value.lastName)}'`,
	token: (value) => `'${colors.italic.yellow(value)}'`,
	scope: (value) => `'${colors.italic.yellow(value)}'`,
	scopeId: (value) => `'${colors.italic.magenta(value)}'`,
	typeName: (value) => `${colors.bold.yellow(value)}`,
	featureName: (value) => `${colors.bold.yellow(value)}`,
	userInfo: (value) => JSON.stringify(value, null, 2),
	subscriptionId: (value) => `'${colors.italic.magenta(value)}'`,
	product: (value) => `'${colors.italic.magenta(value.name)}'`,
	product_SKU: (value) => `'${colors.italic.magenta(value)}'`,
	products: (value) => `${colors.magenta.italic(JSON.stringify(value))}`,
	event: (value) => `${colors.magenta.italic(JSON.stringify(value))}`,
	environmentName: (value) => "'" + (value === EnvironmentNames.production ? colors.yellow.bold("production") : colors.yellow.italic(value)) + "'",
	environmentNames: (value) => colors.yellow(`${value.map((name) => "'" + name + "'").join(", ")}`),
	allowedEnvironments: (value) => colors.yellow(`[${value.map(({ name }) => name).join(", ")}]`),
	possibleEnvironments: (value) => colors.yellow(`[${Object.values(value).join(", ")}]`),
	isAuthenticated: (value) => (value ? colors.green("isAuthenticated = true") : colors.blue("isAuthenticated = false")),
	hasUserProfile: (value) => (value ? colors.green("hasUserProfile = true") : colors.blue("hasUserProfile = false")),
	routeFrom: (value) => `'${colors.italic.yellow(value.name)}'`,
	routeTo: (value) => `'${colors.italic.yellow(value.name)}'`,
	pageName: (value) => `'${colors.italic.yellow(value)}'`,
	eventName: (value) => `'${colors.italic.yellow(value)}'`,
	direction: (value) => `'${colors.bold.blue(value)}'`,
	meta: (value) => colors.yellow.italic(JSON.stringify(value, null, 2)),
	blurhash: (value) => `'${colors.yellow.italic(value)}'`,
	timeRemaining: (value) => colors.yellow.italic(dayjs.duration(value).humanize()),
	bool: (value) => colors.yellow.italic(value),
};
