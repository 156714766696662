<template>
	<EmbeddedBrowser :url="props.passThroughBookingUrl" class="venue-booking-stage-pass-through-booking browser" />
</template>

<script setup>
import EmbeddedBrowser from "../EmbeddedBrowser.vue";

const props = defineProps({
	passThroughBookingUrl: {
		type: String,
		required: true,
	},
});
</script>

<style scoped lang="scss">
@import "../../assets/styles/variables_new.scss";

.venue-booking-stage-pass-through-booking {
	width: 100%;
	height: 100%;
}
</style>
