import { markRaw, onDeactivated, reactive, ref, unref } from "vue";
import { useEventListener } from "@vueuse/core";

export const useAnimatedDialog = (dialogRef, { isFakeModal = false, animationEndHandler, closeHandler, classNameClose = "close" } = {}) => {
	if (!dialogRef) {
		throw new Error("You must supply an elRef to useAnimated");
	}
	if (!closeHandler) {
		throw new Error("You must supply a closeHandler function to useAnimated");
	}

	const closingArgs = ref(null),
		isOpen = ref(false),
		cssClasses = reactive({});

	useEventListener(dialogRef, "animationend", (e) => {
		if (!e.pseudoElement && isCurrentlyClosing()) {
			invokeAnimationEndHandler();
		}
	});

	useEventListener(dialogRef, "close", () => {
		invokeCloseHandler();
	});

	onDeactivated(() => {
		if (isCurrentlyClosing()) {
			invokeCloseHandler();
		}
	});

	const output = reactive({
		open: markRaw(open),
		close: markRaw(close),
		closeInstantly: markRaw(closeInstantly),
		toggle: markRaw(toggle),
		isOpen,
		cssClasses,
	});

	return output;

	function open() {
		isOpen.value = true;
		if (unref(isFakeModal)) {
			dialogRef.value.style.display = "flex";
		} else {
			dialogRef.value.showModal();
		}
	}

	function close(...args) {
		if (isOpen.value) {
			cssClasses[classNameClose] = true;
			closingArgs.value = args;
		}
	}

	function closeInstantly() {
		if (isOpen.value) {
			if (unref(isFakeModal)) {
				dialogRef.value.style.display = "none";
				invokeCloseHandler();
			} else {
				dialogRef.value.close();
			}
		}
	}

	function toggle() {
		if (isOpen.value) {
			close();
		} else {
			open();
		}
	}

	function isCurrentlyClosing() {
		return !!cssClasses[classNameClose];
	}

	function invokeAnimationEndHandler() {
		animationEndHandler({ closeDialog: closeInstantly });
	}

	function invokeCloseHandler() {
		delete cssClasses[classNameClose];
		isOpen.value = false;
		delete cssClasses[classNameClose];
		closeHandler(...(closingArgs.value ?? []));
	}
};
