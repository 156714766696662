import { reactive, toRaw, unref, computed } from "vue";

import { ensureArray, useRouteQuery } from "../../helpers/index.js";

/* WHY: If you are attempting to use multiple instances of useQueryString together you need to ensure you are passing in the same instance of 'route'. If you don't that instances will interfere with eachother (this might be fixed now in useRouteQuery) */
export const useQueryString = (fields, { router, route, persist = true, paramMode = "replace" } = {}) => {
	const routeQueries = persist
		? Object.fromEntries(
				Object.entries(fields).map(([key, value]) => [key, useRouteQuery(key, value, { router, route, mode: paramMode, transform: (v) => (Array.isArray(value) ? ensureArray(v) : v) })]),
		  )
		: reactive(Object.fromEntries(Object.entries(fields)));

	const queryString = reactive(
		Object.fromEntries(
			Object.entries(fields).map(([key]) => {
				return [key, computed({ get: () => (persist ? routeQueries[key].value ?? undefined : routeQueries[key]), set: (value) => queryString.set(key, value) })];
			}),
		),
	);

	queryString.set = (key, value) => {
		// if (typeof routeQueries[key] === "undefined") {
		// 	throw new Error(`useQueryString: key '${key}' not found in fields: ${Object.keys(routeQueries).join(", ")}`);
		// }
		if (value === null) {
			queryString.remove(key);
		} else if (persist) {
			routeQueries[key].value = toRaw(unref(value));
		} else {
			routeQueries[key] = value;
		}
	};

	queryString.remove = (key) => {
		// if (typeof routeQueries[key] === "undefined") {
		// 	throw new Error(`useQueryString: key '${key}' not found in fields: ${Object.keys(routeQueries).join(", ")}`);
		// }
		if (persist) {
			if (fields[key]) {
				routeQueries[key].value = toRaw(unref(fields[key]));
			} else {
				routeQueries[key].value = undefined;
			}
		} else {
			delete routeQueries[key];
		}
	};

	queryString.clear = () => {
		Object.keys(fields).forEach((key) => queryString.remove(key));
	};

	return queryString;
};
