<template>
	<Dialog
		ref="dialogRef"
		:items="venueProducts"
		param-name="venueProductsOpen"
		class="browser-dialog"
		width="90vw"
		height="85dvh"
		max-height="min(85dvh, 650px)"
		max-width="min(95vw, 340px)"
		@closed="close"
		@viewed="venueProductViewed"
		@interaction="venueProductGalleryInteraction"
	>
		<template #tabs="{ item: venueProduct }">{{ venueProduct.name }}</template>
		<template #default="{ item: venueProduct, isActiveSlide }">
			<DialogSimpleLayout
				:has-close-button="isActiveSlide"
				:full-width="true"
				:show-padding="false"
				:show-light-close-button="false"
				:show-background-close-button="true"
				class="layout"
				@close="close"
			>
				<VenueProductDetail :venue="props.venue" :venue-product="venueProduct" />
			</DialogSimpleLayout>
		</template>
	</Dialog>
</template>

<script setup>
import { computed, inject, ref } from "vue";

import Dialog from "../Dialog.vue";
import DialogSimpleLayout from "../DialogSimpleLayout.vue";
import VenueProductDetail from "./VenueProductDetail.vue";

const props = defineProps({
	venue: {
		type: Object,
		required: true,
	},
});

const tracking = inject("tracking");

const trackingEventsFired = {};
const dialogRef = ref(null);

const venueProducts = computed(() => props.venue?.products);

defineExpose({
	open,
	close,
});

async function open() {
	const dialog = dialogRef.value;

	if (dialog && !dialog.isOpen) {
		return dialog.open();
	}
}

async function close() {
	const dialog = dialogRef.value;
	dialog.close();
}

async function venueProductViewed({ index }) {
	if (!trackingEventsFired[index]) {
		trackingEventsFired[index] = true;
		await tracking.venueProductViewed({
			venue: props.venue,
			venueProduct: venueProducts.value[index],
			venueProductCount: venueProducts.value.length,
			venueProductIndex: index,
		});
	}
}

async function venueProductGalleryInteraction({ highestIndexViewed }) {
	await tracking.venueProductGalleryInteraction({
		venue: props.venue,
		venueProduct: venueProducts.value[highestIndexViewed],
		venueProductCount: venueProducts.value.length,
		highestVenueProductIndex: highestIndexViewed,
	});
}
</script>

<style scoped lang="scss">
@import "../../assets/styles/variables_new.scss";

.browser-dialog {
	:deep(.layout) {
		height: 100%;
	}
}
</style>
