<template>
	<div :class="{ 'booking-provider-container': true, 'is-aligned-center': props.isAlignedCenter }">
		<div :class="{ 'booking-provider': true, 'show-border': props.showBorder }">
			<div :class="{ 'powered-by': true, [props.bookingProvider]: true }">Powered by</div>
			<img :src="bookingProviderLogo" :class="{ 'booking-provider-logo': true, [props.bookingProvider]: true }" />
		</div>
	</div>
</template>

<script setup>
import { computed } from "vue";

import { BookingProvider } from "../constants/index.js";

import OpenTableLogo from "../assets/open-table-logo.svg";
import SevenRoomsLogo from "../assets/seven-rooms-logo.svg";

const props = defineProps({
	bookingProvider: {
		type: String,
		required: true,
	},
	showBorder: {
		type: Boolean,
		default: false,
	},
	isAlignedCenter: {
		type: Boolean,
		default: true,
	},
});

const bookingProviderLogo = computed(() => {
	switch (props.bookingProvider) {
		case BookingProvider.OPEN_TABLE:
			return OpenTableLogo;
		case BookingProvider.SEVEN_ROOMS:
			return SevenRoomsLogo;
		default:
			return null;
	}
});
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

.booking-provider-container {
	display: flex;

	&.is-aligned-center {
		justify-content: center;
	}

	.booking-provider {
		display: flex;
		align-items: center;
		gap: calc($spacing / 2);
		font-size: $text-size-quinary;
		color: $text-color-secondary;

		.powered-by {
			white-space: nowrap;

			&.SEVEN_ROOMS {
				line-height: 8px;
			}
		}

		&.show-border {
			border: $border-size-primary solid $border-color-primary;
			border-radius: $border-radius-tertiary;
			padding: calc($spacing / 4) calc($spacing);
		}

		.booking-provider-logo {
			height: 18px;

			&.SEVEN_ROOMS {
				height: 10px;
			}
		}
	}
}
</style>
