<template>
	<div ref="premiumContainerRef" class="premium-container">
		<LoadingMessageWithError v-if="isMounted && (combinedQuery.isLoading || combinedQuery.error)" :error="combinedQuery.error" :retry="combinedQuery.retry" :full-screen="true">
			Loading onezone perks, please wait...
		</LoadingMessageWithError>
		<div v-else-if="queryString.subscribeToProduct">
			<LoadingMessage>Subscribing to Premium, please wait...</LoadingMessage>
		</div>
		<div v-else class="premium-wrapper">
			<!-- <section class="premium-membership">
				<PremiumMembershipStatus :user-premium="userPremium" :user="user" :premium-products="premiumProducts" />
			</section> -->
			<section class="premium-section">
				<h2 v-if="isPremium">Perks</h2>
				<h2 v-else>Perks</h2>
				<div class="summary">Free cocktails, dishes and desserts at London's best restaurants</div>
				<NewList
					v-slot="{ item: perk, isItemVisible }"
					name="premium-perks-list"
					class="premium-perks-list"
					:items="perks"
					:direction="ListDirections.HORIZONTAL"
					:item-height="220"
					:item-width="170"
					:gap-size="7"
					:item-buffer-count="4"
					:show-navigation-buttons="true"
					:pad-first-and-last-item="true"
				>
					<PerkCard v-if="isItemVisible" :perk="perk" />
				</NewList>
			</section>
			<section class="premium-section">
				<h2 v-if="isPremium">Previews</h2>
				<h2 v-else>Previews</h2>
				<div class="summary">Book into exclusive 50% off preview nights at London's hottest new restaurants, with early access to secure spaces</div>
				<NewList
					v-slot="{ item: launch, isItemVisible }"
					name="premium-launches-list"
					class="premium-launches-list"
					:items="launches"
					:direction="ListDirections.HORIZONTAL"
					:item-height="220"
					:item-width="354"
					:gap-size="7"
					:item-buffer-count="4"
					:show-navigation-buttons="true"
					:pad-first-and-last-item="true"
				>
					<LaunchCard v-if="isItemVisible" :launch="launch" :show-wide="true" />
				</NewList>
			</section>
			<!-- <section class="premium-section">
				<h2 v-if="isPremium">Your Cities</h2>
				<h2 v-else>Cities</h2>
				<div class="summary">Discover the best places to eat, drink and stay across Europe with new onezone cities</div>
				<SuperList
					v-slot="{ item: city, style }"
					:items="cities"
					:item-size="184"
					:is-virtual="true"
					direction="horizontal"
					name="premium-cities-list"
					class="premium-cities-list"
					:first-item-padding="isSmallerThanMedium ? 14 : 0"
					:last-item-padding="0"
					:show-navigation-buttons="true"
				>
					<CityCard :city="city" :style="style" />
				</SuperList>
			</section> -->
		</div>
	</div>
</template>

<script setup>
import { computed, inject, onActivated, onMounted, ref, toRef, watch } from "vue";
import { useRouter, useRoute } from "vue-router";

import { combineQueries, useRouteParams, getUserPremiumContext, urlBuilder } from "../helpers/index.js";
import { useScrollMemory } from "../functions/scroll-memory/index.js";
// import { breakpoints } from "../domain/index.js";
import { useQueryString } from "../functions/query-string/index.js";
import { PageNames, ListDirections } from "../constants/index.js";
import loggingMessages from "./Premium.logging-messages.js";

// import PremiumMembershipStatus from "./PremiumMembershipStatus.vue";
// import SuperList from "./SuperList.vue";
import PerkCard from "./PerkCard.vue";
import LaunchCard from "./LaunchCard.vue";
import NewList from "./NewList.vue";
// import CityCard from "./CityCard.vue";

const isAppHydrated = inject("isAppHydrated");
const model = inject("model");
const currentEnvironment = inject("currentEnvironment");
const logger = inject("logger").nested({ name: "Premium" });

const isMounted = ref(false);
const routeParams = useRouteParams();
const queryString = useQueryString({ subscribeToProduct: "", feature: "", returnTo: "" });
const router = useRouter();
const route = useRoute();
const premiumContainerRef = ref(null);
const scrollMemory = useScrollMemory({ name: "premium", containerRef: premiumContainerRef });
// const isSmallerThanMedium = breakpoints.smaller("medium");

const subscriptionManager = computed(() => currentEnvironment.value.subscriptionManager);
const isSignedIn = computed(() => currentEnvironment.value.isSignedIn.value);

const combinedQuery = combineQueries({
	premium: model.queries.GetPremium({
		cityName: computed(() => routeParams.cityName),
		isSignedIn,
	}),
	premiumProducts: model.queries.GetPremiumProducts({
		cityName: computed(() => routeParams.cityName),
	}),
	cities: model.queries.GetCities(),
	currentUser: model.queries.GetCurrentUser(),
});

const user = computed(() => combinedQuery.model?.currentUser);
const userPremium = computed(() => getUserPremiumContext(user.value));
const isPremium = computed(() => userPremium.value?.isPremium ?? false);
const perks = computed(() => combinedQuery.model?.perks ?? []);
const launches = computed(() => (combinedQuery.model?.launches ?? []).map((launch) => ({ ...launch, id: launch.venue.id })));
// const cities = computed(() => combinedQuery.model?.cities ?? []);
const premiumProducts = computed(() => combinedQuery.model?.products ?? []);

watch([isMounted, toRef(queryString, "subscribeToProduct"), premiumProducts], () => {
	if (isMounted.value && queryString.subscribeToProduct && premiumProducts.value.length > 0) {
		const product = premiumProducts.value.find(({ id }) => id === queryString.subscribeToProduct);
		if (!product) {
			throw new Error("Premium Product not found: " + queryString.subscribeToProduct);
		}
		const returnTo = queryString.returnTo ? decodeURIComponent(queryString.returnTo) : null;
		deepLinkSubscribeToProduct({ product, returnTo });
	}
});

onMounted(() => {
	isMounted.value = true;
});

onActivated(() => {
	if (route.query.scrollTop) {
		scrollMemory.scrollToTop();
	}
});

if (!isAppHydrated.value) {
	// await combinedQuery.promise;
}

function deepLinkSubscribeToProduct({ product, returnTo }) {
	if (!returnTo) {
		returnTo = router.resolve(urlBuilder(PageNames.PERKS)).href;
	}

	if (isSignedIn.value) {
		if (isPremium.value) {
			logger.log(loggingMessages.deepLinkSubscribeAlreadyPremiumRedirecting, { product });
			router.replace(returnTo);
		} else {
			logger.log(loggingMessages.deepLinkSubscribeToPremiumProduct, { product });
			subscriptionManager.value.subscribeToPremium({ premiumProduct: product, successUrl: returnTo, cancelUrl: returnTo });
		}
	} else {
		logger.log(loggingMessages.deepLinkSubscribeToPremiumProductNotSignedInCreatingAccount, { product });
		currentEnvironment.value.createAccount({ returnTo: route.fullPath });
	}
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables_new.scss";

.premium-container {
	display: flex;
	flex-direction: column;
	overflow: auto;
	@include centre-loading-message;

	.premium-wrapper {
		display: flex;
		flex-direction: column;
		gap: calc($spacing * 2);
		margin-bottom: $spacing;

		.premium-membership {
			display: flex;
			flex-direction: column;
			gap: calc($spacing);
			// padding-bottom: calc($spacing * 3);
			// border-bottom: $border-size-primary solid $border-color-quinary;
		}

		.premium-section {
			display: flex;
			flex-direction: column;
			gap: calc($spacing/1.5);

			.premium-perks-list,
			.premium-launches-list,
			.premium-cities-list {
				height: 224px;
			}

			h2,
			.summary {
				margin-left: calc($spacing);
			}

			.summary {
				font-size: $text-size-tertiary;
				font-weight: $text-bold-secondary;
				color: $text-color-secondary;
			}
		}
	}
}

@media (min-width: $bp-medium) {
	.premium-container {
		.premium-wrapper {
			.premium-membership {
				margin-left: 0;
			}

			.premium-section {
				h2,
				.summary {
					margin-left: 0;
				}
			}
		}
	}
}
</style>
