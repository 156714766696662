import { computed } from "vue";

export default class {
	constructor({ logger, modelBuilder, api, authentication }) {
		this.logger = logger.nested({ name: "Model" });
		this.modelBuilder = modelBuilder;
		this.api = api;
		this.authentication = authentication;
	}

	/* isSignedIn is passed in so any changes to the user's sign-in status will trigger a re-fetch, anonymously */
	execute() {
		return this.modelBuilder.build({
			name: "GetVenueProductTypes",
			execute: async ({ args }) => {
				let venueProductTypes;
				const data = await this.api.queries.getVenueProductTypes(
					{},
					{
						useClientCache: true,
						clientCacheMaxAge: { minutes: 10 },
						accessToken: args.isSignedIn ? this.authentication.getAccessToken : undefined,
					},
				);
				venueProductTypes = computed(() => data.venueProductTypes.map((venueProductType) => ({ ...venueProductType })));
				return { venueProductTypes };
			},
		});
	}
}
