<template>
	<div :class="{ 'venue-booking-stage-select-time-slot': true, 'is-aligned-center': props.isAlignedCenter }">
		<VenueHeader v-if="props.showVenueHeader" :venue="venue" />
		<div class="booking-controls">
			<DateTimePicker :date-time="startTime" @change="dateOrTimePickerChanged" />
			<div class="party-size-control">
				<font-awesome-icon icon="fa-regular fa-user" class="icon" />
				<select v-model="params.partySize">
					<option v-for="size in 20" :key="size" :value="size">{{ size }} {{ size === 1 ? "person" : "people" }}</option>
				</select>
			</div>
		</div>
		<loading-message-with-error v-if="query.isLoading" :show-padding="false" class="loading-message"> Checking availability, please wait... </loading-message-with-error>
		<div v-else-if="query.error" :class="{ 'error-message': true, 'is-aligned-center': props.isAlignedCenter }">
			Sorry, there was a problem getting availability.<br />
			<button class="push-button-primary" @click="query.retry">Retry</button>
		</div>
		<div v-else-if="!isAvailabilityEnabled">
			<button class="push-button-call-to-action book-a-table-button" @click="checkAvailabilityClicked">
				<img :src="onezoneLogo" class="onezone-logo" />
				Check Availability
			</button>
		</div>
		<loading-message v-else-if="props.isGettingLock" :show-padding="false">Confirming availability, please wait...</loading-message>
		<div
			v-for="(reason, index) in noAvailabilityReasons"
			v-else-if="noAvailabilityReasons?.length > 0"
			:key="index"
			:class="{ 'no-availability-reason': true, 'is-aligned-center': props.isAlignedCenter }"
		>
			{{ NO_AVAILABILITY_REASON_DESCRIPTIONS[reason] }}
		</div>
		<template v-else>
			<div v-if="props.error" :class="{ 'error-message': true, 'is-aligned-center': props.isAlignedCenter }">{{ props.error }}</div>
			<div v-if="timesAvailableToShow?.length > 0" class="booking-times">
				<button
					v-for="({ time, seatingPosition, isCreditCardRequired, bookingProviderAssociation }, index) in timesAvailableToShow"
					:key="index"
					:class="{ 'booking-time': true, 'push-button-call-to-action': true, 'is-closest-to-requested-time': dayjs(time).valueOf() === dayjs(closestTime).valueOf() }"
					@click="timeSlotSelected({ venueId, timeSlot: time, seatingPosition, isCreditCardRequired, bookingProviderAssociation })"
				>
					<div>{{ formatDateForTimeDisplay(time) }}</div>
					<div v-if="shouldShowSeatingPosition">{{ SEATING_POSITION_LABELS[seatingPosition] }}</div>
				</button>
			</div>
			<div v-else :class="{ 'error-message': true, 'is-aligned-center': props.isAlignedCenter }">
				{{ NO_AVAILABILITY_REASON_DESCRIPTIONS[VenueBookingNoAvailabilityReaons.NO_TIMES_EXIST] }}
			</div>
		</template>
	</div>
</template>

<script setup>
import { computed, inject, ref, watch, onDeactivated } from "vue";
import { useRoute } from "vue-router";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

import { TimeFormats, DateFormats, VenueBookingNoAvailabilityReaons, VenueBookingSeatingPosition, ButtonStyles, ButtonActions } from "../../constants/index.js";
import { formatDateForTimeDisplay, parseDateTimeInVenueTimeZone, safeHapticsVibrate } from "../../helpers/index.js";

import onezoneLogo from "../../assets/icons/logo-black-background.svg";

import VenueHeader from "./VenueHeader.vue";
import DateTimePicker from "../DateTimePicker.vue";

const VENUE_TIME_ZONE = "Europe/London";
const AVAILABILITY_WINDOW_COUNT = 3;

const SEATING_POSITION_LABELS = {
	[VenueBookingSeatingPosition.STANDARD]: "Standard",
	[VenueBookingSeatingPosition.OUTDOOR]: "Outdoor",
	[VenueBookingSeatingPosition.BAR]: "Bar",
	[VenueBookingSeatingPosition.HIGHTOP]: "High Top",
	[VenueBookingSeatingPosition.COUNTER]: "Counter",
};

const NO_AVAILABILITY_REASON_DESCRIPTIONS = {
	[VenueBookingNoAvailabilityReaons.NO_TIMES_EXIST]: "There is no availability within three hours of your date and time. Please select a new time.",
	[VenueBookingNoAvailabilityReaons.BELOW_MIN_PARTY_SIZE]: "The party size is too small.",
	[VenueBookingNoAvailabilityReaons.ABOVE_MAX_PARTY_SIZE]: "The party size is too big.",
	[VenueBookingNoAvailabilityReaons.SAME_DAY_CUTOFF]: "The booking time is too soon, same day booking is not allowed. Please select a new time.",
	[VenueBookingNoAvailabilityReaons.EARLY_CUTOFF]: "The booking time is too soon. Please select a new time.",
	[VenueBookingNoAvailabilityReaons.TOO_FAR_IN_ADVANCE]: "The booking time is too far in advance. Please select a new time.",
	[VenueBookingNoAvailabilityReaons.NOT_FAR_ENOUGH_IN_ADVANCE]: "The booking date and time are not scheduled far enough in advance. Please select a new date and time.",
};

const props = defineProps({
	venueBooking: {
		type: Object,
		required: true,
	},
	error: {
		type: String,
		required: false,
		default: null,
	},
	isGettingLock: {
		type: Boolean,
		default: false,
	},
	showVenueHeader: {
		type: Boolean,
		default: true,
	},
	isAutomaticAvailabilityCheck: {
		type: Boolean,
		default: true,
	},
	isAlignedCenter: {
		type: Boolean,
		default: true,
	},
	placement: {
		type: String,
		required: true,
	},
});

const emit = defineEmits(["timeSlotSelected"]);

const model = inject("model");
const currentEnvironment = inject("currentEnvironment");
const tracking = inject("tracking");

const route = useRoute();

const datePickerValue = ref(),
	timePickerValue = ref(),
	isAvailabilityManuallyRequested = ref(false);

const params = computed(() => props.venueBooking.params);
const isSignedIn = computed(() => currentEnvironment.value?.isSignedIn.value);
const venue = computed(() => props.venueBooking.venue.value);
const venueId = computed(() => props.venueBooking.venueId.value);
const startTime = computed(() => params.value.startTime);
const adjustedStartTime = computed(() => params.value.adjustedStartTime);
const partySize = computed(() => params.value.partySize);
const isAvailabilityEnabled = computed(() => props.isAutomaticAvailabilityCheck || isAvailabilityManuallyRequested.value);

watch(
	startTime,
	() => {
		datePickerValue.value = dayjs.utc(startTime.value).format(DateFormats.ISO_DATE);
		timePickerValue.value = dayjs.utc(startTime.value).format(TimeFormats.IGNORE_SECONDS);
	},
	{ immediate: true },
);

onDeactivated(() => {
	isAvailabilityManuallyRequested.value = false;
});

const query = model.queries.GetVenueBookingAvailability(
	{
		isSignedIn,
		venueId,
		startTime: adjustedStartTime,
		partySize,
		isEnabled: isAvailabilityEnabled,
	},
	{
		isWatchEnabled: computed(() => true),
		onExecuted: async ({ timesAvailable }) => {
			await tracking.bookingAvailabilitySearched({
				venue: venue.value,
				searchDateTime: adjustedStartTime.value,
				partySize: partySize.value,
				timeSlotsCount: timesAvailable.value?.length,
				button: {
					pageName: route.name,
					placement: props.placement,
					action: ButtonActions.BOOKING_CHECK_AVAILABILITY,
					text: "Check Availability",
					style: ButtonStyles.CALL_TO_ACTION,
				},
			});
		},
	} /* force watch to be enabled, regardless of route */,
);

const availabilityTimes = computed(() => query.model.venue?.book.availabilityTimes);
const timesAvailable = computed(() => availabilityTimes.value?.timesAvailable);
const filteredTimesAvailable = computed(() => timesAvailable.value.filter((time) => parseDateTimeInVenueTimeZone(time.time, { timeZone: VENUE_TIME_ZONE }).isAfter(dayjs.utc())));
const closestTimeIndex = computed(() =>
	filteredTimesAvailable.value.reduce((closestIndex, time, index) => {
		const requestedTime = dayjs.utc(adjustedStartTime.value);
		const timeDiff = Math.abs(dayjs.utc(time.time).diff(requestedTime));
		const closestTimeDiff = Math.abs(dayjs.utc(filteredTimesAvailable.value[closestIndex].time).diff(requestedTime));
		return timeDiff < closestTimeDiff ? index : closestIndex;
	}, 0),
);
const closestTime = computed(() => filteredTimesAvailable.value[closestTimeIndex.value]?.time);
const timesAvailableToShow = computed(() => {
	if (timesAvailable.value) {
		const startIndex = Math.max(0, closestTimeIndex.value - AVAILABILITY_WINDOW_COUNT);
		const endIndex = Math.min(filteredTimesAvailable.value.length, closestTimeIndex.value + AVAILABILITY_WINDOW_COUNT + 1);
		return filteredTimesAvailable.value.slice(startIndex, endIndex);
	} else {
		return null;
	}
});
const noAvailabilityReasons = computed(() => availabilityTimes.value?.noAvailabilityReasons);
const shouldShowSeatingPosition = computed(() => timesAvailable.value?.some((time) => time.seatingPosition !== VenueBookingSeatingPosition.STANDARD));

async function timeSlotSelected(args) {
	emit("timeSlotSelected", args);

	const { timeSlot, seatingPosition, isCreditCardRequired, bookingProviderAssociation } = args;

	await tracking.bookingTimeSlotSelected({
		venue: venue.value,
		bookingProviderAssociation,
		searchDateTime: adjustedStartTime.value,
		partySize: partySize.value,
		timeSlot,
		seatingPosition,
		isCreditCardRequired,
		button: {
			pageName: route.name,
			placement: props.placement,
			action: ButtonActions.BOOKING_SELECT_TIME_SLOT,
			text: "<Time Formatted AM/PM>",
			style: ButtonStyles.CALL_TO_ACTION,
		},
	});
}

function dateOrTimePickerChanged(newDateTime) {
	params.value.startTime = newDateTime;
}

function checkAvailabilityClicked() {
	safeHapticsVibrate({ duration: 20 });
	isAvailabilityManuallyRequested.value = true;
}
</script>

<style scoped lang="scss">
@import "../../assets/styles/variables_new.scss";

.venue-booking-stage-select-time-slot {
	display: flex;
	flex-direction: column;
	gap: calc($spacing * 1.5);
	flex-grow: 1;

	&.is-aligned-center {
		align-items: center;

		.booking-controls {
			justify-content: center;
		}

		.booking-times {
			justify-content: center;
		}
	}

	.booking-controls {
		display: flex;
		flex-wrap: wrap;
		gap: calc($spacing / 2);

		select,
		input {
			font-size: $text-size-tertiary;

			&:focus {
				outline: none;
			}
		}

		.party-size-control {
			display: flex;
			align-items: center;
			padding: calc($spacing / 1.5) calc($spacing / 1.5);
			border: $border-size-primary solid $border-color-primary;
			border-radius: $border-radius-tertiary;
		}

		.party-size-control {
			> select {
				margin-left: calc($spacing / 2);
				border: none;
				appearance: none;
			}
		}
	}

	.book-a-table-button {
		display: flex;
		align-items: center;
		gap: calc($spacing / 2);
		padding: calc($spacing / 1.7) calc($spacing * 2);

		> img {
			height: 18px;
		}
	}

	.no-availability-reason,
	.booking-times {
		display: flex;
		gap: calc($spacing / 2);
		align-items: center;
		// min-height: 86px;
	}

	.no-availability-reason {
		font-size: $text-size-secondary;
		// color: $text-color-error;

		&.is-aligned-center {
			text-align: center;
			align-items: center;
		}
	}

	.booking-times {
		flex-wrap: wrap;

		.booking-time {
			overflow: visible;
			font-size: $text-size-tertiary;
			min-height: 44px;
			width: 70px;
			font-weight: normal;
			padding: 0;

			&.is-closest-to-requested-time {
				font-weight: $text-bold-primary;
				border-width: $border-size-secondary;
			}
		}
	}

	.loading-message {
		margin: calc($spacing * 2) 0;
		// min-height: 86px;
	}

	.error-message {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: calc($spacing);
		font-size: $text-size-secondary;
		// color: $text-color-error;

		&.is-aligned-center {
			text-align: center;
			align-items: center;
		}
		// min-height: 86px;
	}
}
</style>
