<template>
	<div v-if="useFakeModal" ref="dialogRef" :class="{ 'fake-modal-wrapper': true, ...dialogClasses }" @mousedown="dialogClick">
		<template v-if="props.items">
			<div class="swiper-container">
				<!-- <NavigationArrow direction="left" :class="{ 'tab': true, 'navigation-button-prev': true, [navigationUniqueId]: true }" :show-light="false" />
				<NavigationArrow direction="right" :class="{ 'tab': true, 'navigation-button-next': true, [navigationUniqueId]: true }" :show-light="false" /> -->
				<swiper
					:modules="SWIPER_MODULES"
					:navigation="{ enabled: true, prevEl: `.tab.navigation-button-prev.${navigationUniqueId}`, nextEl: `.tab.navigation-button-next.${navigationUniqueId}` }"
					class="multi-item-tabs"
					:edge-swipe-detection="true"
					:slides-per-view="'auto'"
					:space-between="7"
					:centered-slides="true"
					:slide-to-clicked-slide="true"
					@slide-change="slideChange"
					@swiper="initMultiItemTabsSwiper"
				>
					<swiper-slide v-for="(item, index) in props.items" :key="item?.[props.itemKey]" class="tab">
						<div :class="{ 'tab-primary': true, 'highlighted': activeTabIndex === index, 'tab-button': true }">
							<slot name="tabs" v-bind="{ item }" />
						</div>
					</swiper-slide>
				</swiper>
			</div>
			<div class="swiper-container">
				<NavigationArrow direction="left" :class="{ 'container': true, 'navigation-button-prev': true, [navigationUniqueId]: true }" :show-light="false" />
				<NavigationArrow direction="right" :class="{ 'container': true, 'navigation-button-next': true, [navigationUniqueId]: true }" :show-light="false" />
				<swiper
					:modules="SWIPER_MODULES"
					:navigation="{ enabled: true, prevEl: `.container.navigation-button-prev.${navigationUniqueId}`, nextEl: `.container.navigation-button-next.${navigationUniqueId}` }"
					class="multi-item-container"
					:edge-swipe-detection="true"
					:slides-per-view="'auto'"
					:space-between="14"
					:centered-slides="true"
					@slide-change="slideChange"
					@swiper="initMultiItemContainerSwiper"
				>
					<swiper-slide
						v-for="(item, index) in props.items"
						:key="item?.[props.itemKey]"
						:class="{ 'fake-modal': true, 'dialog-new': true, 'full-screen': props.fullScreen }"
						:style="{ maxWidth: props.maxWidth, maxHeight: props.maxHeight, width: props.width, height: props.height }"
					>
						<slot v-bind="{ item, isOpen: dialog.isOpen, closeDialog: close, isActiveSlide: index === multiItemContainerSwiper?.activeIndex }" />
					</swiper-slide>
				</swiper>
			</div>
		</template>
		<div
			v-else
			:class="{ 'fake-modal': true, 'dialog-new': true, 'full-screen': props.fullScreen }"
			:style="{ maxWidth: props.maxWidth, maxHeight: props.maxHeight, width: props.width, height: props.height }"
			:open="dialog.isOpen"
			@mousedown.stop
		>
			<slot v-bind="{ isOpen: dialog.isOpen, closeDialog: close }" />
		</div>
	</div>
	<dialog
		v-else
		ref="dialogRef"
		:class="{ 'dialog-new': true, 'full-screen': props.fullScreen, ...dialogClasses }"
		:style="{ maxWidth: props.maxWidth, maxHeight: props.maxHeight, width: props.width, height: props.height }"
		@mousedown="dialogClick"
		@keydown.enter.stop.prevent="handleEnter"
		@keydown.escape.stop.prevent="handleEscape"
	>
		<slot v-bind="{ isOpen: dialog.isOpen, closeDialog: close }" />
	</dialog>
</template>

<script setup>
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/zoom";
import { computed, markRaw, nextTick, provide, reactive, ref, toRef, watch } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Keyboard } from "swiper/modules";
import cuid from "cuid";
import pDebounce from "p-debounce";

import { didMouseIntersect, ExternallyResolvablePromise, useAnimatedDialog } from "../helpers/index.js";
import { useQueryString } from "../functions/query-string/index.js";

import NavigationArrow from "./NavigationArrow.vue";

const SWIPER_MODULES = [Navigation, Keyboard];

const props = defineProps({
	enterName: {
		type: String,
		default: "enter",
	},
	escapeName: {
		type: String,
		default: "escape",
	},
	paramName: {
		type: String,
		default: null,
	},
	paramMode: {
		type: String,
		default: undefined,
		validator: (value) => [undefined, "replace", "push"].includes(value),
	},
	padding: {
		type: Boolean,
		default: true,
	},
	fullScreen: {
		type: Boolean,
		default: false,
	},
	enterDirection: {
		type: String,
		default: "left",
		validator: (value) => ["left", "right"].includes(value),
	},
	isFakeModal: {
		type: Boolean,
		default: false,
	},
	items: {
		type: Array,
		default: null,
	},
	itemKey: {
		type: String,
		default: "id",
	},
	width: {
		type: String,
		default: undefined,
	},
	height: {
		type: String,
		default: undefined,
	},
	maxWidth: {
		type: String,
		default: undefined,
	},
	maxHeight: {
		type: String,
		default: undefined,
	},
});

const emit = defineEmits(["closed", "viewed", "interaction"]);

const queryString = useQueryString({ [props.paramName]: undefined, activeTabKey: undefined }, { paramMode: props.paramMode });

const debouncedMultiItemDialogInteraction = pDebounce(multiItemDialogInteraction, 20000);

const dialogRef = ref(null);
const modalPromise = ref(null);
const multiItemContainerSwiper = ref(null);
const multiItemTabsSwiper = ref(null);
let highestIndexViewed = null;

const navigationUniqueId = ref(cuid());

const useFakeModal = computed(() => props.isFakeModal || props.items);

const dialog = useAnimatedDialog(dialogRef, {
	isFakeModal: useFakeModal,
	animationEndHandler({ closeDialog }) {
		closeDialog();
	},
	closeHandler(...args) {
		if (props.paramName) {
			queryString.remove(props.paramName);
			queryString.remove("activeTabKey");
		}
		try {
			modalPromise.value?.resolve(...args);
		} catch (error) {
			modalPromise.value?.reject(error);
		} finally {
			emit("closed", ...args);
			modalPromise.value = null;
		}
	},
});

const showDialog = computed(() => (props.paramName ? queryString[props.paramName] ?? "" !== "" : true));
const dialogClasses = computed(() => ({ [props.enterDirection]: true, ...dialog.cssClasses }));
const activeTabKey = computed(() => (props.items ? queryString.activeTabKey : null));
const activeTabIndex = computed(() =>
	Math.max(
		props.items?.findIndex((item) => item[props.itemKey] === activeTabKey.value),
		0,
	),
);

watch(
	[queryString, dialogRef],
	() => {
		if (dialogRef.value && props.paramName) {
			if (showDialog.value && !dialog.isOpen) {
				/* show modal on nexttick to ensure the dialog has been mounted to document and avoid "Failed to execute 'showModal' on 'HTMLDialogElement': The element is not in a document." */
				nextTick(() => {
					/* make sure we check again if dialog ref is still available */
					if (dialogRef.value) {
						openInstantly();
					}
				});
			} else if (!showDialog.value && dialog.isOpen) {
				closeInstantly();
			}
		}
	},
	{ immediate: true },
);

watch(
	[activeTabIndex, multiItemTabsSwiper, multiItemContainerSwiper],
	() => {
		if (multiItemTabsSwiper.value && multiItemTabsSwiper.value.activeIndex !== activeTabIndex.value) {
			multiItemTabsSwiper.value.slideTo(activeTabIndex.value);
		}
		if (multiItemContainerSwiper.value && multiItemContainerSwiper.value.activeIndex !== activeTabIndex.value) {
			multiItemContainerSwiper.value.slideTo(activeTabIndex.value);
		}
	},
	{ immediate: true },
);

const externalInterface = reactive({
	open: markRaw(open),
	close: markRaw(close),
	toggle: markRaw(toggle),
	isOpen: toRef(dialog, "isOpen"),
});

provide("modal", externalInterface);
defineExpose(externalInterface);

async function open(value) {
	if (props.paramName) {
		queryString.set(props.paramName, value ?? "true");
	} else {
		if (modalPromise.value === null) {
			modalPromise.value = new ExternallyResolvablePromise();
			openInstantly();
		}
		const args = await modalPromise.value;
		return args;
	}
}

async function openInstantly() {
	dialog.open();
	emit("viewed", { key: props.items?.[activeTabIndex.value][props.itemKey], index: activeTabIndex.value });
}

async function close(...args) {
	dialog.close(...args);
	await modalPromise.value;
}

async function closeInstantly() {
	dialog.closeInstantly();
	await modalPromise.value;
}

function toggle() {
	if (dialog.isOpen) {
		return close();
	} else {
		return open();
	}
}

function handleEnter() {
	close({ name: props.enterName });
}

function handleEscape() {
	close({ name: props.escapeName });
}

function dialogClick(e) {
	const target = e.target ?? e.currentTarget;
	const dialogEl = dialogRef.value;
	if (target === dialogEl) {
		let shouldClose = false;
		if (useFakeModal.value) {
			shouldClose = true;
		} else {
			const backdrop = getComputedStyle(dialogEl, "::backdrop");
			if (backdrop) {
				const wasBackdropClicked = didMouseIntersect(
					{ x: e.clientX, y: e.clientY },
					{
						top: parseFloat(backdrop.top.replace("px", "")),
						left: parseFloat(backdrop.right.replace("px", "")),
						width: parseFloat(backdrop.width.replace("px", "")),
						height: parseFloat(backdrop.height.replace("px", "")),
					},
				);
				const wasDialogClicked = didMouseIntersect({ x: e.clientX, y: e.clientY }, dialogEl.getBoundingClientRect());
				if (wasBackdropClicked && !wasDialogClicked) {
					shouldClose = true;
				}
			}
		}
		if (shouldClose) {
			e.stopPropagation();
			close({ name: props.escapeName });
		}
	}
}

function initMultiItemTabsSwiper(swiper) {
	multiItemTabsSwiper.value = swiper;
}

function initMultiItemContainerSwiper(swiper) {
	multiItemContainerSwiper.value = swiper;
}

function slideChange(swiper) {
	const { activeIndex } = swiper;
	const item = props.items?.[activeIndex];
	if (item && dialog.isOpen) {
		const itemKeyValue = item[props.itemKey];
		if (activeIndex > 0) {
			queryString.set("activeTabKey", itemKeyValue);
		} else {
			queryString.remove("activeTabKey");
		}

		const isMultiItemContainer = swiper === multiItemContainerSwiper.value;
		if (isMultiItemContainer) {
			emit("viewed", { key: itemKeyValue, index: activeIndex });
			if (activeIndex > 0) {
				if (activeIndex > highestIndexViewed) {
					highestIndexViewed = Math.max(highestIndexViewed, activeIndex);
					debouncedMultiItemDialogInteraction();
				}
			}
		}
	}
}

function multiItemDialogInteraction() {
	emit("interaction", { highestIndexViewed: highestIndexViewed });
}
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

.fake-modal-wrapper {
	animation: fadeIn 0.3s ease normal;
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100dvh;
	max-width: 100vw;
	max-height: 100dvh;
	background-color: $background-color-modal;
	z-index: 1000;
	display: none;
	flex-direction: column;
	gap: calc($spacing/4);
	justify-content: center;
	align-items: center;

	&.close {
		animation: fadeOut 0.3s ease normal;

		.fake-modal {
			animation: slideOutLeft 0.3s ease normal;
		}
	}

	.swiper-container {
		position: relative;
		width: 100%;

		.navigation-button-prev,
		.navigation-button-next {
			display: none;
			position: absolute;
			transform: translateY(calc(-50% - ($spacing))); /* WHY: allow for the extra padding added to bottom of container */
			top: 50%;
			width: 50px;
			height: 50px;
			z-index: 1001;

			&.swiper-button-disabled {
				opacity: 0;
			}
		}

		.navigation-button-prev {
			left: 30px;
		}

		.navigation-button-next {
			right: 30px;
		}

		.navigation-button-prev,
		.navigation-button-next {
			opacity: 0;
		}

		&:hover {
			.navigation-button-prev,
			.navigation-button-next {
				&:not(.swiper-button-disabled) {
					opacity: 0.5;
				}
			}
		}
	}

	.multi-item-tabs,
	.multi-item-container {
		box-sizing: border-box;
		width: 100%;
		padding-bottom: calc($spacing / 2); /* WHY: Added to allow drop-shadow to show as we're hiding overflow */
		overflow: hidden;
	}

	.multi-item-tabs {
		// display: flex;
		// flex-direction: row;
		// gap: calc($spacing * 2);
		padding-top: calc($spacing / 2);

		:deep(.swiper-slide) {
			// padding: 0 calc($spacing);
			transition: transform 0.2s ease-in;
			padding: 0 calc($spacing / 2);
		}

		:deep(.swiper-slide-active) {
			transform: scale(1.2);
			// margin: 0 calc($spacing * 2);
			z-index: 2;
		}

		:deep(> .swiper-wrapper) {
			// gap: calc($spacing / 2);
		}

		.tab {
			width: auto;

			.tab-button {
				width: 120px;
				transition: background-color 0.3s ease, color 0.3s ease;
			}
		}
	}

	.multi-item-container {
		:deep(> .swiper-wrapper) {
			> .swiper-slide {
				position: relative;

				&::after {
					content: "";
					background-color: rgba($background-color-modal, 0);
					transition: background-color 0.3s ease;
					@include swiper-overlay-fix;
				}

				&:not(.swiper-slide-active) {
					&::after {
						z-index: 1000;
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						background-color: rgba($background-color-modal, 0.6);
					}
				}
			}
		}
	}
}

.dialog-new {
	animation: slideInLeft 0.3s ease normal;
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */
	// width: 90vw;
	// height: 80dvh;
	flex-direction: column;
	min-width: min(300px, 90vw);
	max-height: 80dvh;
	background-color: $background-color-primary;
	border-radius: $border-radius-primary;
	border: none;
	padding: 0;
	@include drop-shadow-subtle;
	overflow: auto;

	&[open] {
		display: flex;
	}

	&.full-screen {
		margin: 0;
		width: 100vw;
		height: 100dvh;
		max-width: none;
		max-height: none;
	}

	&::backdrop {
		animation: fadeIn 0.3s ease normal;
		background-color: $background-color-modal;
	}

	&.close {
		animation: slideOutLeft 0.3s ease normal;

		&::backdrop {
			animation: fadeOut 0.3s ease normal;
		}
	}

	&.right {
		animation-name: slideInRight;

		&.close {
			animation-name: slideOutRight;
		}
	}

	&:focus {
		outline: none;
	}

	.header {
		position: relative;
		display: flex;
		border-bottom: $border-size-primary solid $border-color-primary;
		padding: calc($spacing / 2) 0;
		min-height: 22px;

		h2 {
			width: 100vw;
			margin: 0;
			text-align: center;
			font-size: $text-size-header-secondary;
		}
	}

	.dialog-inner {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		gap: calc($spacing);
		mix-blend-mode: normal;

		box-sizing: border-box;
		overflow: auto;

		&.padding {
			padding: 0 calc($spacing / 2);
			margin: calc($spacing * 1.25);
		}
	}
}

@media (min-width: $bp-medium) {
	.fake-modal-wrapper {
		&.close {
			.fake-modal {
				animation-name: fadeOut;
			}
		}

		.swiper-container {
			width: 550px;
			max-width: 550px;

			.navigation-button-prev,
			.navigation-button-next {
				display: block;
			}

			.multi-item-tabs,
			.multi-item-container {
				@include fade-out-content-horizontal;
			}
		}
	}

	// .multi-item-tabs,
	// .multi-item-container {
	// 	max-width: 700px;
	// 	@include fade-out-content-horizontal;
	// }

	.dialog-new {
		animation-name: fadeIn;
		box-sizing: border-box;

		&.full-screen {
			max-width: 400px;
			max-height: 80vh;
			margin: auto;
		}

		&.close {
			animation-name: fadeOut;
		}
	}
}
</style>
