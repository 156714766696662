<template>
	<MiniCard
		v-if="props.showMini"
		:text="text"
		:middle-text="topSubText"
		:bottom-text="bottomText"
		:image-src="imageUrl"
		:image-preview="imagePreview"
		:router-link="routerLink"
		:router-link-enabled="isRouterLinkEnabled"
		class="city-card"
		@click="cardOnClick"
	/>
	<Card
		v-else
		:top-text="text"
		:top-sub-text="topSubText"
		:bottom-text="bottomText"
		:image-src="imageUrl"
		:image-preview="imagePreview"
		:router-link="routerLink"
		:router-link-enabled="isRouterLinkEnabled"
		class="city-card"
		@click="cardOnClick"
	/>
</template>

<script setup>
import { computed, inject } from "vue";
import { useRoute } from "vue-router";

import { PremiumFeature } from "../constants/index.js";
import { useRouteParams } from "../helpers/index.js";

import Card from "./Card.vue";
import MiniCard from "./MiniCard.vue";

// const STATUS_DISPLAY_TEXT = {
// 	[CityStatus.AVAILABLE_NOW]: "Available Now",
// 	[CityStatus.COMING_SOON]: "Coming Soon",
// 	[CityStatus.NEW_CITY]: "New!",
// };

const props = defineProps({
	city: {
		type: Object,
		required: true,
		validator: (city) => {
			return city.id && city.name;
		},
	},
	showMini: {
		type: Boolean,
		default: false,
	},
	routerLinkEnabled: {
		type: Boolean,
		default: true,
	},
	showNameOnly: {
		type: Boolean,
		default: false,
	},
});

const userActionDialogRef = inject("userActionDialogRef");
const route = useRoute();
const routeParams = useRouteParams();

const imageUrl = computed(() => props.city.image?.url);
const imagePreview = computed(() => props.city.image?.preview ?? null);
const bottomText = computed(() => (!props.showNameOnly ? `${props.city?.venueCount ?? 0} Places` : ""));
const topSubText = computed(() => (!props.showNameOnly ? props.city?.shortDescription : "") ?? "");
// const statusText = computed(() => STATUS_DISPLAY_TEXT[props.city.status]);
const text = computed(() => props.city?.name ?? "");
const view = computed(() => props.city?.canView ?? false);
const canView = computed(() => view.value?.canView ?? false);
const action = computed(() => view.value?.action ?? null);
const routerLink = computed(() => {
	const city = props.city.name.toLowerCase();
	const changeCityFallbackRoute = route.meta?.changeCityFallbackRoute && city !== routeParams.cityName.toLowerCase() ? route.meta?.changeCityFallbackRoute : {};
	const { name, params = {} } = changeCityFallbackRoute;

	return {
		name,
		params: {
			...params,
			city,
		},
	};
});
const isRouterLinkEnabled = computed(() => props.routerLinkEnabled && canView.value);

const cardOnClick = () => {
	if (!canView.value) {
		userActionDialogRef.value.open({ feature: PremiumFeature.CITY, userAction: action.value });
	}
};
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";
.city-card {
	cursor: pointer;
}
</style>
