import { computed, inject, onActivated, onMounted, unref, watch } from "vue";
import { useScroll } from "@vueuse/core";
import { debounce } from "lodash-es";

import { useLogger } from "../logger/index.js";
import loggingMessages from "./useScrollMemory.logging-messages.js";

export const useScrollMemory = ({ name: nameMaybeRefOrString, containerRef, isPersistent = false, focusOnScroll = false } = {}) => {
	const logger = useLogger({ name: "useScrollMemory" });
	const positionCache = inject("positionCache");
	let nonPersistentPositionCache = null;

	const containerRefHtmlElement = computed(() => (containerRef.value instanceof HTMLElement ? containerRef.value : containerRef.value?.$el));

	const { x, y } = useScroll(containerRefHtmlElement, { throttle: 100 });

	const onScrollSetFocus = debounce(
		() => {
			if (focusOnScroll) {
				containerRefHtmlElement.value.focus();
			}
		},
		1000,
		{ leading: true, trailing: false },
	);

	watch(
		computed(() => containerRef.value || unref(nameMaybeRefOrString)),
		restorePosition,
	);
	watch([x, y], () => {
		storePosition();
		onScrollSetFocus();
	});
	onMounted(() => {
		restorePosition();
	});
	onActivated(() => {
		restorePosition();
	});

	return {
		scrollToTop,
	};

	function storePosition() {
		const name = getName();
		const updatedPosition = { x: x.value, y: y.value };
		if (isPersistent) {
			positionCache[name] = updatedPosition;
		} else {
			nonPersistentPositionCache = { name, data: updatedPosition };
		}
		logger.log(loggingMessages.scrollPositionStored, { name, position: updatedPosition });
	}

	function restorePosition() {
		const name = getName();
		const cachedPosition = isPersistent ? positionCache[name] : name === nonPersistentPositionCache?.name ? nonPersistentPositionCache.data : null;
		if (cachedPosition) {
			x.value = cachedPosition.x;
			y.value = cachedPosition.y;
			logger.log(loggingMessages.scrollPositionRestored, {
				name,
				position: cachedPosition,
			});
		}
	}

	function getName() {
		return nameMaybeRefOrString ? unref(nameMaybeRefOrString) : "non-persistent";
	}

	function scrollToTop() {
		if (containerRef.value) {
			x.value = 0;
			y.value = 0;
		}
	}
};
