import { computed, unref } from "vue";

export const useVenueProductSummary = (list) => {
	const listVenueProducts = computed(
		() =>
			unref(list)
				.items?.filter(({ venueProduct }) => venueProduct?.published)
				.map(({ venueProduct }) => {
					return venueProduct;
				}) ?? [],
	);

	const venueProductsSummary = computed(() => {
		return Object.values(unref(listVenueProducts)).reduce((acc, venueProduct) => {
			const existingVenuue = acc.find(({ venue }) => venue.id === venueProduct.venue.id);
			if (existingVenuue) {
				existingVenuue.venueProducts.push(venueProduct);
			} else {
				acc.push({ venue: venueProduct.venue, venueProducts: [venueProduct] });
			}
			return acc;
		}, []);
	});

	return venueProductsSummary;
};
