import { BookingMethods } from "./BookingMethods.js";
import { SpecialTagIds } from "./SpecialTagIds.js";

export const SpecialTagsLookupById = {
	[SpecialTagIds.VIEW_ALL]: {
		id: SpecialTagIds.VIEW_ALL,
		name: "All Places",
		image: { url: "https://onezone.imgix.net/venue_image_cjruiwkn549da0830omd0dy4k_1550423844136" },
		venueSelector: () => true,
	},
	[SpecialTagIds.PLACES_WITH_PERKS]: {
		id: SpecialTagIds.PLACES_WITH_PERKS,
		name: "Places with Perks",
		image: { url: "https://onezone.imgix.net/venue_image_cky8tf31izlq20a985v34p2ql_1688640290201" },
		venueSelector: ({ perks }) => perks()?.length > 0,
	},
	[SpecialTagIds.LIVE_AVAILABILITY]: {
		id: SpecialTagIds.LIVE_AVAILABILITY,
		name: "Places with Live Availability",
		image: { url: "https://onezone.imgix.net/venue_image_cky8tf31izlq20a985v34p2ql_1726667376529" },
		venueSelector: ({ book }) => [BookingMethods.IN_APP, BookingMethods.PASS_THROUGH].includes(book?.bookingMethod),
	},
};
