<template>
	<div
		ref="scrollableContainerRef"
		:class="{ 'scrollable-container': true, 'include-top-margin': props.includeTopMargin, 'include-all-spacing': props.includeAllSpacing }"
		:style="{ 'padding-bottom': `${keyboardCompensationForMobile}px` }"
	>
		<slot />
	</div>
</template>

<script setup>
import { useElementBounding, useWindowSize } from "@vueuse/core";
import { Keyboard } from "@capacitor/keyboard";
import { computed, inject, onMounted, onUnmounted, ref } from "vue";

const props = defineProps({
	includeTopMargin: {
		type: Boolean,
		default: true,
	},
	includeAllSpacing: {
		type: Boolean,
		default: true,
	},
});

const platform = inject("platform");

const scrollableContainerRef = ref();
const mobileKeyboardHeight = ref(0);

const { bottom: scrollableContainerBottom } = useElementBounding(scrollableContainerRef);
const { height: windowHeight } = useWindowSize();

const keyboardCompensationForMobile = computed(() => (mobileKeyboardHeight.value > 0 ? scrollableContainerBottom.value - (windowHeight.value - mobileKeyboardHeight.value) + 14 : 0));

let keyboardWillShowListener,
	keyboardDidShowListener,
	keyboardWillHideListener,
	isKeyboardShowing = false;
onMounted(async () => {
	if (platform.isNative) {
		keyboardWillShowListener = await Keyboard.addListener("keyboardWillShow", (info) => {
			mobileKeyboardHeight.value = info.keyboardHeight;
		});
		keyboardDidShowListener = await Keyboard.addListener("keyboardDidShow", () => {
			if (!isKeyboardShowing) {
				isKeyboardShowing = true;
				const focusedElement = document.activeElement;
				if (focusedElement) {
					focusedElement.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
				}
			}
		});
		keyboardWillHideListener = await Keyboard.addListener("keyboardWillHide", () => {
			isKeyboardShowing = false;
			mobileKeyboardHeight.value = 0;
		});
	}
});
onUnmounted(() => {
	keyboardWillShowListener?.remove();
	keyboardDidShowListener?.remove();
	keyboardWillHideListener?.remove();
});
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

.scrollable-container {
	flex-grow: 1;
	overflow: auto;

	&.include-all-spacing {
		margin-left: calc($spacing / 2);
		margin-right: calc($spacing / 2);
		padding-left: calc($spacing / 2);
		padding-right: calc($spacing / 2);
	}

	&.include-top-margin {
		margin-top: $spacing;
	}
}
</style>
