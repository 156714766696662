export const ButtonActions = {
	BOOKING_OPEN: "BOOKING_OPEN",
	BOOKING_CHECK_AVAILABILITY: "BOOKING_CHECK_AVAILABILITY",
	BOOKING_SELECT_TIME_SLOT: "BOOKING_SELECT_TIME_SLOT",
	BOOKING_CANCEL_TIME_SLOT: "BOOKING_CANCEL_TIME_SLOT",
	BOOKING_DIALOG_CLOSE: "BOOKING_DIALOG_CLOSE",
	BOOKING_DIALOG_BACK: "BOOKING_DIALOG_BACK",
	BOOKING_COMPLETE: "BOOKING_COMPLETE",
	PERK_OPEN: "PERK_OPEN",
};
