<template>
	<CustomRouterLink v-if="isRelativeLink" :to="ctaLinkUrl" :class="{ 'push-button-call-to-action': props.useDefaultStyle }" @click="onClick">
		<slot />
	</CustomRouterLink>
	<InAppBrowserLink v-else-if="ctaLinkUseIFrame" :class="{ 'push-button-call-to-action': props.useDefaultStyle }" :href="ctaLinkUrl" :browser-title="ctaBrowserTitle" @click="onClick">
		<slot />
	</InAppBrowserLink>
	<ExternalLink v-else :href="ctaLinkUrl" :class="{ 'push-button-call-to-action': props.useDefaultStyle }" @click="onClick">
		<slot />
	</ExternalLink>
</template>

<script setup>
import { computed } from "vue";

import InAppBrowserLink from "./InAppBrowserLink.vue";
import ExternalLink from "./ExternalLink.vue";

const props = defineProps({
	ctaLink: {
		type: Object,
		required: true,
	},
	browserTitle: {
		type: String,
		default: undefined,
	},
	useDefaultStyle: {
		type: Boolean,
		default: true,
	},
});

const emit = defineEmits(["click"]);

const ctaBrowserTitle = computed(() => props.browserTitle);
const ctaLinkUseIFrame = computed(() => props.ctaLink.useIFrame ?? true);
const ctaLinkUrl = computed(() => props.ctaLink.url ?? null);
const isRelativeLink = computed(() => ctaLinkUrl.value?.startsWith("/"));

function onClick() {
	emit("click");
}
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";
</style>
