import { queries, mutations } from "./model/index.js";

export class Model {
	constructor({ logger, modelBuilder, entityStore, api, router, authentication, changeManager, endpointUrl }) {
		this.logger = logger.nested({ name: "Model" });
		this.modelBuilder = modelBuilder;
		this.entityStore = entityStore;
		this.api = api;
		this.router = router;
		this.authentication = authentication;
		this.changeManager = changeManager;
		this.endpointUrl = endpointUrl;
	}

	initialise({ cities }) {
		this.queryInstances = {};
		this.queries = Object.fromEntries(
			queries.map(({ name, Class }) => {
				if (!this.queryInstances[name]) {
					this.queryInstances[name] = new Class({ ...this, cities });
				}
				return [name, (...args) => this.queryInstances[name].execute(...args)];
			}),
		);

		this.mutationInstances = {};
		this.mutations = Object.fromEntries(
			mutations.map(({ name, Class }) => {
				if (!this.mutationInstances[name]) {
					this.mutationInstances[name] = new Class({ ...this, cities });
				}
				return [
					name,
					(...args) => {
						return this.mutationInstances[name].execute(...args);
					},
				];
			}),
		);
	}

	getStoredEntity({ id, typeName } = {}) {
		return this.entityStore.get({ id, typeName });
	}

	static getKeys() {
		return {
			queries: queries.map(({ name }) => name),
			mutations: mutations.map(({ name }) => name),
		};
	}
}
