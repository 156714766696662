<template>
	<div class="iframe-wrapper">
		<iframe v-show="isIFrameLoaded" :src="url" :style="{ width: `calc(100% + ${scrollBarWidth}px)` }" @load="iframeLoaded" />
		<LoadingMessage v-if="!isIFrameLoaded" :full-screen="true">Loading, please wait...</LoadingMessage>
		<div ref="measureScrollBarWidthRef" class="measure-scrollbar-width"></div>
	</div>
</template>

<script setup>
import { onMounted, ref, computed, watch } from "vue";

const props = defineProps({
	url: {
		type: String,
		required: true,
	},
});

const measureScrollBarWidthRef = ref(null);
const scrollBarWidth = ref(0);
const isIFrameLoaded = ref(false);

const url = computed(() => decodeURIComponent(props.url));

watch(url, () => {
	isIFrameLoaded.value = false;
});

onMounted(() => {
	scrollBarWidth.value = measureScrollBarWidthRef.value.offsetWidth - measureScrollBarWidthRef.value.clientWidth;
});

defineExpose({
	open,
	close,
});

function iframeLoaded() {
	isIFrameLoaded.value = true;
}
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

.iframe-wrapper {
	overflow: hidden;

	iframe {
		border: none;
		width: 100%;
		height: 100%;
	}

	.measure-scrollbar-width {
		position: absolute;
		top: 0;
		left: 0;
		width: 50px;
		height: 50px;
		overflow: scroll;
		z-index: -1;

		&::-webkit-scrollbar {
			width: auto;
			height: auto;
		}
	}
}
</style>
