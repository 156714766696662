import { computed, inject, onMounted, onUnmounted, ref } from "vue";
import { useElementBounding, useWindowSize } from "@vueuse/core";
import { Keyboard } from "@capacitor/keyboard";

export const useKeyboardPadding = (containerRef) => {
	const platform = inject("platform");

	const mobileKeyboardHeight = ref(0);

	const { bottom: scrollableContainerBottom } = useElementBounding(containerRef);
	const { height: windowHeight } = useWindowSize();

	const keyboardCompensationForMobile = computed(() => (mobileKeyboardHeight.value > 0 ? scrollableContainerBottom.value - (windowHeight.value - mobileKeyboardHeight.value) + 14 : 0));

	let keyboardWillShowListener,
		keyboardDidShowListener,
		keyboardWillHideListener,
		isKeyboardShowing = false;
	onMounted(async () => {
		if (platform.isNative) {
			keyboardWillShowListener = await Keyboard.addListener("keyboardWillShow", (info) => {
				mobileKeyboardHeight.value = info.keyboardHeight;
			});
			keyboardDidShowListener = await Keyboard.addListener("keyboardDidShow", () => {
				if (!isKeyboardShowing) {
					isKeyboardShowing = true;
					const focusedElement = document.activeElement;
					if (focusedElement) {
						focusedElement.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
					}
				}
			});
			keyboardWillHideListener = await Keyboard.addListener("keyboardWillHide", () => {
				isKeyboardShowing = false;
				mobileKeyboardHeight.value = 0;
			});
		}
	});

	onUnmounted(() => {
		keyboardWillShowListener?.remove();
		keyboardDidShowListener?.remove();
		keyboardWillHideListener?.remove();
	});

	return {
		style: computed(() => ({
			paddingBottom: `${keyboardCompensationForMobile.value}px`,
			boxSizing: "border-box",
		})),
	};
};
