<template>
	<component
		:is="props.stage.component.is"
		:venue-booking="props.venueBooking"
		:placement="props.placement"
		v-bind="{ ...(props.stage.component.vBind ?? {}), ...(props.vBindOverride ?? {}) }"
		class="component"
		v-on="{ ...(props.stage.component.vOn ?? {}), ...(props.vOnOverride ?? {}) }"
	/>
</template>

<script setup>
const props = defineProps({
	venueBooking: {
		type: Object,
		required: true,
	},
	stage: {
		type: Object,
		required: true,
	},
	vOnOverride: {
		type: Object,
		required: false,
		default: null,
	},
	vBindOverride: {
		type: Object,
		required: false,
		default: null,
	},
	placement: {
		type: String,
		required: true,
	},
});
</script>

<style scoped lang="scss">
@import "../../assets/styles/variables_new.scss";
</style>
