import fetch from "cross-fetch";
import { ApolloClient, InMemoryCache } from "@apollo/client/core/index.js";
import { BatchHttpLink } from "@apollo/client/link/batch-http";

import { useConfig } from "../domain/config.js";
import loggingMessages from "./DataService.logging-messages";
import { ApiRequestQueryError, ApiRequestMutationError } from "../errors/index.js";
import { reactive } from "vue";
import dayjs from "dayjs";

export class DataService {
	constructor({ logger, platform, endpointUrl, ssrState, queryString, defaultEnvironment, environmentName }) {
		this.logger = logger.nested({ name: "DataService" });
		this.platform = platform;
		this.endpointUrl = endpointUrl;
		this.ssrState = ssrState;
		this.queryString = queryString;
		this.defaultEnvironment = defaultEnvironment;
		this.environmentName = environmentName;
		this.environment = null;
		this.config = useConfig();
		this.apolloClient = {};
		this.operationPerformance = reactive({});
	}

	async get({ name: operationName, query, variables = {}, options: { useSSRState = true, accessToken, shouldImpersonate = true } = {} } = {}) {
		let data = null;
		if (useSSRState) {
			const cachedData = this.#getFromSSRState(operationName, variables);
			if (cachedData) {
				this.logger.log(loggingMessages.usingSSRCachedDataForOperation, { operationName, variables, environmentName: this.environmentName });
				data = cachedData;
				this.#removeSSRState(operationName, variables); /* WHY: Remove SSR data for this operation after it's retrieved and allow client to call API or use client cache */
			}
		}

		if (!data) {
			this.logger.log(loggingMessages.usingApiDataForOperation, { operationName, variables, environmentName: this.environmentName });
			const apolloClient = await this.#getApolloClient({ accessToken, shouldImpersonate });
			try {
				const startTime = !this.platform.isServer ? window.performance.now() : null;
				const results = await apolloClient.query({ query, variables, fetchPolicy: "no-cache" });
				const endTime = !this.platform.isServer ? window.performance.now() : null;
				const durationMS = !this.platform.isServer ? endTime - startTime : 0;

				if (this.defaultEnvironment?.isAdmin.value || this.environment?.isAdmin.value) {
					const now = dayjs().format("YYYY-MM-DD HH:mm:ss");
					const variablesKey = this.#generateVariablesKey(variables);
					if (!this.operationPerformance[operationName]) {
						this.operationPerformance[operationName] = {};
					}
					if (!this.operationPerformance[operationName][variablesKey]) {
						this.operationPerformance[operationName][variablesKey] = [];
					}
					this.operationPerformance[operationName][variablesKey].push({ dateTime: now, durationMS });
				}

				this.logger.log(loggingMessages.apiDataCallForOperationSuccessful, { operationName, variables, environmentName: this.environmentName, durationMS });
				data = results.data;
			} catch (error) {
				const graphQLErrors = error?.networkError?.result?.errors ?? [];
				if (graphQLErrors.length > 0) {
					throw new ApiRequestQueryError(error.message, new Error(JSON.stringify(graphQLErrors, null, 2)));
				} else {
					throw error;
				}
			}
		}

		if (this.platform.isServer) {
			this.#storeInSSRState(operationName, variables, data);
		}

		return data;
	}

	async update({ name: operationName, mutation, variables = {}, options: { useClientCache = true, accessToken } = {} } = {}) {
		let data = null,
			errors = [];

		if (!data) {
			this.logger.log(loggingMessages.usingApiDataForOperation, { operationName, variables, useClientCache, environmentName: this.environmentName });
			const apolloClient = await this.#getApolloClient({ accessToken });
			try {
				const results = await apolloClient.mutate({ mutation, variables, fetchPolicy: useClientCache ? "network-only" : "no-cache", errorPolicy: "all" });
				data = results.data;
				errors = results.errors ?? [];
			} catch (error) {
				const graphQLErrors = error?.networkError?.result?.errors ?? [];
				throw new ApiRequestMutationError(error.message, new Error(JSON.stringify(graphQLErrors, null, 2)));
			}
		}
		if (this.platform.isServer) {
			this.#storeInSSRState(operationName, variables, data);
		}
		return { data, errors };
	}

	setEnvironment(environment) {
		this.environment = environment;
	}

	#generateCacheKey(operationName, variables) {
		return `${operationName}:${this.#generateVariablesKey(variables)}`;
	}

	#generateVariablesKey(variables) {
		return Object.keys(variables).length > 0
			? Object.entries(variables)
					.map(([key, value]) => `${key}=${value}`)
					.join(",")
			: "no-args";
	}

	#storeInSSRState(operationName, variables, data) {
		const cacheKey = this.#generateCacheKey(operationName, variables);
		this.ssrState[cacheKey] = data;
	}

	#removeSSRState(operationName, variables) {
		const cacheKey = this.#generateCacheKey(operationName, variables);
		delete this.ssrState[cacheKey];
	}

	#getFromSSRState(operationName, variables) {
		const cacheKey = this.#generateCacheKey(operationName, variables);
		const cachedData = this.ssrState ? this.ssrState[cacheKey] : null;
		return cachedData;
	}

	async #getApolloClient({ accessToken = null, shouldImpersonate = true } = {}) {
		const finalAccessToken = typeof accessToken === "function" ? await accessToken() : accessToken;

		if (!this.apolloClient[finalAccessToken]) {
			this.apolloClient[finalAccessToken] = {};
		}

		if (!this.apolloClient[finalAccessToken][shouldImpersonate]) {
			const apolloClient = new ApolloClient({
				link: new BatchHttpLink({
					uri: this.endpointUrl,
					batchMax: 5, // Maximum number of operations per batch
					batchInterval: 20, // Wait time in ms before sending batch
					headers: {
						"is-premium-free": "true",
						"apollographql-client-name": this.config.clientName,
						"apollographql-client-version": this.config.clientVersion,
						...(finalAccessToken
							? {
									Authorization: `Bearer ${finalAccessToken}`,
							  }
							: {}),
						...(this.queryString.impersonate && shouldImpersonate ? { "impersonate-user": this.queryString.impersonate } : {}),
					},
					fetch,
				}),
				cache: new InMemoryCache({
					typePolicies: {
						List: {
							fields: {
								/* WHY: as we don't really care about ListItem ids and don't supply them to API, we always merge incoming here. (when we add venues to lists in change effect, we don't generate list item id) */
								items: {
									merge: (existing, incoming) => incoming,
								},
							},
						},
					},
				}),
				defaultOptions: {
					query: {
						fetchPolicy: "cache-first",
						errorPolicy: "none",
					},
				},
				connectToDevTools: true,
			});
			this.logger.log(loggingMessages.gotApolloClient, { isAuthenticated: !!finalAccessToken });
			this.apolloClient[finalAccessToken][shouldImpersonate] = apolloClient;
		}

		return this.apolloClient[finalAccessToken][shouldImpersonate];
	}
}
