<template>
	<UniversalCard
		:image="firstImage"
		:router-link="openVenueProductRouterLink"
		:router-link-enabled="props.routerLinkEnabled"
		:card-type="props.cardType"
		:name="name"
		:description="address"
		:summary="spaces"
	>
		<template #header>
			<h2>{{ name }}</h2>
		</template>
		<template #aside>
			<div>{{ spaces }}, {{ address }}</div>
			<div v-html="venue.longDescriptionHtml" />
		</template>
		<template #footer>
			<router-link :to="openVenueProductRouterLink" class="push-button-primary">Discover</router-link>
			<!-- <VenueProductRequestInfoButton :venue="venue" :venue-product="firstVenueProduct" /> -->
		</template>
		<template #decals1>
			<img v-if="shouldHighlightBooking" :src="onezoneLogoWhite" alt="Live Availability" class="live-booking-decal" />
		</template>
		<template #decals2>
			<img v-if="perkIcon" :src="DiamondWhite" alt="Perks" class="perks-decal" />
		</template>
	</UniversalCard>
</template>

<script setup>
import { computed } from "vue";

import { useVenueBookingMethod } from "../composables/index.js";
import { pluralize } from "../helpers/index.js";

import UniversalCard from "./UniversalCard.vue";

import DiamondWhite from "../assets/icons/Diamond-White.svg";
import onezoneLogoWhite from "../assets/icons/logo-black-background.svg";
// import VenueProductRequestInfoButton from "./venue/VenueProductRequestInfoButton.vue";

const props = defineProps({
	venue: {
		type: Object,
		required: true,
	},
	venueProducts: {
		type: Array,
		required: true,
	},
	venueProductTypes: {
		type: Array,
		required: true,
	},
	routerLinkEnabled: {
		type: Boolean,
		default: undefined,
	},
	cardType: {
		type: String,
		default: undefined,
	},
});

const venue = computed(() => props.venue);
const { shouldHighlightBooking } = useVenueBookingMethod(venue);
const perkIcon = computed(() => venue.value.perks()?.length > 0);
const venueProducts = computed(() => props.venueProducts);
// const firstVenueProduct = computed(() => venueProducts.value?.[0]);
const matchingVenueProductTypes = computed(() => props.venueProductTypes.filter((venueProductType) => venueProducts.value.some((venueProduct) => venueProduct.type === venueProductType.name)));
const firstMatchingVenueProductType = computed(() => matchingVenueProductTypes.value?.[0]);

const name = computed(() => venue.value.name);
const address = computed(() => venue.value.addressLine2);
const spaces = computed(() => `${venueProducts.value.length} ${pluralize(venueProducts.value.length, "Space")}`);

const firstImage = computed(() => venue.value.images?.[0] ?? null);
// const openVenueRouterLink = computed(() => {
// 	return (
// 		props.routerLink ?? {
// 			name: "City/Explore/Areas/Venue",
// 			params: {
// 				venueId: venue.value.id,
// 			},
// 		}
// 	);
// });
const openVenueProductRouterLink = computed(() => {
	return (
		props.routerLink ?? {
			name: "City/Explore/Areas/Venue",
			params: {
				venueId: venue.value.id,
			},
			query: {
				venueProductType: firstMatchingVenueProductType.value.name,
				venueProductsOpen: true,
			},
		}
	);
});
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

:deep(.perks-decal) {
	height: 16px !important;
}
</style>
