<template>
	<div class="home-feed-container">
		<LoadingMessageWithError
			v-if="queries.error || (queries.isLoading && (queries.state?.loading ?? LOADING_STATE.LOAD_INITIAL) === LOADING_STATE.LOAD_INITIAL)"
			:error="queries.error"
			:retry="queries.retry"
		>
			Loading {{ cityNameFormatted }}, please wait...
		</LoadingMessageWithError>
		<!-- <SuperList
			v-else-if="isAllowedToViewCity"
			v-slot="{ item: list, style, isItemVisible }"
			:name="`home-feed-${routeParams.cityName}`"
			class="home-feed"
			:query="queries"
			:items="feedLists"
			:item-size="ITEM_HEIGHT"
			:is-loading-more="queries.isLoading && (queries.state?.loading ?? LOADING_STATE.LOAD_INITIAL) === LOADING_STATE.LOAD_MORE"
			:load-more-distance-item-count="5"
			:loading-message="loadingMessage"
			:empty-message="emptyMessage"
			:is-virtual="false"
			:enable-pull-to-refresh="true"
			:enable-load-more="true"
			@refresh="onRefresh"
			@load-more="onLoadMore"
		>
			<FeedList :list="list" :style="style" :show-venues="isItemVisible.value" />
		</SuperList> -->
		<HomeFeedList v-else-if="isAllowedToViewCity" :city-name="cityNameFormatted" :feed-lists="feedListsWithAds" :query="queries" @refresh="onRefresh" @load-more="onLoadMore" />
		<PremiumUpgradeMessage v-else :feature="PremiumFeature.CITY" :user-action="SuggestedUserAction.UPGRADE_PREMIUM">
			Only premium members are allowed to view {{ cityNameFormatted }}.<br /><br />
			Access more cities with onezone premium
		</PremiumUpgradeMessage>
	</div>
</template>

<script setup>
import { inject, ref, onMounted, computed, capitalize } from "vue";

import { AdvertPlacementNames, LOADING_STATE, PremiumFeature, SuggestedUserAction } from "../constants/index.js";
import { combineQueries, useRouteParams, waitForDataSSR } from "../helpers/index.js";

// import FeedList from "./FeedList.vue";
// import SuperList from "./SuperList.vue";
import PremiumUpgradeMessage from "./PremiumUpgradeMessage.vue";
import LoadingMessageWithError from "./LoadingMessageWithError.vue";
import HomeFeedList from "./HomeFeedList.vue";

const ITEM_COUNT_PER_PAGE = 10;
// const ITEM_HEIGHT = 280;
const ADS_EVERY_NTH_ITEM = 10;
const ADS_OFFSET = 3;

const logger = inject("logger").nested({ name: "HomeFeed" });
const model = inject("model");
const currentEnvironment = inject("currentEnvironment");
const routeParams = useRouteParams();

const isMounted = ref(false);

const city = computed(() => queries?.model?.city);
const feedLists = computed(() => [...(queries?.model?.city?.feedLists ?? [])]);
const matchAdvertResults = computed(() => queries?.model?.matchAdvertResults);
const feedListsWithAds = computed(() =>
	feedLists.value.reduce((acc, feedList, index) => {
		const advertIndex = Math.floor(index / ADS_EVERY_NTH_ITEM);
		const pageIndex = queries.homeFeed.actions.getPageIndex();
		const matchAdvertResult = matchAdvertResults.value[advertIndex];
		if ((index - ADS_OFFSET) % ADS_EVERY_NTH_ITEM === 0 && matchAdvertResult) {
			acc.push({ isAd: true, id: `ads-${index}`, matchAdvertResult, extraTrackingData: { pageIndex: pageIndex + 1 } });
		}
		acc.push(feedList);
		return acc;
	}, []),
);
const isAllowedToViewCity = computed(() => city.value?.canView?.canView ?? false);
const cityName = computed(() => routeParams.cityName);
const cityId = computed(() => currentEnvironment.value.cities.value.find(({ name }) => name.toLowerCase() === cityName.value.toLowerCase())?.id);
const cityNameFormatted = computed(() => capitalize(cityName.value));
// const loadingMessage = computed(() => `Loading ${cityNameFormatted.value}, please wait...`);
// const emptyMessage = computed(() => `No curated lists found for ${capitalize(routeParams.cityName)}`);
const queries = combineQueries(
	{
		homeFeed: model.queries.GetHomeFeed({
			cityName,
			pageSize: ITEM_COUNT_PER_PAGE,
		}),
		matchAdvert: model.queries.MatchAdvert({
			placementName: AdvertPlacementNames.HOME_FEED_IN_FEED,
			placementSize: computed(() => ({ width: 753, height: 203 })),
			matchJson: computed(() => ({ cityId: cityId.value })),
		}),
	},
	{ isLoading: { include: ["homeFeed"] } },
);

const onLoadMore = async () => {
	/* TODO: add error handling */
	queries.homeFeed.actions.loadMore();
	queries.matchAdvert.actions.loadMore();
};

const onRefresh = async () => {
	queries.homeFeed.actions.refresh();
	queries.matchAdvert.actions.refresh();
};

onMounted(() => {
	isMounted.value = true;
});

await waitForDataSSR(queries, logger);
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables_new.scss";

.home-feed-container {
	display: flex;
	justify-content: center;
	width: 100%;
	height: 100%;

	.home-feed {
		> :deep(.super-list) {
			padding: 0;
		}
	}

	// .home-feed > :deep(.super-list) {
	// 	scroll-snap-type: both mandatory;

	// 	> .feed-list-container {
	// 		scroll-snap-align: start;
	// 	}
	// }

	@include centre-loading-message;
}

@media (min-width: $bp-medium) {
	.home-feed-container {
		.home-feed {
			> :deep(.super-list) {
				// padding: 0 calc($spacing / 2) 0 0;
			}
		}
	}
}
</style>
