<template>
	<Dialog ref="citySwitchingDialogRef" class="city-switch-dialog" param-name="citySwitchOpen" param-mode="push" :full-screen="true" enter-direction="right">
		<template #default="{ isOpen, closeDialog }">
			<KeepAlive>
				<DialogFullScreenPageLayout v-if="isOpen" header="Change City" :show-header-on-desktop="true" :auto-wire-back-button="false" @close="closeDialog">
					<SwitchCityList class="switch-city-list" />
				</DialogFullScreenPageLayout>
			</KeepAlive>
		</template>
	</Dialog>
</template>

<script setup>
import { computed, ref } from "vue";

import Dialog from "../Dialog.vue";
import DialogFullScreenPageLayout from "../DialogFullScreenPageLayout.vue";
import SwitchCityList from "../SwitchCityList.vue";

const citySwitchingDialogRef = ref(null);

defineExpose({ open: openDialog, close, toggle: toggleDialog, isOpen: computed(() => citySwitchingDialogRef.value?.isOpen) });

function openDialog() {
	citySwitchingDialogRef.value.open();
}

function close() {
	citySwitchingDialogRef.value.close();
}

function toggleDialog() {
	citySwitchingDialogRef.value.toggle();
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/variables_new.scss";

:deep(.switch-city-list) {
	padding: calc($spacing) 0;
}

@media (min-width: $bp-medium) {
	:deep(.switch-city-list) {
		margin: calc($spacing / 2) calc($spacing / 2) calc($spacing) calc($spacing);
		padding: calc($spacing / 2) 0 0 0;
	}
}
</style>
