<template>
	<ul class="venue-navigation">
		<li class="menu">
			<a v-if="menuUrl" v-external-link :href="menuUrl" target="_blank" class="navigation-item push-button-primary">Menu</a>
			<span v-else class="disabled navigation-item push-button-primary">No Menu</span>
		</li>
		<!-- <li>
			<a v-if="directionsUrl" :href="directionsUrl" target="_blank" class="navigation-item push-button-primary">Directions</a>
			<span v-else class="disabled navigation-item push-button-primary">Directions</span>
		</li> -->
		<li class="book">
			<VenueBookButton :venue="props.venue" />
		</li>
	</ul>
</template>

<script setup>
import { computed } from "vue";

import VenueBookButton from "./VenueBookButton.vue";

const props = defineProps({
	venue: {
		type: Object,
		required: true,
	},
});

const menuUrl = computed(() => filterEmptyMenuUrlValues(props.venue.menuUrl) ?? null);

function filterEmptyMenuUrlValues(url) {
	return url === "" || ["no menu", "not available"].some((value) => url?.toLowerCase().includes(value)) ? null : url;
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/variables_new.scss";

.venue-navigation {
	box-sizing: content-box;
	display: flex;
	flex-direction: row;
	gap: calc($spacing * 1);
	justify-content: center;
	padding: calc($spacing / 1.5) calc($spacing * 1.3) 0 calc($spacing * 1.3);
	margin: 0;
	list-style: none;
	border-top: $border-size-primary solid $border-color-primary;

	li {
		height: 53px; /* fix to this height to match main navigation */
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;

		box-sizing: content-box;
		font-size: $text-size-header-secondary;
		color: $text-color-primary;
		font-weight: $text-bold-secondary;

		.navigation-item {
			display: flex;
			flex-grow: 1;
			justify-content: center;
			gap: calc($spacing / 2);
			padding: calc($spacing) 0;

			&.disabled {
				color: $text-color-secondary;
			}

			> * {
				text-align: center;
			}
		}

		&.menu {
			flex: 1;
		}
		&.book {
			flex: 2;
		}
	}
}

@media (min-width: $bp-medium) {
	.venue-navigation {
		padding: 0;
		li {
			height: auto;
			margin: 0;
		}
	}
}
</style>
