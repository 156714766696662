<template>
	<MiniCard v-if="props.showMini" :text="props.list.name" :bottom-text="bottomText" :image-src="imageUrl" :image-preview="imagePreview" :router-link="routerLink">
		<slot />
	</MiniCard>
	<Card v-else :top-text="props.list.name" :top-sub-text="topSubText" :bottom-text="bottomText" :image-src="imageUrl" :image-preview="imagePreview" :router-link="routerLink">
		<slot />
	</Card>
</template>

<script setup>
import { computed } from "vue";

import { useRouteParams, urlBuilder } from "../helpers/index.js";
import { PageNames } from "../constants/index.js";
import Card from "./Card.vue";
import MiniCard from "./MiniCard.vue";

const props = defineProps({
	list: {
		type: Object,
		required: true,
		validator: (list) => {
			return list.id && list.name && list.image && list.city && (list.numberPublishedVenues !== undefined || list.venues);
		},
	},
	showMini: {
		type: Boolean,
		default: false,
	},
	showVenueCount: {
		type: Boolean,
		default: true,
	},
});

const imageUrl = computed(() => props.list.image?.url ?? null);

const imagePreview = computed(() => props.list.image?.preview ?? null);

const bottomText = computed(() => {
	const venueCount = props.list.numberPublishedVenues ?? props.list.venues.filter(({ published }) => published).length;
	return props.showVenueCount ? `${venueCount} Places` : null;
});

const topSubText = computed(() => props.list.city.name);

const routeParams = useRouteParams();

const routerLink = computed(() => urlBuilder(PageNames.EXPLORE_LIST, { cityName: routeParams.cityName, list: props.list }));
</script>
