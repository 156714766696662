<template>
	<VenueDetailSection title="Something Wrong" class="venue-feedback">
		<button class="feedback" @click="openFeedbackDialog">
			<div class="icon"></div>
			Something wrong or not working?
		</button>
	</VenueDetailSection>
</template>

<script setup>
import { inject } from "vue";

import VenueDetailSection from "./VenueDetailSection.vue";

const props = defineProps({
	venue: {
		type: Object,
		required: true,
	},
});

const feedbackDialogRef = inject("feedbackDialogRef");

function openFeedbackDialog() {
	feedbackDialogRef.value.open({ venueId: props.venue.id });
}
</script>

<style scoped lang="scss">
@import "../../assets/styles/variables_new.scss";

.venue-feedback {
	:deep(.feedback) {
		display: flex;
		align-items: center;
		font-size: $text-size-secondary;
		cursor: pointer;

		.icon {
			width: 26px;
			height: 26px;
			background: url("../../assets/icons/warning.svg") no-repeat center;
			margin-right: calc($spacing / 2);
		}
	}
}
</style>
