export const formatCurrency = (amount) => {
	let GBP = new Intl.NumberFormat("en-GB", {
		style: "currency",
		currency: "GBP",
		minimumFractionDigits: 0,
		maximumFractionDigits: 0,
	});

	return GBP.format(amount);
};
