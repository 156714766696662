<template>
	<a :href="props.href" @click="linkClicked"><slot /></a>
</template>

<script setup>
import { inject } from "vue";
import { openExternalLink } from "../helpers/openExternalLink.js";

import loggingMessages from "./ExternalLink.logging-messages.js";

const props = defineProps({
	href: {
		type: String,
		required: true,
	},
});

const logger = inject("logger").nested({ name: "ExternalLink" });

async function linkClicked(event) {
	const url = props.href;
	try {
		event.preventDefault();
		if (url) {
			const parsedUrl = new URL(url);
			const isCurrentHost = parsedUrl.host === window.location.host;
			const isExternalLink = url.startsWith("http") && !isCurrentHost;
			const isPDFDocument = parsedUrl.pathname.endsWith(".pdf");
			const shouldOpenInNewWindow = isExternalLink;
			openExternalLink({ url, shouldOpenInNewWindow, isFileLink: isPDFDocument });
		}
	} catch (error) {
		logger.log(loggingMessages.errorOpeningExternalLink, { url, error });
	}
}
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";
</style>
