export const EntityTypeNames = {
	City: "City",
	CityGroup: "CityGroup",
	List: "List",
	ListItem: "ListItem",
	Venue: "Venue",
	VenueProduct: "VenueProduct",
	VenueProductEnquiryInput: "VenueProductEnquiryInput",
	Image: "Image",
	Tag: "Tag",
	User: "User",
	ApiInfo: "ApiInfo",
	ChangeResults: "ChangeResults",
	UpdatedEntities: "UpdatedEntities",
	Zone: "Zone",
	Area: "Area",
	OpeningHours: "OpeningHours",
	Book: "Book",
	Launch: "Launch",
	CitySearchResults: "CitySearchResults",
	CitySearchResultsVenues: "CitySearchResultsVenues",
	CitySearchResultsTags: "CitySearchResultsTags",
	CitySearchResultsZones: "CitySearchResultsZones",
	CitySearchResultsLists: "CitySearchResultsLists",
	CitySearchResultsGoogleLocations: "CitySearchResultsGoogleLocations",
	GooglePlace: "GooglePlace",
	Premium: "Premium",
	PremiumProductPurchase: "PremiumProductPurchase",
	SearchResults: "SearchResults",
	SearchResultsUsers: "SearchResultsUsers",
	ActivityFeed: "ActivityFeed",
	BookingAvailabilityTimes: "BookingAvailabilityTimes",
	BookingAvailabilityTime: "BookingAvailabilityTime",
	BookingAvailabilityLock: "BookingAvailabilityLock",
	BookingAvailabilityUnlock: "BookingAvailabilityUnlock",
	BookingReservationConfirmation: "BookingReservationConfirmation",
	BookingReservationDetailsInput: "BookingReservationDetailsInput",
	BookingReservationConfirmationChallenge: "BookingReservationConfirmationChallenge",
	MatchAdvertResult: "MatchAdvertResult",
	AdvertPlacementSize: "AdvertPlacementSize",
};
