<template>
	<NewList
		v-slot="{ item, isItemVisible }"
		:show-items-visibility="true"
		class="home-feed"
		list-class="home-feed-list"
		item-class="home-feed-item"
		:name="listName"
		:query="props.query"
		:items="feedLists"
		:direction="ListDirections.VERTICAL"
		:item-height="250"
		:gap-size="28"
		:item-buffer-count="2"
		:enable-pull-to-refresh="true"
		:enable-load-more="true"
		:is-loading-more="props.query.isLoading && (props.query.state?.loading ?? LOADING_STATE.LOAD_INITIAL) === LOADING_STATE.LOAD_MORE"
		:show-debug-panel="false"
		@refresh="onRefresh"
		@load-more="onLoadMore"
	>
		<KeepAlive>
			<AdvertCardLayout
				v-if="isItemVisible && item.isAd"
				:placement-width="753"
				:placement-height="203"
				:match-advert-result="item.matchAdvertResult"
				:extra-tracking-data="item.extraTrackingData"
				class="advert"
			/>
			<FeedList v-else-if="isItemVisible" :key="`feed-list:${item.id}`" :feed-list="item" :venue-product-types="props.venueProductTypes" />
		</KeepAlive>
	</NewList>
	<!-- <EfficientList v-slot="{ item: feedList, style }" class="home-feed" :items="feedLists" :item-size="250" :buffer="2" :recycle="true">
		<FeedListNew :feed-list="feedList" :style="style" />
	</EfficientList> -->
</template>

<script setup>
import { computed } from "vue";

import { ListDirections } from "./IntersectionObserverList/index.js";
import { LOADING_STATE } from "../constants/index.js";

import NewList from "./NewList.vue";
import FeedList from "./FeedList.vue";
import AdvertCardLayout from "./adverts/AdvertCardLayout.vue";
// import EfficientList from "./EfficientList/EfficientList.vue";

const props = defineProps({
	cityName: {
		type: String,
		required: true,
	},
	feedLists: {
		type: Array,
		required: true,
	},
	venueProductTypes: {
		type: Array,
		required: true,
	},
	query: {
		type: Object,
		required: true,
	},
});

const emits = defineEmits(["refresh", "loadMore"]);

const listName = computed(() => `home-feed:${props.cityName}`);

const feedLists = computed(() => props.feedLists);

function onRefresh() {
	emits("refresh");
}

function onLoadMore() {
	emits("loadMore");
}
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

.adsbygoogle {
	width: 100%;
}

.home-feed {
	width: 100%;
	// @include fade-out-content;

	:deep(.home-feed-list) {
		padding: 0;
	}

	.advert {
		align-self: center;
		padding-left: calc($spacing);
		padding-right: calc($spacing);
	}
}

@media (min-width: $bp-medium) {
	.home-feed {
		.advert {
			padding-left: 0;
			padding-right: 0;
		}
	}
}
</style>
