<template>
	<Page :is-page-full-height="true">
		<DialogFullScreenPageLayout :show-header-on-desktop="true" class="dialog-full-screen-page-layout">
			<template #header>
				<h2>{{ pageTitle }}</h2>
			</template>
			<template v-if="queries?.isLoading || queries?.error" #default>
				<LoadingMessageWithError :error="queries?.error" :retry="queries?.retry" class="loading-message" />
			</template>
			<template v-else #default>
				<Tabs class="followers-and-following">
					<Tab :route-name="isCurrentUser ? PageNames.PROFILE_FOLLOWERS : PageNames.OTHER_USER_FOLLOWERS" class="name">{{ followersTabTitle }}</Tab>
					<Tab :route-name="isCurrentUser ? PageNames.PROFILE_FOLLOWING : PageNames.OTHER_USER_FOLLOWING" class="name">{{ followingTabTitle }}</Tab>
				</Tabs>
				<NewList
					v-slot="{ item, isItemVisible }"
					list-class="users"
					:name="listName"
					:items="listItems"
					:direction="ListDirections.VERTICAL"
					:item-height="userCardHeight"
					:gap-size="0"
					:item-buffer-count="4"
					:show-navigation-buttons="true"
					:enable-snap="false"
					:query="queries"
					:empty-message="emptyMessage"
					class="list"
				>
					<UserCard v-if="isItemVisible" :user="item" />
				</NewList>

				<!-- <EfficientList v-slot="{ item: user, style }" :items="listItems" :item-size="userCardHeight" :buffer="4" :recycle="true" class="list">
					<UserCard :user="user" :style="style" />
				</EfficientList> -->
			</template>
		</DialogFullScreenPageLayout>
	</Page>
</template>

<script setup>
import { computed, inject } from "vue";
import { useRouter } from "vue-router";

import { PageNames } from "../constants/index.js";
import { ListDirections } from "../components/IntersectionObserverList/index.js";
import { usePageSize } from "../domain/usePageSize.js";
import { combineQueries, useRouteParams } from "../helpers/index.js";

import Page from "../components/Page.vue";
import DialogFullScreenPageLayout from "../components/DialogFullScreenPageLayout.vue";
import Tabs from "../components/Tabs.vue";
import Tab from "../components/Tab.vue";
import NewList from "../components/NewList.vue";
import UserCard from "../components/UserCard.vue";
// import EfficientList from "../components/EfficientList/EfficientList.vue";

const currentEnvironment = inject("currentEnvironment");
const model = inject("model");

const router = useRouter();
const pageSize = usePageSize();
const routeParams = useRouteParams();

const activeUser = computed(() => currentEnvironment.value.activeUser.value);
const userId = computed(() => routeParams.userId ?? activeUser.value?.id);
const queries = combineQueries({
	followersAndFollowing: model.queries.GetFollowersAndFollowing({ userId }),
	user: model.queries.GetUser({ userId: computed(() => routeParams.userId) }),
	currentUser: model.queries.GetCurrentUser(),
});

const userCardHeight = computed(() => (pageSize.isSmallerThanMedium ? 76 : 76));
const isCurrentUser = computed(() => userId.value === activeUser.value?.id);
const user = computed(() => (isCurrentUser.value ? queries.model?.currentUser : queries.model?.user));
const followers = computed(() => queries.model?.followers ?? []);
const following = computed(() => queries.model?.following ?? []);
const pageTitle = computed(() => (queries.isLoading ? "Loading..." : queries.error ? "Error" : user.value?.username));
const followersTabTitle = computed(() => (followers.value?.length > 0 ? `${followers.value.length} Followers` : `${followers.value.length} Followers`));
const followingTabTitle = computed(() => (following.value?.length > 0 ? `${following.value.length} Following` : `${following.value.length} Following`));

const userLookup = {
	[PageNames.PROFILE_FOLLOWERS]: computed(() => followers.value),
	[PageNames.PROFILE_FOLLOWING]: computed(() => following.value),
	[PageNames.OTHER_USER_FOLLOWERS]: computed(() => followers.value),
	[PageNames.OTHER_USER_FOLLOWING]: computed(() => following.value),
};
const emptyMessageLookup = {
	[PageNames.PROFILE_FOLLOWERS]: "Let your friends know they can follow you",
	[PageNames.PROFILE_FOLLOWING]: "Search for your friends and start following them",
	[PageNames.OTHER_USER_FOLLOWERS]: "No followers",
	[PageNames.OTHER_USER_FOLLOWING]: "Not following anyone",
};
const listItems = computed(() => userLookup[router.currentRoute.value.name]?.value.slice(0, 800) ?? []);
const emptyMessage = computed(() => emptyMessageLookup[router.currentRoute.value.name]);
const listName = computed(() => `${userId.value}:${router.currentRoute.value.name}`);
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables_new.scss";

.followers-and-following {
	margin: 0 calc($spacing);
	.name {
		font-size: $text-size-primary;
	}
}

.list {
	overflow: auto;

	:deep(.users) {
		margin: calc($spacing / 2) calc($spacing);
	}
}

@media (min-width: $bp-medium) {
	// .followers-and-following {
	// 	margin: 0 calc($spacing / 2);
	// }

	.list {
		// margin: calc($spacing/ 2);
	}
}
</style>
