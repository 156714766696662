<template>
	<div class="feed-list">
		<div class="list-title">
			<h2>{{ feedList.name }}</h2>
			<CustomRouterLink :to="listRouterLink" class="show-more link-button-primary">Show all</CustomRouterLink>
		</div>
		<DownloadApp v-if="!entityTypeConfig" />
		<template v-else>
			<NewList
				v-slot="{ item, isItemVisible }"
				list-class="venues"
				item-class="venue"
				:name="listName"
				:items="entityTypeConfig.items"
				:direction="ListDirections.HORIZONTAL"
				:item-height="entityTypeConfig.itemHeight"
				:item-width="entityTypeConfig.itemWidth"
				:gap-size="7"
				:item-buffer-count="4"
				:show-navigation-buttons="true"
				:enable-snap="false"
				:pad-first-and-last-item="true"
			>
				<KeepAlive>
					<component :is="item.component" v-if="isItemVisible" :key="`list-item:${item.id}`" v-bind="item" />
				</KeepAlive>
			</NewList>
		</template>

		<!-- <EfficientList v-slot="{ item: item, style }" class="venues" :direction="ListDirections.HORIZONTAL" :items="props.feedList.items" :item-size="220" :buffer="4" :recycle="true">
			<VenueCard :venue="item.venue" :style="style" />
		</EfficientList> -->
	</div>
</template>

<script setup>
import { computed, reactive, unref } from "vue";

import { urlBuilder } from "../helpers/index.js";
import { useVenueProductSummary } from "../composables/index.js";
import { PageNames, ListDirections, EntityTypeNames, CardTypeNames } from "../constants/index.js";

import NewList from "./NewList.vue";
import VenueCard from "./VenueCard.vue";
import VenueProductSummaryCard from "./VenueProductSummaryCard.vue";
import DownloadApp from "./DownloadApp.vue";
// import EfficientList from "./EfficientList/EfficientList.vue";

const props = defineProps({
	feedList: {
		type: Object,
		required: true,
	},
	venueProductTypes: {
		type: Array,
		required: true,
	},
});

const list = computed(() => props.feedList);
const items = computed(() => unref(list).items ?? []);
const listName = `feed-list:${props.feedList.name}:${props.feedList.id}`;
const listRouterLink = computed(() => urlBuilder(PageNames.EXPLORE_LIST, { cityName: props.feedList.city()?.name?.toLowerCase(), list: props.feedList }));
const entityTypes = computed(() => props.feedList.entityTypes ?? []);
const venueProductsSummary = useVenueProductSummary(list);
const firstEntityType = computed(() => entityTypes.value?.[0] ?? EntityTypeNames.Venue);
const ENTITY_TYPE_CONFIG_LOOKUP = {
	[EntityTypeNames.Venue]: reactive({
		items: computed(() => unref(items).map(({ venue }) => ({ id: venue.id, component: VenueCard, venue }))),
		itemHeight: 217,
		itemWidth: 170,
	}),
	[EntityTypeNames.VenueProduct]: reactive({
		items: computed(() =>
			venueProductsSummary.value.map(({ venue, venueProducts }) => ({
				id: venue.id,
				component: VenueProductSummaryCard,
				class: "venue-product",
				venue,
				venueProducts,
				venueProductTypes: props.venueProductTypes,
				cardType: CardTypeNames.LARGE_SQUARE_NO_DETAILS,
			})),
		),
		itemHeight: 217,
		itemWidth: 170,
	}),
	// [EntityTypeNames.VenueProduct]: reactive({
	// 	items: computed(() => sortedVenueProducts.value.map((venueProduct) => ({ id: venueProduct.id, component: VenueProductCard, class: "venue-product", venueProduct }))),
	// 	itemHeight: 184,
	// }),
};
const entityTypeConfig = computed(() => ENTITY_TYPE_CONFIG_LOOKUP[firstEntityType.value]);
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

.feed-list {
	display: flex;
	flex-direction: column;
	gap: calc($spacing / 2);
	box-sizing: border-box;
	height: 100%;

	.list-title {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: calc($spacing / 1.5);
		max-width: 100vw;
		padding-left: calc($spacing);

		> h2 {
			@include text-ellipsis;
		}

		.show-more {
			margin-top: 4px;
			flex-shrink: 0;
		}
	}
}

@media (min-width: $bp-medium) {
	.feed-list {
		> .list-title {
			padding-left: 0;
		}
	}
}
</style>
