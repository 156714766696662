export const TrackingEventNames = {
	SignUpAttempt: "Sign Up Attempt",
	SignInAttempt: "Sign In Attempt",
	SuccessfulSignIn: "Successful Sign In",
	SuccessfulAuthenticationCreatingAccount: "Successful Authentication Creating Account",
	FailedSignIn: "Failed Sign In",
	SignOut: "Sign Out",
	Click: "Click",
	ViewVenue: "View Venue",
	PerkViewed: "Perk Viewed",
	PerkRedeemButtonPressed: "Perk Redeem Button Pressed",
	VenueBookPressed: "Venue Book Pressed",
	VenueImageGalleryInteraction: "Venue Image Gallery Interaction",
	CancelCreateUserProfile: "Cancel Create User Profile",
	BookingAvailabilitySearched: "Booking Availability Searched",
	BookingTimeSlotSelected: "Booking Time Slot Selected",
	BookingTimeSlotCancelled: "Booking Time Slot Cancelled",
	BookingConfirmed: "Booking Confirmed",
	BookingFailed: "Booking Failed",
	AddVenueToUserList: "Add Venue To User List",
	RemoveVenueFromUserList: "Remove Venue From User List",
	AdvertisingIDRequested: "Advertising ID Requested",
	FeedbackSubmitted: "Feedback Submitted",
	VenueProductLinkClicked: "Venue Product Link Clicked",
	VenueProductViewed: "Venue Product Viewed",
	VenueProductGalleryInteraction: "Venue Product Gallery Interaction",
	VenueProductImageGalleryInteraction: "Venue Product Image Gallery Interaction",
};
