export default class {
	constructor({ logger, modelBuilder, api, authentication, cities }) {
		this.logger = logger.nested({ name: "Model" });
		this.modelBuilder = modelBuilder;
		this.api = api;
		this.authentication = authentication;
		this.cities = cities;
	}

	execute({ cityName } = {}) {
		return this.modelBuilder.build({
			name: "GetAreas",
			args: { cityName },
			execute: async ({ args }) => {
				const foundCity = this.cities.value.find(({ name }) => name.toLowerCase() === args.cityName);
				if (!foundCity) {
					throw new Error(`City '${args.cityName}' not found`);
				}

				const areas = await this.api.queries.getAreas({ cityId: foundCity.id }, { useClientCache: true, usePersistentCache: true });

				return { areas };
			},
		});
	}
}
