<template>
	<ConditionalRouterLink :to="props.routerLink" :enabled="props.routerLinkEnabled" :class="{ 'universal-card': true, [props.cardType]: true }">
		<h2 v-if="!cardTypeConfig.showDetails && cardTypeConfig.header">{{ cardTypeConfig.header }}</h2>
		<h3 v-if="!cardTypeConfig.showDetails && cardTypeConfig.subHeader">{{ cardTypeConfig.subHeader }}</h3>
		<div class="image" :style="{ minWidth: cardTypeConfig.imageSize + 'px' }">
			<div v-if="slots.decals1" class="decals-1"><slot name="decals1" /></div>
			<div v-if="slots.decals2" class="decals-2"><slot name="decals2" /></div>
			<ImgIxWithPlaceholder v-if="props.imgIx" :src="imageSrc" :preview="imagePreview" :sizes="sizes" :widths="widths" :is-lazy="props.isImageLazy" />
			<img v-else :src="props.imageSrc" loading="lazy" />
		</div>
		<div v-if="cardTypeConfig.showDetails" class="detail">
			<header v-if="slots.header">
				<slot name="header" />
			</header>
			<aside v-if="slots.aside">
				<slot name="aside" />
			</aside>
			<footer v-if="slots.footer">
				<slot name="footer" />
			</footer>
		</div>
		<footer v-if="!cardTypeConfig.showDetails && cardTypeConfig.footer">{{ cardTypeConfig.footer }}</footer>
	</ConditionalRouterLink>
</template>

<script setup>
import { computed, reactive, toRef, unref, useSlots } from "vue";

import { CardTypeNames } from "../constants/index.js";

import ConditionalRouterLink from "./ConditionalRouterLink.vue";
import ImgIxWithPlaceholder from "./imgix/ImgIxWithPlaceholder.vue";

const props = defineProps({
	cardType: {
		type: String,
		default: () => CardTypeNames.MEDIUM_RECTANGLE_MID_DETAILS,
	},
	image: {
		type: Object,
		default: null,
	},
	isImageLazy: {
		type: Boolean,
		default: true,
	},
	routerLink: {
		type: [String, Object],
		default: null,
	},
	routerLinkEnabled: {
		type: Boolean,
		default: true,
	},
	showMini: {
		type: Boolean,
		default: false,
	},
	imgIx: {
		type: Boolean,
		default: true,
	},
	name: {
		type: String,
		default: null,
	},
	description: {
		type: String,
		default: null,
	},
	summary: {
		type: String,
		default: null,
	},
});

const CARD_TYPE_CONFIG = {
	[CardTypeNames.LARGE_SQUARE_NO_DETAILS]: reactive({
		imageSize: 170,
		showDetails: false,
		header: toRef(props, "name"),
		subHeader: toRef(props, "description"),
		footer: toRef(props, "summary"),
	}),
	[CardTypeNames.MEDIUM_RECTANGLE_MID_DETAILS]: reactive({
		imageSize: 120,
		showDetails: true,
	}),
};

const slots = useSlots();

const imageSrc = computed(() => props.image?.url);
const imagePreview = computed(() => props.image?.preview);
const cardTypeConfig = computed(() => CARD_TYPE_CONFIG[props.cardType]);

const sizes = computed(() => `${unref(cardTypeConfig).imageSize}px`);
const widths = computed(() => new Array(3).fill(null).map((v, index) => unref(cardTypeConfig).imageSize * (index + 1)));
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables_new.scss";

.universal-card {
	display: flex;
	box-sizing: content-box;

	&.LARGE_SQUARE_NO_DETAILS {
		flex-direction: column;

		h2,
		h3,
		footer {
			font-size: $text-size-tertiary;
			line-height: 130%;
			margin: 0;
		}

		h2 {
			color: $text-color-primary;
			font-weight: $text-bold-secondary;
		}

		h3 {
			color: $text-color-secondary;
			margin-bottom: calc($spacing / 6);
			font-weight: $text-normal;
		}

		footer {
			color: $text-color-secondary;
			margin-top: calc($spacing / 4);
		}
	}

	&.MEDIUM_RECTANGLE_MID_DETAILS {
		@include hover-primary;

		flex-direction: row;
		align-items: flex-start;
		gap: $spacing;

		.detail {
			header {
				// margin-left: 2px;
			}

			aside {
				// margin-left: 2px;
				margin-right: calc($spacing * 2);
				max-width: max(58%, 300px);

				> :deep(div) {
					@include text-ellipsis-multiline(2);
				}
			}

			footer {
				flex: 1;
				align-items: center;
				// max-width: 300px;

				//:deep(> *) {
				// max-width: 50%;
				// flex: 1;
				//}

				:deep(a),
				:deep(button) {
					font-size: $text-size-tertiary;
					font-weight: $text-bold-primary;
					// padding: calc($spacing / 2) 0;
				}
			}
		}
	}

	.image {
		position: relative;
		aspect-ratio: 1;
		border-radius: $border-radius-primary;
		overflow: hidden;

		.decals-1,
		.decals-2 {
			position: absolute;
			z-index: 1;
			display: flex;
			flex-direction: column;
			height: 20px;
			align-items: center;
			justify-content: center;
			gap: calc($spacing/2);
			padding: calc($spacing/2) calc($spacing/1.5);

			:deep(> img) {
				height: 100%;
				@include drop-shadow-image-decal;
			}
		}

		.decals-2 {
			right: 0;
		}
	}

	.detail {
		height: 100%;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		// padding: calc($spacing / 2);

		header {
			display: flex;
			flex-direction: column;
			margin-bottom: calc($spacing / 5);

			:deep(h2) {
				font-size: $text-size-primary;
				font-weight: $text-bold-primary;
				color: $text-color-primary;
				margin: 0;
			}

			:deep(h3) {
				font-size: $text-size-primary;
				font-weight: $text-bold-primary;
				color: $text-color-secondary;
				margin: 0;
			}
		}

		aside {
			display: flex;
			flex-direction: column;
			gap: round(calc($spacing / 2));
			font-size: $text-size-tertiary;
			color: $text-color-secondary;

			:deep(p) {
				margin: 0;
			}
		}

		footer {
			display: flex;
			gap: calc($spacing / 1.5);
			align-items: flex-end;
			font-size: $text-size-tertiary;
			font-weight: $text-bold-primary;
			color: $text-color-primary;
			// margin-top: calc($spacing / 2);
		}
	}
}
</style>
