import { watch, toRef } from "vue";
import { useRouter } from "vue-router";
import { useRouteParams } from "./useRouteParams.js";

import loggingMessages from "./ensureCorrectCity.logging-messages.js";

export const ensureCorrectCity = async (parentLogger, cityName) => {
	/* BUG: This is currently not used (was used in ExploreList and ExploreArea), if it is used it needs to be fixed so it only works when route is active. Currently it will monitor and overwrite route changes when kept-alive */
	const logger = parentLogger.nested({ name: "ensureCorrectCity" });
	const router = useRouter();
	const routeParams = useRouteParams();

	watch(
		[cityName, toRef(routeParams, "cityName")],
		() => {
			const cityNameToTest = cityName.value?.toLowerCase();
			const currentCityName = routeParams.cityName?.toLowerCase();
			if (cityNameToTest && currentCityName) {
				const doesCityNameMatch = cityNameToTest === currentCityName;
				if (!doesCityNameMatch) {
					logger.log(loggingMessages.cityMismatch, { expected: cityNameToTest, actual: currentCityName });
					router.replace({ params: { city: cityName.value?.toLowerCase() } });
				}
			}
		},
		{ immediate: true },
	);
};
