<template>
	<div v-if="!canBook && suggestedUserAction === SuggestedUserAction.UPGRADE_PREMIUM" class="upgrade book-button push-button-primary" @click="bookBlockedOnClick">Upgrade To Book</div>
	<div v-else-if="!canBook && suggestedUserAction === SuggestedUserAction.SIGN_IN" class="upgrade book-button push-button-call-to-action" @click="bookBlockedOnClick">Book</div>
	<span v-else-if="!canBook && suggestedUserAction === SuggestedUserAction.WAIT" class="disabled book-button push-button-primary">Coming Soon</span>
	<a v-else-if="bookingMethod" v-external-link="{ ...bookingMethod }" :href="bookingMethod.href" :class="{ 'book-button': true, 'show-mini': props.showMini, ...(bookingMethod.class ?? {}) }">
		<img v-if="bookingMethod.showIcon" :src="props.showMini ? onezoneLogoBlack : onezoneLogoWhite" class="onezone-logo" />
		<span>{{ bookingMethod.label }}</span>
	</a>
	<span v-else class="disabled book-button push-button-primary">No Booking</span>
</template>

<script setup>
import { computed, inject, reactive } from "vue";
import { useRoute } from "vue-router";

import { PremiumFeature, SuggestedUserAction, BookingMethods, VenueType, PagePlacements, ButtonActions, ButtonStyles } from "../../constants/index.js";
import { safeHapticsVibrate } from "../../helpers/index.js";

import onezoneLogoWhite from "../../assets/icons/logo-black-background.svg";
import onezoneLogoBlack from "../../assets/icons/logo-white-background.svg";

const VENUE_TYPE_TAG_LOOKUP = {
	Hotel: VenueType.HOTEL,
	Spa: VenueType.SPA,
};

const VENUE_TYPE_BOOK_LABEL_LOOKUP = {
	[VenueType.RESTAURANT]: "Book A Table",
	[VenueType.HOTEL]: "Book A Room",
	[VenueType.SPA]: "Book A Treatment",
};

const props = defineProps({
	venue: {
		type: Object,
		required: true,
	},
	showMini: {
		type: Boolean,
		default: false,
	},
});

const BOOKING_METHODS = reactive({
	[BookingMethods.IN_APP]: {
		label: computed(() => (props.showMini ? "Book" : VENUE_TYPE_BOOK_LABEL_LOOKUP[venueType.value])),
		clickHandler: (e) => {
			venueBookingDialogRef.value.open({ venueId: props.venue.id });
			venueBookPressed(e);
		},
		class: { "push-button-call-to-action": !props.showMini },
		showIcon: true,
	},
	[BookingMethods.PASS_THROUGH]: {
		label: computed(() => (props.showMini ? "Book" : VENUE_TYPE_BOOK_LABEL_LOOKUP[venueType.value])),
		clickHandler: (e) => {
			venueBookingDialogRef.value.open({ venueId: props.venue.id });
			venueBookPressed(e);
		},
		class: { "push-button-call-to-action": !props.showMini },
		showIcon: true,
	},
	[BookingMethods.URL]: {
		label: computed(() => (props.showMini ? "Book" : VENUE_TYPE_BOOK_LABEL_LOOKUP[venueType.value])),
		href: computed(() => props.venue.bookingUrl ?? null),
		clickHandler: (e) => {
			venueBookPressed(e);
		},
		class: { "push-button-call-to-action": !props.showMini },
	},
	// [BookingMethods.PHONE]: {
	// 	label: "Call to Book",
	// 	href: computed(() => (props.venue.contactNumber ? `tel:${props.venue.contactNumber}` : null)),
	// 	openWindow: false,
	// 	clickHandler: async () => {
	// 		await tracking.venueBookPressed(props.venue, BookingMethods.PHONE);
	// 	},
	// 	class: { "push-button-primary": true },
	// },
});

const userActionDialogRef = inject("userActionDialogRef");
const tracking = inject("tracking");
const venueBookingDialogRef = inject("venueBookingDialogRef");

const route = useRoute();

const canBook = computed(() => props.venue.book.canBook);
const suggestedUserAction = computed(() => props.venue.book.action);
const bookingMethod = computed(() => BOOKING_METHODS[props.venue.book.bookingMethod]);
const venueType = computed(() => Object.entries(VENUE_TYPE_TAG_LOOKUP).find(([key]) => props.venue.tags?.some((tag) => tag.name === key))?.[1] ?? VenueType.RESTAURANT);

function bookBlockedOnClick() {
	userActionDialogRef.value.open({ feature: PremiumFeature.LAUNCH, userAction: suggestedUserAction.value });
}

function venueBookPressed(e) {
	const buttonElement = e.currentTarget;
	const isCallToAction = buttonElement.classList.contains("push-button-call-to-action");

	safeHapticsVibrate({ duration: 20 });
	tracking.venueBookPressed({
		venue: props.venue,
		button: {
			pageName: route.name,
			placement: PagePlacements.EMBEDDED,
			action: ButtonActions.BOOKING_OPEN,
			text: buttonElement.innerText,
			style: isCallToAction ? ButtonStyles.CALL_TO_ACTION : ButtonStyles.STANDARD,
		},
	});
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/variables_new.scss";

.book-button {
	/* WHY: Need the extra specifity to override styles on push-buttons */
	&.push-button-primary,
	&.push-button-call-to-action,
	&.show-mini {
		display: flex;
		flex-grow: 1;
		justify-content: center;
		padding: calc($spacing) 0;
		gap: calc($spacing / 2);
		cursor: pointer;

		&.show-mini {
			flex-direction: column;
			gap: calc($spacing / 6);

			&:hover {
				.onezone-logo {
					// filter: invert(1);
				}
			}

			> * {
				text-align: center;
				font-size: $text-size-quinary;
				color: $text-color-secondary;
			}

			.onezone-logo {
				background-color: $background-color-primary;
				height: 18px;
				border-radius: 50%;
			}
		}

		&.disabled {
			color: $text-color-secondary;
		}

		> * {
			text-align: center;
		}

		.onezone-logo {
			height: 18px;
		}
	}
}
</style>
