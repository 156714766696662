import { computed, reactive, ref } from "vue";
import { useEventListener, useSwipe } from "@vueuse/core";
import { debounce } from "lodash-es";

import { safeHapticsVibrate } from "../../helpers/index.js";

const SWIPE_DIRECTION = {
	DOWN: "down",
	UP: "up",
};

export const usePullToRefresh = (targetRef, { enabled = true, message, threshold = 5, swipeStartThreshold = 0, distance = 50, onRefresh, pullStages = 1, blockClicksWhilstPulling = true } = {}) => {
	const top = ref(0),
		isRefreshing = ref(false),
		isPulling = ref(false),
		isScrolling = ref(false);

	const swipeState = {
		swipeStartedScrollTop: null,
		initialSwipeDirection: null,
		hasInvokedRefresh: false,
		wasLastActionRefresh: ref(false),
		isPullComplete: ref(false),
	};

	const length = computed(() => {
		return swipeData.lengthY.value + swipeState.swipeStartedScrollTop + threshold;
		// return swipeData.lengthY.value + threshold;
	});

	const pullComplete = debounce(
		() => {
			swipeState.isPullComplete.value = true;
			safeHapticsVibrate({ duration: 20 });
		},
		200,
		{ leading: true },
	);

	const pullCompleteReset = () => {
		swipeState.isPullComplete.value = false;
	};

	const pulledAmount = ref(0);
	const pullStage = ref(0);

	const swipeData = useSwipe(targetRef, {
		passive: true,
		threshold,
		onSwipeStart() {
			if (!enabled) {
				return;
			}

			swipeState.swipeStartedScrollTop = targetRef.value.scrollTop;
			swipeState.wasLastActionRefresh.value = false;
			pulledAmount.value = 0;
			pullStage.value = 0;

			if (swipeState.swipeStartedScrollTop > swipeStartThreshold) {
				return;
			}
		},
		onSwipe() {
			if (!enabled) {
				return;
			}

			if (swipeState.swipeStartedScrollTop > swipeStartThreshold) {
				return;
			}

			if (!swipeState.initialSwipeDirection && swipeData.direction.value) {
				swipeState.initialSwipeDirection = swipeData.direction.value;
			}

			if (swipeData.lengthY.value < 0 && swipeState.initialSwipeDirection?.toLowerCase() === SWIPE_DIRECTION.DOWN) {
				if (isRefreshing.value === false) {
					isPulling.value = true;

					top.value = `${Math.min(Math.abs(length.value), distance)}px`;
					pulledAmount.value = Math.min(Math.abs(length.value) / distance, 1);
					pullStage.value = Math.round(pulledAmount.value * pullStages);

					if (pulledAmount.value == 1 && !swipeState.isPullComplete.value) {
						pullComplete();
					} else if (pulledAmount.value < 1 && swipeState.isPullComplete.value) {
						pullCompleteReset();
					}

					const hasPullToRefreshMessage = !!message?.value?.$el?.style;
					if (hasPullToRefreshMessage) {
						const isPullToRefreshMessageVisible = targetRef.value.scrollTop === 0 && swipeData.isSwiping.value;
						if (isPullToRefreshMessageVisible) {
							message.value.$el.style.transition = "none";
							message.value.$el.style.opacity = pulledAmount.value;
						} else {
							message.value.$el.style.transition = null;
							message.value.$el.style.opacity = 0;
						}
					}
				}
			} else {
				top.value = 0;
			}
		},
		async onSwipeEnd() {
			if (!enabled) {
				return;
			}

			if (swipeState.swipeStartedScrollTop > swipeStartThreshold) {
				return;
			}

			if (swipeData.lengthY.value < 0 && swipeState.initialSwipeDirection?.toLowerCase() === SWIPE_DIRECTION.DOWN) {
				if (length.value <= 0 - distance && isRefreshing.value === false) {
					isRefreshing.value = true;
				}
				top.value = 0;
				const isAlreadyRefreshing = swipeState.hasInvokedRefresh;
				const isRefreshScheduled = isRefreshing.value === true;
				const hasRefreshHandler = !!onRefresh;
				if (isRefreshScheduled && hasRefreshHandler && !isAlreadyRefreshing) {
					swipeState.hasInvokedRefresh = true;
					swipeState.wasLastActionRefresh.value = true;
					await onRefresh();
					swipeState.hasInvokedRefresh = false;
					isRefreshing.value = false;
				}
			}
			/* reset is pulling after timeout, to allow time for the click event handler to detect it was pulling and prevent the click (this is required for safari mobile, which is eager to consider a swipe as a click) */
			setTimeout(() => {
				isPulling.value = false;
			}, 200);

			swipeState.initialSwipeDirection = null;
			swipeState.swipeStartedScrollTop = null;
		},
	});

	useEventListener(
		targetRef,
		"touchmove",
		(e) => {
			/* WHY: Prevent scrolling the target on mobile when we are pulling to refresh */
			if (targetRef.value.scrollTop <= 0 && isPulling.value && swipeState.initialSwipeDirection?.toLowerCase() === SWIPE_DIRECTION.DOWN) {
				e.preventDefault();
			}
		},
		{ passive: false },
	);

	if (blockClicksWhilstPulling) {
		useEventListener(
			targetRef,
			"scroll",
			debounce(
				() => {
					isScrolling.value = true;
				},
				400,
				{ leading: true, trailing: false },
			),
			{ passive: true },
		);
		useEventListener(
			targetRef,
			"scroll",
			debounce(() => {
				isScrolling.value = false;
			}, 400),
			{ passive: true },
		);
		useEventListener(
			targetRef,
			"click",
			(e) => {
				if (isPulling.value || isScrolling.value) {
					e.preventDefault();
				}
			},
			{ capture: true },
		);
	}

	return reactive({
		isRefreshing,
		isPulling,
		pulledAmount,
		pullStage,
		style: computed(() => ({
			top: top.value,
			transition: swipeData.isSwiping.value && !isRefreshing.value ? "none" : "top 0.2s ease-in-out",
		})),
	});
};
