<template>
	<custom-router-link :to="perkLink" :replace="true" class="perk-button" @click="buttonClicked">
		<img :src="DiamondBlack" class="icon" />
		<div class="text">Perk</div>
	</custom-router-link>
</template>

<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";

import { safeHapticsVibrate } from "../../helpers/index.js";
import { PagePlacements, ButtonActions, ButtonStyles } from "../../constants/index.js";

import DiamondBlack from "../../assets/icons/Diamond-Black.svg";

const props = defineProps({
	perk: {
		type: Object,
		required: true,
	},
	venue: {
		type: Object,
		required: true,
	},
});

const route = useRoute();

const perkLink = computed(() => {
	return {
		name: "City/Explore/Areas/Venue",
		params: {
			city: props.venue.zone().area().city().name.toLowerCase(),
			venueId: props.venue.id,
		},
		query: {
			showPerk: "true",
			button: JSON.stringify({
				pageName: route.name,
				placement: PagePlacements.EMBEDDED,
				action: ButtonActions.PERK_OPEN,
				text: "Perk",
				style: ButtonStyles.STANDARD,
			}),
		},
	};
});

function buttonClicked() {
	safeHapticsVibrate({ duration: 20 });
}
</script>

<style scoped lang="scss">
@import "../../assets/styles/variables_new.scss";

.perk-button {
	display: flex;
	align-items: center;
	gap: calc($spacing/6);
	flex-direction: column;

	.icon {
		padding: 2px;
		box-sizing: border-box;
		height: 28px;
	}

	.text {
		font-size: $text-size-tertiary;
	}
}
</style>
