<template>
	<div ref="listContainerRef" class="new-list-container" :data-list-name="props.name">
		<!-- <div v-if="props.showItemsVisibility">{{ itemVisibility }}</div> -->
		<div v-if="props.showDebugPanel" class="debug-panel">
			<pre>{{ debugInfo }}</pre>
		</div>
		<ScrollArrows v-if="props.showNavigationButtons" class="scroll-arrows" :container-ref="listContainerRef" :scroll-ref="listRef" :item-count="props.items.length" :item-size="itemSize" />
		<Refreshing v-if="props.enablePullToRefresh" class="refreshing" :stage="pullToRefresh.pulledAmount" :style="pullToRefresh?.style" />
		<LoadingMore v-if="props.enableLoadMore" :is-visible="props.isLoadingMore" class="loading-more" />
		<KeepAlive>
			<LoadingMessageWithError v-if="(props.query?.isLoading && !props.isLoadingMore) || props.query?.error" :error="props.query?.error" :retry="props.query?.retry" class="loading-message">
				{{ props.loadingMessage }}
			</LoadingMessageWithError>
			<EmptyResultsMessage v-else-if="props.items.length === 0">
				{{ props.emptyMessage }}
			</EmptyResultsMessage>
			<ol v-else ref="listRef" class="new-list" :style="containerStyle" :class="listClasses">
				<template v-for="(item, index) in props.items" :key="item[props.keyField]">
					<li ref="itemRefs" :data-item-id="item[props.keyField]" :style="itemStyle" :class="props.itemClass">
						<slot v-bind="{ item, isItemVisible: itemVisibility[item.id] ?? false, index }" />
					</li>
				</template>
			</ol>
		</KeepAlive>
	</div>
</template>

<script setup>
import { computed, markRaw, reactive, ref, toRef, watch } from "vue";
import { useScroll } from "@vueuse/core";

import { usePullToRefresh } from "./pull-to-refresh/usePullToRefresh.js";
import { useIntersectionObserverList, ListDirections } from "./IntersectionObserverList/index.js";
import { useScrollMemory } from "../functions/scroll-memory/index.js";

import ScrollArrows from "./ScrollArrows.vue";
import Refreshing from "./Refreshing.vue";
import LoadingMore from "./LoadingMore.vue";
import EmptyResultsMessage from "./EmptyResultsMessage.vue";

const props = defineProps({
	name: {
		type: String,
		required: true,
	},
	query: {
		type: Object,
		default: undefined,
	},
	items: {
		type: Array,
		required: true,
	},
	direction: {
		type: String,
		default: ListDirections.VERTICAL,
		validator: (value) => Object.values(ListDirections).includes(value),
	},
	listClass: {
		type: String,
		default: undefined,
	},
	itemClass: {
		type: String,
		default: undefined,
	},
	itemHeight: {
		type: Number,
		default: undefined,
	},
	itemWidth: {
		type: Number,
		default: undefined,
	},
	gapSize: {
		type: Number,
		default: undefined,
	},
	itemBufferCount: {
		type: Number,
		default: undefined,
	},
	keyField: {
		type: String,
		default: "id",
	},
	padFirstAndLastItem: {
		type: Boolean,
		default: false,
	},
	showNavigationButtons: {
		type: Boolean,
		default: false,
	},
	enablePullToRefresh: {
		type: Boolean,
		default: false,
	},
	loadingMessage: {
		type: String,
		default: "Loading, please wait...",
	},
	emptyMessage: {
		type: String,
		default: "No places found",
	},
	isLoadingMore: {
		type: Boolean,
		default: false,
	},
	enableLoadMore: {
		type: Boolean,
		default: false,
	},
	loadMoreDistanceItemCount: {
		type: Number,
		default: 2,
	},
	showDebugPanel: {
		type: Boolean,
		default: false,
	},
	enableSnap: {
		type: Boolean,
		default: false,
	},
	showItemsVisibility: {
		type: Boolean,
		default: false,
	},
});

const emits = defineEmits(["loadMore", "refresh"]);

const listContainerRef = ref(),
	listRef = ref();

const itemSize = computed(() => (props.direction === ListDirections.VERTICAL ? props.itemHeight : props.itemWidth) + props.gapSize);
const listClasses = computed(() => ({
	[props.listClass]: !!props.listClass,
	"pull-to-refresh-enabled": props.enablePullToRefresh,
	"load-more-enabled": props.enableLoadMore,
	"pad-first-and-last-item": props.padFirstAndLastItem,
}));

const intersectionObserverList = useIntersectionObserverList(
	listRef,
	reactive({
		direction: toRef(props, "direction"),
		items: toRef(props, "items"),
		itemWidth: toRef(props, "itemWidth"),
		itemHeight: toRef(props, "itemHeight"),
		gapSize: toRef(props, "gapSize"),
		itemBufferCount: toRef(props, "itemBufferCount"),
		enableSnap: toRef(props, "enableSnap"),
	}),
);

const { containerStyle, itemVisibility, itemStyle, itemRefs } = intersectionObserverList;

const visibleItems = computed(() =>
	Object.entries(itemVisibility)
		.filter(([, isVisible]) => isVisible)
		.map(([id]) => props.items.find((item) => item[props.keyField] === id)),
);
const hiddenItems = computed(() =>
	Object.entries(itemVisibility)
		.filter(([, isVisible]) => !isVisible)
		.map(([id]) => props.items.find((item) => item[props.keyField] === id)),
);
const debugInfo = computed(() => JSON.stringify({ itemCount: props.items.length, visibleItemsCount: visibleItems.value.length, hiddenItemsCount: hiddenItems.value.length }, null, 2));

const { arrivedState } = useScroll(listRef, reactive({ offset: { bottom: computed(() => itemSize.value * props.loadMoreDistanceItemCount) } }));
watch(
	arrivedState,
	() => {
		if (arrivedState.bottom === true && props.enableLoadMore) {
			emits("loadMore");
		}
	},
	{ immediate: true },
);

const pullToRefresh = usePullToRefresh(
	listRef,
	reactive({
		enabled: computed(() => props.enablePullToRefresh),
		distance: 100,
		pullStages: 2,
		onRefresh: markRaw(async () => {
			setTimeout(() => {
				emits("refresh");
			}, 500);
		}),
	}),
);

useScrollMemory({ name: toRef(props, "name"), containerRef: listRef });

// const { visibleItems, containerStyle } = useRecyclingList(listRef, feedLists, { direction: ListDirections.VERTICAL, itemHeight: 250 });
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

.new-list-container {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	overflow: auto;

	.scroll-arrows {
		z-index: 2;
		position: absolute;
		padding: 0 calc($spacing / 2);
		display: none;
		// transform: translateY(-50%);

		@media (pointer: fine) {
			display: inherit;
		}
	}

	.refreshing {
		position: absolute;
		transform: translateY(-100%);
	}

	.loading-message {
		height: 100%;
	}

	.loading-more {
		position: absolute;
		bottom: 0px;
	}

	.new-list {
		margin: 0;
		padding: 0 calc($spacing / 2) 0 0;
		// scroll-snap-type: both mandatory;

		&.pull-to-refresh-enabled {
			overscroll-behavior: none;
		}

		&.load-more-enabled {
			padding-bottom: calc($spacing * 3);
		}

		&.pad-first-and-last-item {
			&::-webkit-scrollbar {
				width: 0px;
				height: 0px;
				background: transparent;
				display: none;
			}

			> li {
				scroll-margin: 7px;

				&:first-child {
					padding-left: calc($spacing);
				}
				&:last-child {
					padding-right: calc($spacing / 2);
				}
			}
		}

		> li {
			// scroll-snap-align: start;
			// scroll-snap-stop: always;
			list-style: none;
			display: flex;
			flex-direction: column;
		}
	}

	.debug-panel {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 20vh;
		background-color: rgba(255, 255, 255, 0.9);
		overflow: auto;
		z-index: 100;
	}
}

@media (min-width: $bp-medium) {
	.new-list-container {
		.new-list {
			&.pad-first-and-last-item {
				> li {
					scroll-margin: 0;

					&:first-child {
						padding-left: 0;
					}
				}
			}
		}
	}
}
</style>
